<ng-container *ngIf="{ client: client$ | async } as data">
	<ng-container *ngIf="data.client && data.client.voucher">
		<div class="container" (click)="openCelebrations(data.client.voucher.value)">
			<div class="box">
				<mat-icon svgIcon="pm-icons:celebrations-white" style="width: 24px; height: 24px"></mat-icon>
				<p>Celebration</p>

				<mat-icon
					svgIcon="pm-icons:bottom-arrow-white"
					style="width: 14px; height: 8px; margin-left: auto"
				></mat-icon>
			</div>
		</div>
	</ng-container>
</ng-container>
