import { CommonModule } from '@angular/common'
import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { TermsOfUseBottomSheetComponent } from '../../simple-bottom-sheets/terms-of-use.component'

@Component({
	standalone: true,
	imports: [MatButtonModule, CommonModule],
	selector: 'feature-pm-terms-and-conditions-checkbox',
	template: `
		<label style=" bottom: 110px; cursor: pointer;" [ngStyle]="{ position: this.position }">
			Li e aceito os
			<span style="color:var(--content-primary)" (click)="open()">termos e condições</span> para
			utilizar {{ use }}.
			<input type="checkbox" (change)="changeConfirm($event)" />
		</label>
	`,
	styles: `
	label {
		color: var(--content-txt);
	}
        input[type="checkbox"] {
            accent-color: var(--content-primary); 
        }
    `,
})
export class TermsAndConditionsCheckboxComponent {
	@Output() changeInput = new EventEmitter<boolean>()
	@Input() position: 'fixed' | 'static' = 'fixed'
	@Input() use: 'benefícios' | 'o Programa Menu' = 'benefícios'
	public bottomSheet = inject(MatBottomSheet)

	open() {
		this.bottomSheet.open(TermsOfUseBottomSheetComponent)
	}

	public changeConfirm(event: Event) {
		const target = event.target as HTMLInputElement
		this.changeInput.emit(target.checked)
	}
}
