import { Component, inject } from '@angular/core'
import { SidebarService } from '../../sidebar/sidebar.service'
import { environment } from '@monorepo-channels/shared/util-environments'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
	standalone: true,
	selector: 'pm-concierge-page',
	template: `
		<div class="iframe-container">
			<iframe [src]="conciergeURL" width="100%" height="100%" frameborder="0"></iframe>
		</div>
	`,
	styles: `
        .iframe-container {
    width: 100%;
    height: 100vh; /* ocupa a altura total da tela */
    overflow: hidden;
    }

    iframe {
    width: 100%;
    height: 100%;
    border: none; /* Remove a borda padrão do iframe */
    }

    
    `,
})
export class ConciergePageComponent {
	public sidebarService = inject(SidebarService)
	public conciergeURL = inject(DomSanitizer).bypassSecurityTrustResourceUrl(environment.conciergeURL)
	constructor() {
		this.sidebarService.isOpen.set(false)
	}
}
