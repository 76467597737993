import { Component } from '@angular/core'
import { WalletComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	selector: 'pm-wallet-page',
	imports: [WalletComponent],
	template: '<feature-pm-wallet/>',
})
export class WalletPageComponent {}
