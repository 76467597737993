import { Component, inject, OnInit } from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { CommonModule } from '@angular/common'
import { MatBottomSheetRef, MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import {
	FooterButtonsComponent,
	BottomSheetHeaderComponent,
	LoadingPageComponent,
} from '@monorepo-channels/components/ui-pm'
import { Store } from '@ngrx/store'

import { FormControl, ReactiveFormsModule } from '@angular/forms'
import {
	// Cuisine,
	CuisineActions,
	getCuisines,
	getStatusCuisine,
	VenueActions,
} from '@monorepo-channels/channels/domain'
import { FilterState } from '../filter.state'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatIconModule,
		MatBottomSheetModule,
		MatButtonModule,
		BottomSheetHeaderComponent,
		LoadingPageComponent,
		ReactiveFormsModule,
		FooterButtonsComponent,
	],
	selector: 'feature-pm-categories-cuisines',
	templateUrl: './categories-cuisines.component.html',
	styleUrls: ['./categories-cuisines.component.scss'],
})
export class CategoriesCuisinesComponent implements OnInit {
	private store = inject(Store)
	public filterState = inject(FilterState)

	public michelinGuide = this.filterState.michelinGuide
	// public newRestaurant = this.filterState.newRestaurant
	public selectedCategory = this.filterState.selectedCategory
	public selectedCuisine = this.filterState.selectedCuisine
	public selectedBenefitIds = this.filterState.selectedBenefitIds
	public selectedExcludeBenefitIds = this.filterState.selectedExcludeBenefitIds

	public cuisines$ = this.store.select(getCuisines)
	public statusCuisines$ = this.store.select(getStatusCuisine)

	private bottomSheetRef = inject<MatBottomSheetRef<CategoriesCuisinesComponent>>(MatBottomSheetRef)

	public searchCityControl = new FormControl('', { nonNullable: true })

	constructor() {
		if (this.filterState.count() === 0) this.filterState.resetState()
	}

	ngOnInit(): void {
		this.store.dispatch(CuisineActions.getCuisines())
	}

	cleanFilter() {
		this.filterState.resetState()
		this.filterState.assignCount()
		this.store.dispatch(VenueActions.cleanIncludeBenefits())
		this.store.dispatch(VenueActions.cleanExcludeBenefits())
		this.store.dispatch(CuisineActions.cleanCuisines())
		this.store.dispatch(VenueActions.cleanCategories())
		this.store.dispatch(VenueActions.cleanMichelinGuide())
		// this.store.dispatch(VenueActions.cleanNewRestaurant())
		this.store.dispatch(VenueActions.applyFilter())
		this.bottomSheetRef.dismiss()
	}

	applyFilter() {
		this.filterState.assignCount()
		this.store.dispatch(CuisineActions.setCurrentCuisine({ cuisines: this.selectedCuisine() }))
		this.store.dispatch(VenueActions.setCurrentCategories({ categories: this.selectedCategory() }))
		this.store.dispatch(
			VenueActions.setCurrentIncludeBenefits({ includeBenefits: this.selectedBenefitIds() })
		)
		this.store.dispatch(
			VenueActions.setCurrentExcludeBenefits({ excludeBenefits: this.selectedExcludeBenefitIds() })
		)
		this.store.dispatch(VenueActions.setMichelinGuide({ michelinGuide: this.michelinGuide().selected }))
		// this.store.dispatch(VenueActions.setNewRestaurant({ newRestaurant: this.newRestaurant().selected }))
		this.store.dispatch(VenueActions.applyFilter())
		this.bottomSheetRef.dismiss()
	}
}
