import { Component } from '@angular/core'
import { RegisterComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	selector: 'pm-register-page',
	imports: [RegisterComponent],
	template: '<feature-pm-register/>',
})
export class RegisterPageComponent {}
