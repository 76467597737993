<article class="card" (click)="clickCard.emit()">
	<div class="card__img">
		<img [src]="venue.coverURL.value" width="343" height="150" />
		<div class="card__badge" [ngClass]="venue.category" *ngIf="venue.category">{{ venue.category }}</div>

		<button class="favorite" mat-button (click)="toggleFavorite($event)">
			<mat-icon
				svgIcon="pm-icons:favorite"
				[class.favorite-icon-anim]="client?.isVenueFavorite(venue.id)"
				*ngIf="!client || !client?.isVenueFavorite(venue.id)"
			></mat-icon>
			<mat-icon
				svgIcon="pm-icons:favorite-full"
				[class.favorite-icon-anim]="client.isVenueFavorite(venue.id)"
				*ngIf="client && client.isVenueFavorite(venue.id)"
			></mat-icon>
		</button>
	</div>
	<div class="card__info">
		<div class="card__info__venue">
			<div style="display: flex; justify-content: space-between">
				<h1 class="card__info__title">{{ venue.name }}</h1>
				<mat-icon
					svgIcon="pm-icons:bib_gourmant"
					*ngIf="venue.michelinGuide === 'michelin_bib_gourmand'"
				></mat-icon>
				<mat-icon
					svgIcon="pm-icons:michelin_star"
					*ngIf="venue.michelinGuide === 'michelin_star'"
				></mat-icon>
			</div>
			<p class="card__info__address">
				{{ venue.address.neighborhood }} • {{ venue.address.city }}
				<span *ngIf="venue.distance">• {{ venue.distance | number: '1.2-2' }} km</span>
			</p>
		</div>
		<div class="tags">
			<ui-pm-tag *ngIf="venue.michelinGuide" [isMichelin]="venue.michelinGuide">
				Guia MICHELIN
			</ui-pm-tag>
			<ui-pm-tag [isNew]="venue.isNew" *ngIf="venue.isNew"> Novo </ui-pm-tag>
			<ui-pm-tag *ngFor="let cuisine of venue.cuisines.slice(0, 2)">{{ cuisine }}</ui-pm-tag>
		</div>
		<ui-common-divisor top="16px" bottom="13px" />
		<ui-pm-benefits [benefits]="venue.benefits" [celebrations]="celebrations" style="padding: 0" />
	</div>
</article>
