import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { Router } from '@angular/router'
import { BottomSheetHeaderComponent, FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	imports: [CommonModule, BottomSheetHeaderComponent, FooterButtonsComponent, MatButtonModule],
	selector: 'feature-pm-register-or-login',
	template: `
		<ui-pm-bottom-sheet-header title="Cadastre-se ou faça login"></ui-pm-bottom-sheet-header>

		<p style="padding: 20px">
			Comece a usufruir dos benefícios exclusivos do seu cartão bradesco realizando seu cadastro ou
			login.
		</p>

		<ui-pm-footer-buttons>
			<button mat-raised-button (click)="login()">Login</button>
			<button mat-raised-button color="primary" (click)="register()">Cadastro</button>
		</ui-pm-footer-buttons>
	`,
})
export class RegisterOrLoginComponent {
	private router = inject(Router)
	private bottomSheetRef = inject(MatBottomSheetRef)

	login() {
		this.router.navigate(['/entrar'])
		this.bottomSheetRef.dismiss()
	}

	register() {
		this.router.navigate(['/cadastrar'])
		this.bottomSheetRef.dismiss()
	}
}
