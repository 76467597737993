import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { CommonModule } from '@angular/common'
import { Store } from '@ngrx/store'
import { getClient, getSelectOrder, OrderHistory } from '@monorepo-channels/channels/domain'
import { combineLatest, map, take } from 'rxjs'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { CancelOrderComponent } from '../../simple-bottom-sheets/cancel-order.component'
import {
	CelebrationsRibbonComponent,
	FooterButtonsComponent,
	SwiperUiComponent,
} from '@monorepo-channels/components/ui-pm'
import { Router } from '@angular/router'
import { CapitalizeFirstPipe } from '@monorepo-channels/shared/util-helpers'
import { BenefitsUiComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	selector: 'feature-pm-order-details',
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		SwiperUiComponent,
		CapitalizeFirstPipe,
		FooterButtonsComponent,
		CelebrationsRibbonComponent,
		BenefitsUiComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA], // why? because of the custom element <swiper-element>
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
	private store = inject(Store)
	private router = inject(Router)
	private selectedOrder$ = this.store.select(getSelectOrder)
	private client$ = this.store.select(getClient)
	public bottomSheet = inject(MatBottomSheet)

	public vm$ = combineLatest([this.selectedOrder$, this.client$]).pipe(
		map(([order, client]) => ({ order, client }))
	)

	ngOnInit(): void {
		this.selectedOrder$.pipe(take(1)).subscribe(order => {
			if (!order) {
				this.goBack()
			}
		})
	}

	goBack() {
		this.router.navigate(['/reservas-e-checkins'])
	}

	cancelOrder(order: OrderHistory | null) {
		if (!order) return
		this.bottomSheet.open(CancelOrderComponent, { data: order })
	}
}
