import { Component } from '@angular/core'
import { NewCardComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	imports: [NewCardComponent],
	selector: 'pm-new-card-page',
	template: `<feature-pm-new-card />`,
})
export class NewCardPageComponent {}
