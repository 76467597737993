import { CommonModule } from '@angular/common'
import { Component, inject, ViewEncapsulation } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'

@Component({
	standalone: true,
	imports: [MatButtonModule, CommonModule, MatIconModule],
	selector: 'feature-pm-celebrations-modal',
	templateUrl: './celebrations-modal.component.html',
	styleUrl: './celebrations-modal.component.scss',
	encapsulation: ViewEncapsulation.None,
})
export class CelebrationsModalComponent {
	public matDialogRef = inject(MatDialogRef<CelebrationsModalComponent>)
	public voucherValue = inject<string>(MAT_DIALOG_DATA)
}
