import { Address } from '../vo/address'
import { CoverURL } from '../vo/coverURL'
import { Phone, VenueDetailProps } from './venues-details.interface'
import { OperationHours } from '../vo/operation-hours'
import { Benefits } from '../benefits/benefits'
import { isNewEntity, PartnersId } from '@monorepo-channels/shared/util-helpers'
import { GoogleMapsLink, LivemenuLink } from '../vo/links'
import { isOnlyCelebration } from './venue-service'

export type MichelinGuide = 'michelin' | 'michelin_star' | 'michelin_bib_gourmand'

export class VenueDetail {
	_id: string
	address: Address
	name: string
	phone: Phone[]
	cuisines: string[]
	bannersURL: string[]
	bannersURLoriginal: string[]
	hasMenu: boolean
	mapsMobile: string
	mapsDesk: string
	mapsLink!: string
	liveMenuURL: string
	descriptionLong: string
	operationHours: OperationHours
	benefits: Benefits[]
	checkin: {
		enabled: boolean
		available: boolean
		status: 'available' | 'unavailable' | 'disabled'
		time?: { startTime: string; endTime: string }
	}
	reservation?: {
		enabled: boolean
	}
	isOnlyCelebration: boolean
	michelinGuide?: MichelinGuide
	isNew: boolean

	constructor(props: VenueDetailProps, partnerId: string) {
		this._id = props._id ?? ''
		this.name = props?.name ?? ''
		this.phone = props?.phone ?? [{ name: '', value: '' }]
		this.cuisines = props?.cousines.map(cuisine => cuisine.name) ?? ['']
		this.hasMenu = props.hasMenu
		if (partnerId === PartnersId.BRADESCO) {
			this.operationHours = new OperationHours(props.operationHours, '|', false)
		} else {
			this.operationHours = new OperationHours(props.operationHours, '-', false)
		}
		this.benefits = props.benefits.map(benefit => new Benefits(benefit))
		this.descriptionLong = props.descriptionLong
		this.reservation = props.reservation
		this.checkin = {
			enabled: props.checkin.enabled,
			available: props.checkin.available,
			status: !props.checkin.enabled
				? 'disabled'
				: props.checkin.enabled && !props.checkin.available
				  ? 'unavailable'
				  : 'available',
			time: {
				startTime: props.checkin.time?.startTime ?? '',
				endTime: props.checkin.time?.endTime ?? '',
			},
		}

		// address
		this.address = new Address(
			props?.address.city ?? '',
			props?.address.state ?? '',
			props?.address.neighborhood ?? '',
			props?.address.address ?? '',
			props?.address.number ?? ''
		)

		// coverURL
		this.bannersURL = props?.partner.images.banners.map(URL => {
			const vo = new CoverURL(URL.coverUrl, URL.optimizedCoverUrl)
			return vo.value
		})
		// coverURL original
		this.bannersURLoriginal = props?.partner.images.banners.map(URL => {
			const vo = new CoverURL(URL.coverUrl, URL.optimizedCoverUrl)
			return vo.original
		})

		// maps mobile and desk
		const location = props.address.location
		const googleMaps = new GoogleMapsLink(location[0], location[1])
		this.mapsMobile = googleMaps.mapsMobile
		this.mapsDesk = googleMaps.mapsDesk
		this.mapsLink = googleMaps.mapsLink
		// liveMenuURL
		const liveMenu = new LivemenuLink(props._id, props.partner.alternateMenu)
		this.liveMenuURL = liveMenu.liveMenuURL

		this.isOnlyCelebration = isOnlyCelebration(props?.partner.types, props?.benefits)
		this.michelinGuide = props?.michelinGuide
		this.isNew = isNewEntity(props?.createdAt ?? undefined)
	}
}
