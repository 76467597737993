import { BenefitProps } from './benefits.interface'
export class Benefits {
	_id: string
	value: string
	name: string
	type: string
	description: string
	descriptionLong: string
	order: number
	constructor(props: BenefitProps) {
		this._id = props._id
		this.name = props.name
		this.value = props.value
		this.type = props.type
		this.description = props.description
		this.descriptionLong = props.descriptionLong
		this.order = props.order
	}
}
