import { Component, inject } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonModule } from '@angular/material/button'
import { Store } from '@ngrx/store'
import { ClientActions, getClient } from '@monorepo-channels/channels/domain'
import { CommonModule } from '@angular/common'
import { RouterLink } from '@angular/router'

@Component({
	imports: [
		MatIconModule,
		DivisorUiComponent,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		MatListModule,
		MatButtonModule,
		CommonModule,
		RouterLink,
	],
	selector: 'pm-sidebar',
	standalone: true,
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
	private store = inject(Store)
	client$ = this.store.select(getClient)

	logout() {
		this.store.dispatch(ClientActions.logoutClient())
	}
}
