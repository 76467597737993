@if (vm$ | async; as vm) {
	@if (showCelebrationOptions()) {
		<feature-pm-choose-celebrations
			*ngIf="vm.client && vm.client.voucher && vm.venue && vm.venue.benefits"
			[voucherValue]="vm.client.voucher.value"
			[benefits]="vm.venue.benefits"
			(outputCelebration)="setCelebration($event, vm.client)"
			order="checkin"
		/>
	} @else {
		<section>
			<ui-pm-loading-page
				*ngIf="vm.statusAvailability === 'pending' || vm.statusConfirm === 'pending'"
			/>
		</section>
		<main
			class="container"
			*ngIf="
				(vm.statusAvailability === 'success' && vm.statusConfirm === 'idle') ||
				vm.statusConfirm === 'failure'
			"
		>
			<h1 class="title">Check-in</h1>
			<h2 class="venue-title">{{ vm.venue?.name }}</h2>

			<ul class="card-box-shadow" style="margin-top: 20px; margin-bottom: 40px">
				<li class="card-box-shadow__item">
					<mat-icon svgIcon="pm-icons:profile" style="width: 24px; height: 24px"></mat-icon>
					{{ vm.client?.fullName }}
				</li>

				<li class="card__item">
					<mat-icon svgIcon="pm-icons:calendar" style="width: 23px; height: 23px"> </mat-icon>

					{{ checkinDate.today | date: "EEEE, d 'de' MMMM" | capitalizeFirst }}
				</li>
			</ul>

			Mesa para:
			<div class="partysize">
				<button mat-icon-button (click)="decrease()">
					<mat-icon svgIcon="pm-icons:minus" style="width: 32px; height: 32px"> </mat-icon>
				</button>
				<div class="partysize__people-container">
					<p class="partysize__number">{{ partysize() }}</p>
					<p class="partysize__people">{{ partysize() === 1 ? 'Pessoa' : 'Pessoas' }}</p>
				</div>
				<button mat-icon-button (click)="increase()">
					<mat-icon svgIcon="pm-icons:plus" style="width: 32px; height: 32px"></mat-icon>
				</button>
			</div>

			<ui-pm-confirm-slider (sliderValueChange)="toggleConfirm($event)">
				<p style="font-size: 14px">Confirmo que estou acomodado em uma mesa</p>
			</ui-pm-confirm-slider>

			<feature-pm-terms-and-conditions-checkbox
				(changeInput)="toggleTermsAndConditions($event)"
				*ngIf="!eulaFromClient()"
			/>

			<ui-pm-footer-buttons type="fixed-order">
				<button
					mat-raised-button
					[color]="isDisabled() ? '' : 'primary'"
					[disabled]="isDisabled()"
					(click)="confirmCheckin()"
				>
					Check-in
				</button>
			</ui-pm-footer-buttons>
		</main>

		<ng-container *ngIf="vm.statusConfirm === 'success' && vm.venue && vm.client">
			<ui-pm-order-success
				type="checkin"
				[partySize]="partysize()"
				[venue]="vm.venue"
				[client]="vm.client"
				[orderDate]="checkinDate.today"
				[reservationTime]="checkinDate.time"
				[voucherValue]="vm.voucherValue"
				(ok)="okClick()"
			/>
		</ng-container>
	}
}
