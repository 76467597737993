import { Component, inject } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { FilterState } from './filter.state'
import { debounceTime, distinctUntilChanged, filter } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Store } from '@ngrx/store'
import {
	AddressActions,
	getSelectedAddress,
	getSelectedNeighborhood,
	VenueActions,
} from '@monorepo-channels/channels/domain'
import { MatExpansionModule } from '@angular/material/expansion'
import { CommonModule } from '@angular/common'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { CitiesComponent } from './cities/cities.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { CategoriesCuisinesComponent } from './categories-cusines/categories-cuisines.component'

@Component({
	standalone: true,
	imports: [
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		MatIconModule,
		MatExpansionModule,
		CommonModule,
		MatProgressSpinnerModule,
	],
	selector: 'feature-pm-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
	private store = inject(Store)
	private filterState = inject(FilterState)
	private bottomSheet = inject(MatBottomSheet)
	public searchControl = new FormControl('', { nonNullable: true })
	public placeholder = 'Faça sua busca'

	public selectedAddress$ = this.store.select(getSelectedAddress)
	public selectedNeighborhood$ = this.store.select(getSelectedNeighborhood)
	public filterCount = this.filterState.count

	constructor() {
		this.searchControl.valueChanges.pipe(takeUntilDestroyed()).subscribe(name => {
			this.filterState.searchChange.set(name)
		})

		this.searchControl.valueChanges
			.pipe(
				distinctUntilChanged(),
				takeUntilDestroyed(),
				filter(name => name.length === 0)
			)
			.subscribe(() => {
				this.store.dispatch(VenueActions.cleanFilterVenuesByName())
			})

		this.searchControl.valueChanges
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				takeUntilDestroyed(),
				filter(name => name.length >= 3)
			)
			.subscribe(name => {
				this.store.dispatch(VenueActions.filterVenuesByNameV2({ name: name }))
			})
	}

	cleanFilterAddress() {
		this.store.dispatch(AddressActions.cleanAddress())
		this.store.dispatch(VenueActions.applyFilter())
	}

	openBottomSheetCities() {
		this.store.dispatch(AddressActions.getAddresses())
		this.bottomSheet.open(CitiesComponent)
	}

	openBottomSheetCategoriesCusiines() {
		this.bottomSheet.open(CategoriesCuisinesComponent)
	}
}
