import { AfterViewInit, Component, ElementRef, inject } from '@angular/core'
import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router'
import {
	AuthService,
	ClientActions,
	InfiniteScrollService,
	VenueFilter,
	getClient,
	getFilterVenues,
	getStatusClient,
} from '@monorepo-channels/channels/domain'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { combineLatest, filter, first, map, of, take, tap } from 'rxjs'
import { getStatusVenues, getVenues, VenueActions } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'

import { DataLayerService, GeolocationService } from '@monorepo-channels/shared/util-helpers'
import { LoadingPageComponent, VenueCardUiComponent } from '@monorepo-channels/components/ui-pm'
import { FilterState } from '../filter/filter.state'
import { MatIconModule } from '@angular/material/icon'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { RegisterOrLoginComponent } from '../simple-bottom-sheets/register-or-login.component'

@Component({
	selector: 'feature-pm-venue-card-list',
	standalone: true,
	imports: [NgIf, NgFor, AsyncPipe, VenueCardUiComponent, LoadingPageComponent, MatIconModule],
	styles: `
	:host {
		@import 'mixin';
		display: grid;
		grid-template-columns: 1fr;
		gap: 24px;
		@include laptop-up {
			grid-template-columns: repeat(var(--number-of-cards, 3), 1fr);
			margin: 0 auto;
			gap: 32px;
			max-width: var(--container-desktop);
		}
	}
	`,
	template: `
		@if ((statusVenues$ | async) === 'pending') {
			<div style="padding: 58px 0;">
				<ui-pm-loading-page />
			</div>
		} @else {
			<ng-container *ngIf="vm$ | async as vm">
				<div
					style="display: flex; flex-direction: column; align-items: center; text-align: center"
					*ngIf="vm.venues?.length === 0"
				>
					<mat-icon
						svgIcon="pm-icons:close-circle-red"
						style="margin-bottom: 14px; width: 32px; height: 32px"
					></mat-icon>
					<p style="font-size: 16px; font-weight: 600">Restaurante não encontrado.</p>
					<p>Refaça sua busca.</p>
				</div>

				@if (vm.filterVenues && vm.filterVenues.length > 0) {
					<ui-pm-venue-card
						*ngFor="let venue of vm.filterVenues"
						[venue]="venue"
						[celebrations]="vm.client?.voucher?.id ? true : false"
						[client]="vm.client"
						(clickCard)="clickCard(venue)"
						(clickFavorite)="toggleFavorite($event)"
					/>
				} @else {
					<ui-pm-venue-card
						*ngFor="let venue of vm.venues"
						[venue]="venue"
						[client]="vm.client"
						[celebrations]="vm.client?.voucher?.id ? true : false"
						(clickCard)="clickCard(venue)"
						(clickFavorite)="toggleFavorite($event)"
					/>
				}
			</ng-container>
		}
	`,
})
export class VenueCardListComponent implements AfterViewInit {
	private store = inject(Store)
	private filterService = inject(FilterState)
	public router = inject(Router)
	public activatedRoute = inject(ActivatedRoute)
	public dataLayerService = inject(DataLayerService)
	public client = this.store.select(getClient)
	private authService = inject(AuthService)
	public infiniteScrollService = inject(InfiniteScrollService)
	private el = inject(ElementRef)
	private bottomSheet = inject(MatBottomSheet)

	public searchVenue = this.filterService.searchChange

	public venues$ = this.store.select(getVenues).pipe(
		tap(venues => {
			if (venues?.length === 0) {
				this.el.nativeElement.style.setProperty('--number-of-cards', '1')
			}
		})
	)

	public filterCelebrationsVenue$ = combineLatest([this.venues$, this.client]).pipe(
		map(([venues, client]) => {
			if (!venues) return []
			// Se o cliente não estiver logado ou não tiver vouchers, filtrar venues
			if (!client || !client.voucher) {
				return venues.filter(venue => !venue.isOnlyCelebration)
			}
			return venues
		})
	)

	public filterVenues$ = this.store.select(getFilterVenues)
	public vm$ = combineLatest([this.filterCelebrationsVenue$, this.filterVenues$, this.client]).pipe(
		map(([venues, filterVenues, client]) => ({
			venues,
			filterVenues,
			client,
		}))
	)
	public statusVenues$ = this.store.select(getStatusVenues).pipe(
		tap(status => {
			if (status === 'pending') {
				this.el.nativeElement.style.setProperty('--number-of-cards', '1')
			} else {
				this.el.nativeElement.style.setProperty('--number-of-cards', '3')
			}
		})
	)
	public statusClient$ = this.store.select(getStatusClient)

	constructor(private geoLocation: GeolocationService) {
		this.infiniteScrollService.loadMoreVenue$
			.pipe(
				filter(loadMore => loadMore),
				takeUntilDestroyed()
			)
			.subscribe(() => {
				this.store.dispatch(VenueActions.loadMoreVenue())
			})
		// Get currentLocation
		this.geoLocation.getCurrentLocation()

		const { clientId } = this.authService.getClientIdAndToken()

		const clientStatus$ = clientId
			? this.statusClient$.pipe(
					filter(status => status === 'success' || status === 'failure'),
					first()
			  )
			: of('no-login')

		// Combina status$ e location$ e espera o estado apropriado
		combineLatest([clientStatus$, this.geoLocation.location$])
			.pipe(
				takeUntilDestroyed(),
				filter(([, { state }]) => state !== 'idle'), // Ignora enquanto `location$` estiver em 'idle'
				first()
			)
			.subscribe(([, { state, position }]) => {
				if (state === 'denied' || state === 'prompt') {
					this.store.dispatch(VenueActions.getVenues())
				} else if (state === 'granted' && position) {
					this.store.dispatch(
						VenueActions.setLocation({
							latitude: position.latitude,
							longitude: position.longitude,
							maxDistance: 42_500_000,
						})
					)
				}
			})
	}

	clickCard(venue: VenueFilter) {
		this.dataLayerService.logEvent({
			event: 'CH_venue_card_click',
			venueId: venue.id,
			venueName: venue.name,
		})
		this.router.navigate(['/restaurante', venue.id])
	}

	ngAfterViewInit(): void {
		const sidenav = document.querySelector('.mat-sidenav-content')
		if (!sidenav) return
		this.infiniteScrollService.setScrollTarget(sidenav as HTMLElement)
	}

	toggleFavorite(venueId: string) {
		this.client.pipe(take(1)).subscribe({
			next: client => {
				if (!client) {
					this.bottomSheet.open(RegisterOrLoginComponent)
					return
				}
				this.store.dispatch(ClientActions.toggleFavorite({ venueId }))
			},
		})
	}
}
