import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { AccordionComponent } from './accordion.component'
import { AccordionContentDirective } from './directives/accordion-content.directive'
import { AccordionTitleDirective } from './directives/accordion-title.directive'
import { AccordionHeaderDirective } from './directives/accordion-header.directive'
import { AccordionItemComponent } from './accordion-item.component'
import { DivisorUiComponent } from '../divisor/divisor.component'

@NgModule({
	declarations: [
		AccordionComponent,
		AccordionContentDirective,
		AccordionItemComponent,
		AccordionTitleDirective,
		AccordionHeaderDirective,
	],
	imports: [CommonModule, DivisorUiComponent],
	exports: [
		AccordionComponent,
		AccordionItemComponent,
		AccordionContentDirective,
		AccordionTitleDirective,
		AccordionHeaderDirective,
	],
})
export class AccordionModule {}
