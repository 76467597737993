import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, inject, signal } from '@angular/core'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { Router } from '@angular/router'
import { ClientActions, ConciergeAuthService } from '@monorepo-channels/channels/domain'
import { LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { GenericErrorComponent } from '../../simple-bottom-sheets/generic-error.component'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { LoginConciergeService, LoginResponse } from './login-concierge.service'
import { HttpErrorResponse } from '@angular/common/http'
import { PartnerToken } from '@monorepo-channels/shared/util-helpers'

@Component({
	standalone: true,
	providers: [LoginConciergeService],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatDividerModule,
		ReactiveFormsModule,
		LoadingPageComponent,
	],
	selector: 'feature-pm-login-concierge',
	templateUrl: './login-concierge.component.html',
	styleUrls: ['./login-concierge.component.scss'],
})
export class LoginConciergeComponent implements AfterViewInit {
	private router = inject(Router)
	private fb = inject(FormBuilder)
	private partnerId = inject(PartnerToken)
	private actions$ = inject(Actions)
	private bottomSheet = inject(MatBottomSheet)
	private loginConciergeService = inject(LoginConciergeService)
	public loginForm = this.fb.nonNullable.group({
		login: ['', [Validators.required]],
		password: ['', [Validators.required]],
	})
	public isLoginFocused = signal(false)
	public isPasswordFocused = signal(false)
	public showPassword = signal(false)
	public conciergeAuthService = inject(ConciergeAuthService)
	public isLoadingLogin = false

	constructor() {
		this.actions$.pipe(ofType(ClientActions.loadClientFail), takeUntilDestroyed()).subscribe(action => {
			let message = action.error
			if (message.includes('Muitas requisições')) {
				message =
					'Você fez muitas tentativas. Por favor, aguarde um momento e tente novamente mais tarde.'
			}
			this.bottomSheet.open(GenericErrorComponent, {
				data: {
					error: message,
					title: 'Erro ao entrar',
					btnMessage: 'Fechar',
				},
			})
		})
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.loginForm.updateValueAndValidity()
		}, 0)
	}

	onInputChange(): void {
		const passwordControl = this.loginForm.get('password')
		if (passwordControl) {
			passwordControl.setValue((document.getElementById('password') as HTMLInputElement).value)
			passwordControl.updateValueAndValidity()
		}
	}

	login() {
		if (this.loginForm.invalid) return
		this.isLoadingLogin = true

		const dto = {
			username: this.loginForm.controls.login.value,
			password: this.loginForm.controls.password.value,
			partnerId: this.partnerId,
		}

		this.loginConciergeService.login(dto).subscribe({
			next: (response: LoginResponse) => {
				this.conciergeAuthService.setToken(response.access_token.token)
				this.isLoadingLogin = false
				this.router.navigate(['/concierge/painel-de-controle'])
			},
			error: (err: HttpErrorResponse) => {
				const errorMessage = err.error?.message || 'Erro desconhecido'
				this.bottomSheet.open(GenericErrorComponent, {
					data: {
						error: errorMessage,
						title: 'Erro ao entrar',
						btnMessage: 'Fechar',
					},
				})
				this.isLoadingLogin = false
			},
		})
	}
}
