import { Injectable, inject } from '@angular/core'
import { LOCAL_CLIENT_ID, LOCAL_TOKEN, LocalStorageService } from '@monorepo-channels/shared/util-helpers'
import { Client } from '../models/client/client'

@Injectable({ providedIn: 'root' })
export class AuthService {
	private localStorageService = inject(LocalStorageService)
	public redirectURL = '/'

	logout() {
		this.localStorageService.deleteTime(LOCAL_CLIENT_ID)
		localStorage.removeItem(LOCAL_TOKEN)
	}

	getClientIdAndToken() {
		return { token: this.getToken(), clientId: this.getClientId() }
	}

	setClientId(client: Client) {
		this.localStorageService.setTime(LOCAL_CLIENT_ID, client.id)
	}

	getClientId() {
		return this.localStorageService.getTime(LOCAL_CLIENT_ID)
	}

	getToken() {
		return localStorage.getItem(LOCAL_TOKEN)
	}

	setToken(token: string) {
		localStorage.setItem(LOCAL_TOKEN, token)
	}
}
