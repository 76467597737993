import { Component, Input, OnInit, signal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Benefits } from '@monorepo-channels/channels/domain'
import { MatIconModule } from '@angular/material/icon'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { delay, of, take } from 'rxjs'
import { MatExpansionModule } from '@angular/material/expansion'
import { OrDivisorComponent } from '@monorepo-channels/components/ui-common'

@Component({
	selector: 'ui-pm-benefits',
	standalone: true,
	imports: [CommonModule, MatIconModule, MatExpansionModule, OrDivisorComponent],
	templateUrl: './benefits.component.html',
	styleUrls: ['./benefits.component.scss'],
	animations: [
		trigger('expandCollapse', [
			state(
				'collapsed',
				style({
					height: '0',
					opacity: '0',
				})
			),
			state(
				'expanded',
				style({
					height: '*',
					opacity: '1',
				})
			),
			transition('collapsed <=> expanded', [animate('200ms ease-out')]),
		]),
	],
})
export class BenefitsUiComponent implements OnInit {
	@Input({ required: true }) benefits: Benefits[] = []
	@Input() celebrations: boolean = false
	@Input() type: 'simple' | 'expansion' = 'simple'

	steps = signal<Array<{ isExpanded: boolean; isAnimating: boolean }>>([])

	ngOnInit(): void {
		this.steps.set(this.benefits.map(() => ({ isExpanded: false, isAnimating: false })))
	}

	get sortedBenefits() {
		return [...this.benefits].sort((a, b) => a.order - b.order)
	}

	openStep(index: number, event?: Event) {
		if (event) {
			event.stopPropagation()
		}
		const currentSteps = [...this.steps()]
		currentSteps[index].isExpanded = true
		currentSteps[index].isAnimating = true
		this.steps.set(currentSteps)
	}

	closeStep(index: number) {
		const currentSteps = [...this.steps()]
		currentSteps[index].isExpanded = false
		of(1)
			.pipe(delay(300), take(1))
			.subscribe(() => {
				currentSteps[index].isAnimating = false
				this.steps.set(currentSteps)
			})
	}
}
