import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { PaymentActions } from './payment.actions'
import { map, catchError, filter, switchMap } from 'rxjs/operators'
import { AppState } from '../helper.state'
import { getPaymentOrder } from './payment.selectors'
import { CreatePaymentDto, PaymentsGateway } from '../../gateways/payment.gateway'
import { getClient } from '../client/client.selectors'
import { getSelectOrder } from '../order-history/order-history.selectors'
import { environment as env } from '@monorepo-channels/shared/util-environments'
import { DataLayerService } from '@monorepo-channels/shared/util-helpers'

@Injectable()
export class PaymentEffects {
	private actions$ = inject(Actions)
	private paymentGateway = inject(PaymentsGateway)
	private store = inject(Store<AppState>)
	private dataLayerService = inject(DataLayerService)

	createPayment$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(PaymentActions.createPayment),
			concatLatestFrom(() => [
				this.store.select(getSelectOrder),
				this.store.select(getPaymentOrder),
				this.store.select(getClient),
			]),
			filter(([, order, paymentOrder, client]) => !!order && !!paymentOrder && !!client),
			switchMap(([action, order, paymentOrder, client]) => {
				const paymentDto = {
					tableOrder: order?.id,
					orderId: order?.id,
					venueId: env.production ? order?.venue.id : '56c776ff0896b3cd13c6012b',
					clientId: client?.id,
					gatewayId: client?.selectedCategory?.gatewayId,
					value: Math.round(paymentOrder!.value * 100), // Em centavos, mínimo 1000
					gratuityValue: Math.round(paymentOrder!.gratuityValue * 100),
					// cpf: client?.cpf,
					dynamicCVV: action.dynamicCVV,
				}
				return this.paymentGateway.createPayment(paymentDto as CreatePaymentDto).pipe(
					map(res => {
						this.dataLayerService.logEvent({
							event: 'CH_payment_success',
							clientId: client?.id,
							orderId: order?.id,
							venueId: order?.venue.id,
						})
						return PaymentActions.createPaymentSuccess({ res })
					}),
					catchError(err => {
						this.dataLayerService.logEvent({
							event: 'CH_payment_error',
							clientId: client?.id,
							orderId: order?.id,
							venueId: order?.venue.id,
						})
						return of(PaymentActions.createPaymentFailure({ error: err?.error?.message }))
					})
				)
			})
		)
	})
}
