<div>
	<ui-pm-loading-page *ngIf="loadingVenue$ | async"></ui-pm-loading-page>
</div>

@if(vm$ | async; as vm) { @if(vm.venueDetail) {

<ui-pm-swiper-container>
	<swiper-slide *ngFor="let url of vm.venueDetail.bannersURLoriginal">
		<figure class="image-container">
			<img [src]="url" />
			<button mat-ripple class="go-back-container" (click)="goBack()">
				<mat-icon
					svgIcon="pm-icons:left-arrow"
					style="width: 11px; transform: translateX(-1px)"
				></mat-icon>
			</button>
			<button class="favorite" (click)="toggleFavorite(vm.venueDetail._id, vm.client)">
				<mat-icon
					[class.favorite-icon-anim]="vm.client?.isVenueFavorite(vm.venueDetail._id)"
					svgIcon="pm-icons:favorite"
					*ngIf="!vm.client || !vm.client?.isVenueFavorite(vm.venueDetail._id)"
				></mat-icon>
				<mat-icon
					svgIcon="pm-icons:favorite-full"
					[class.favorite-icon-anim]="vm.client.isVenueFavorite(vm.venueDetail._id)"
					*ngIf="vm.client && vm.client.isVenueFavorite(vm.venueDetail._id)"
				></mat-icon>
			</button>
		</figure>
	</swiper-slide>
</ui-pm-swiper-container>

<section class="container">
	<div style="display: flex; justify-content: space-between; align-items: center; position: relative">
		<h1 class="venue-name">{{vm.venueDetail.name}}</h1>
		<button mat-icon-button style="transform: translateY(10px)" (click)="share(vm.venueDetail)">
			<mat-icon svgIcon="pm-icons:share" style="width: 36px; height: 36px"></mat-icon>
		</button>
		<div *ngIf="copied()" @fadeInOut class="copy-notification">Link copiado</div>
	</div>
	<p class="venue-address">{{vm.venueDetail.address.value}}</p>

	<div class="tags">
		<ui-pm-tag *ngIf="vm.venueDetail.michelinGuide" [isMichelin]="vm.venueDetail.michelinGuide">
			Guia MICHELIN
		</ui-pm-tag>
		<ui-pm-tag [isNew]="vm.venueDetail.isNew" *ngIf="vm.venueDetail.isNew"> Novo </ui-pm-tag>
		<ui-pm-tag *ngFor="let cuisine of vm.venueDetail.cuisines">{{ cuisine }}</ui-pm-tag>
	</div>
	<div style="display: flex; gap: 21px">
		<a [href]="vm.venueDetail.mapsLink" target="_blank" style="flex: 1">
			<button mat-button class="normal-button" style="width: 100%">Ver localização</button>
		</a>
		<a [href]="vm.venueDetail.liveMenuURL" target="_blank" style="flex: 1">
			<button mat-button class="normal-button" style="width: 100%">Cardápio digital</button>
		</a>
	</div>
	<ui-common-divisor top="32px" bottom="20px" />
</section>

<section
	*ngIf="vm.client && vm.client.voucher && vm.client.voucher.value && vm.venueDetail.hasCelebrations"
	class="container"
>
	<section style="padding-bottom: 20px">
		<div style="display: flex; align-items: center; gap: 16px">
			<mat-icon svgIcon="pm-icons:celebrations" style="flex-shrink: 0"></mat-icon>
			<div style="display: flex; flex-direction: column; gap: 4px; color: var(--content-txt)">
				<p style="font-size: 18px; font-weight: 480">Celebrations</p>
				<p style="font-size: 14px; font-weight: 360">
					<span style="font-weight: 480">{{ vm.client.voucher.value| currency: 'BRL' }}</span>
					de crédito no mês do seu aniversário para utilizar no Programa Menu.
				</p>
			</div>
		</div>
	</section>

	<ui-common-or-divisor style="display: block; margin-bottom: 16px" />
</section>

<div class="container" *ngIf="vm.venueDetail.benefits.length > 0">
	<h2 class="benefits-title" style="margin-bottom: 16px">Benefícios do seu cartão</h2>
</div>
@if(vm.client) {
<div class="card-background">
	<div class="card-select container">
		<mat-icon svgIcon="pm-icons:wallet" style="width: 24px; height: 24px"></mat-icon>
		{{vm.client.selectedCategory?.nameCapitalized}} <span style="padding: 0 4px">| </span>
		<span class="card-select__change" (click)="openSelectCardOptions()">Trocar</span>
	</div>
</div>
}
<div class="container" style="margin-top: 20px; margin-bottom: 16px">
	<ui-pm-benefits
		[benefits]="vm.venueDetail.benefits"
		type="expansion"
		[celebrations]="vm.client?.voucher?.id && vm.venueDetail.hasCelebrations  ? true: false"
	></ui-pm-benefits>
</div>

<div class="container" *ngIf="vm.venueDetail.descriptionLong">
	<ui-common-divisor top="16px" bottom="20px" />
</div>

<section class="container">
	<ui-common-accordion [collapsing]="false" [divisor]="{ show: true, top: '10px', bottom: '10px' }">
		<ui-common-accordion-item title="Sobre o restaurante" *ngIf="vm.venueDetail.descriptionLong">
			<ng-template uiCommonAccordionContent>
				<div style="font-size: 14px">{{vm.venueDetail.descriptionLong}}</div>
			</ng-template>
		</ui-common-accordion-item>
		<ui-common-divisor top="10px" bottom="10px" />
		<ui-common-accordion-item title="Horários de funcionamento">
			<ng-template uiCommonAccordionContent>
				<ul style="font-size: 14px">
					<li *ngFor="let operationHour of vm.venueDetail.operationHours.value">
						<span style="width: 120px; display: inline-block">{{operationHour.day}}</span>
						{{operationHour.times}}
					</li>
				</ul>
			</ng-template>
		</ui-common-accordion-item>
	</ui-common-accordion>
</section>

<p style="color: var(--content-txt); font-size: 12px; margin-top: 10px" class="container">
	*Reserva e check-in sujeitos à disponibilidade de dia e horário.
</p>

<div style="height: 90px"></div>
<ui-pm-footer-buttons type="fixed">
	<button
		mat-raised-button
		(click)="onReservationClick(vm.venueDetail, vm.client)"
		[color]="vm.venueDetail.reservation?.enabled ? 'primary' : ''"
		[disabled]="!vm.venueDetail.reservation?.enabled"
		[disabledInteractive]="!vm.venueDetail.reservation?.enabled"
	>
		Reservar
	</button>
	<button
		mat-raised-button
		(click)="onCheckinClick(vm.venueDetail, vm.client)"
		[disabled]="vm.venueDetail.checkin.status === 'unavailable' || vm.venueDetail.checkin.status === 'disabled'"
		[disabledInteractive]="vm.venueDetail.checkin.status === 'unavailable' || vm.venueDetail.checkin.status === 'disabled'"
	>
		Check-in
	</button>
</ui-pm-footer-buttons>

} }
