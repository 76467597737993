import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class CepService {
	constructor(private http: HttpClient) {}

	findCep(cep: string) {
		return this.http.get<AddressViaCepResponse>(`https://viacep.com.br/ws/${cep}/json/`).pipe(
			map((response: AddressViaCepResponse) => ({
				address: response.logradouro,
				zipCode: response.cep,
				city: response.localidade,
				state: response.uf,
				neighborhood: response.bairro,
				...(response.erro ? { error: response.erro } : {}),
			}))
		)
	}
}

interface AddressViaCepResponse {
	bairro: string
	cep: string
	complemento: string
	ddd: string
	gia: string
	ibge: string
	localidade: string
	logradouro: string
	siafi: string
	uf: string
	erro?: string
}
