import { Component } from '@angular/core'
import { LoginClientComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	selector: 'pm-login-client-page',
	imports: [LoginClientComponent],
	template: '<feature-pm-login-client/>',
})
export class LoginClientPageComponent {}
