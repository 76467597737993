import { Component, inject } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { SidebarComponent } from './sidebar/sidebar.components'
import { AuthDirective } from '@monorepo-channels/channels/domain'
import { SidebarService } from './sidebar/sidebar.service'

@Component({
	standalone: true,
	imports: [RouterModule, MatIconModule, SidebarComponent],
	selector: 'pm-root',
	template: `
		@if (sidebarService.isOpen()) {
			<pm-sidebar>
				<router-outlet></router-outlet>
			</pm-sidebar>
		} @else {
			<router-outlet></router-outlet>
		}
	`,
	styles: '',
	hostDirectives: [AuthDirective],
})
export class AppComponent {
	public sidebarService = inject(SidebarService)
}
