@if (client$ | async; as client) {
	<article class="container">
		<ui-pm-user-profile [fullName]="client.fullName" style="margin-bottom: 12px" />
		<form [formGroup]="userForm">
			<div style="height: 24px"></div>
			<div class="form-field">
				<input
					type="text"
					id="email"
					class="form-field__input"
					formControlName="email"
					(focus)="getFieldState('email').setFocused(true)"
					(blur)="getFieldState('email').setFocused(false)"
					[ngClass]="{
						focused: getFieldState('email').focused(),
						filled: userForm.controls.email.value,
						editing: getFieldState('email').editing(),
						error: userForm.controls.email.errors && userForm.controls.email.dirty
					}"
				/>
				<label for="email" class="form-field__label">E-mail</label>
				<mat-icon
					(click)="toggleFieldEditing('email')"
					svgIcon="pm-icons:edit-card"
					class="edit-icon"
				>
					account_circle
				</mat-icon>
				<span class="form-field__underline"></span>
				<div class="error-container">
					@if (userForm.controls.email.errors?.['required'] && userForm.controls.email.dirty) {
						<span class="error-message">E-mail é obrigatório</span>
					} @else if (
						userForm.controls.email.errors?.['pattern'] && userForm.controls.email.dirty
					) {
						<span class="error-message">E-mail inválido</span>
					}
				</div>
			</div>

			<div style="height: 24px"></div>
			<div class="form-field">
				<input
					type="text"
					id="phone"
					class="form-field__input"
					formControlName="phone"
					mask="+00 (00) 00000-0000"
					(focus)="getFieldState('phone').setFocused(true)"
					(blur)="getFieldState('phone').setFocused(false)"
					[ngClass]="{
						focused: getFieldState('phone').focused(),
						filled: userForm.controls.phone.value,
						editing: getFieldState('phone').editing(),
						error: userForm.controls.phone.errors && userForm.controls.phone.dirty
					}"
				/>
				<label for="phone" class="form-field__label">Telefone</label>
				<mat-icon
					(click)="toggleFieldEditing('phone')"
					svgIcon="pm-icons:edit-card"
					class="edit-icon"
				>
					account_circle
				</mat-icon>
				<span class="form-field__underline"></span>
				<div class="error-container">
					@if (userForm.controls.phone.errors?.['required'] && userForm.controls.phone.dirty) {
						<span class="error-message">Telefone é obrigatório</span>
					} @else if (
						userForm.controls.phone.errors?.['pattern'] && userForm.controls.phone.dirty
					) {
						<span class="error-message">Telefone deve ter entre 10 e 11 dígitos</span>
					}
				</div>
			</div>
			<div style="height: 24px"></div>
			<div class="form-field">
				<input
					type="text"
					id="cpf"
					class="form-field__input"
					formControlName="cpf"
					mask="000.000.000-00"
					(focus)="getFieldState('cpf').setFocused(true)"
					(blur)="getFieldState('cpf').setFocused(false)"
					[ngClass]="{
						focused: getFieldState('cpf').focused(),
						filled: userForm.controls.cpf.value,
						editing: getFieldState('cpf').editing(),
						error: userForm.controls.cpf.errors && userForm.controls.cpf.dirty
					}"
				/>
				<label for="cpf" class="form-field__label">CPF</label>
				<mat-icon (click)="toggleFieldEditing('cpf')" svgIcon="pm-icons:edit-card" class="edit-icon">
					account_circle
				</mat-icon>
				<span class="form-field__underline"></span>
				<div class="error-container">
					@if (userForm.controls.cpf.errors?.['required'] && userForm.controls.cpf.dirty) {
						<span class="error-message">CPF é obrigatório</span>
					} @else if (userForm.controls.cpf.errors?.['invalidCpf'] && userForm.controls.cpf.dirty) {
						<span class="error-message">CPF inválido</span>
					}
				</div>
			</div>
		</form>
		<div style="margin-top: 24px">
			@if (isAnyFieldEditing()) {
				<button
					mat-raised-button
					[color]="userForm.valid ? 'primary' : ''"
					(click)="saveChanges()"
					[disabled]="userForm.invalid"
				>
					Salvar
				</button>
			} @else {
				<button mat-raised-button routerLink="/">Sair</button>
			}
		</div>
	</article>
}
