import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PlatformModule } from '@angular/cdk/platform'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
	selector: 'ui-pm-loading-page',
	standalone: true,
	imports: [CommonModule, PlatformModule, MatProgressSpinnerModule],
	template: `
		<div style="width: 100%; height: var(--height, 30dvh); display: grid; place-items: center">
			<mat-spinner diameter="30"></mat-spinner>
		</div>
	`,
})
export class LoadingPageComponent {}
