import { Component, computed, inject, OnInit, signal } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import {
	ConfirmSliderUiComponent,
	FooterButtonsComponent,
	LoadingPageComponent,
	OrderSuccessComponent,
} from '@monorepo-channels/components/ui-pm'
import { TermsAndConditionsCheckboxComponent } from '../terms-and-conditions/checkbox/terms-and-conditions-checkbox.componen'
import { Store } from '@ngrx/store'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import {
	CheckinActions,
	CheckinDate,
	Client,
	ClientActions,
	VenueActions,
	getCheckinAvailabilities,
	getCheckinStatusAvailabilities,
	getCheckinStatusConfirm,
	getClient,
	getSelectedVenue,
	getVoucherValueCheckin,
} from '@monorepo-channels/channels/domain'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest, map } from 'rxjs'
import { CommonModule } from '@angular/common'
import { CapitalizeFirstPipe } from '@monorepo-channels/shared/util-helpers'
import { ChooseCelebrationsComponent } from '../choose-celebrations/choose-celebrations.component'
import { Actions, ofType } from '@ngrx/effects'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { GenericErrorComponent } from '../simple-bottom-sheets/generic-error.component'

@Component({
	selector: 'feature-pm-checkin',
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		ConfirmSliderUiComponent,
		FooterButtonsComponent,
		TermsAndConditionsCheckboxComponent,
		OrderSuccessComponent,
		LoadingPageComponent,
		CapitalizeFirstPipe,
		ChooseCelebrationsComponent,
	],
	templateUrl: './checkin.component.html',
	styleUrls: ['./checkin.component.scss'],
})
export class CheckinComponent implements OnInit {
	public confirm = signal(false)
	public eula = signal(false)
	public eulaFromClient = signal(false)
	private store = inject(Store)
	private actions$ = inject(Actions)
	private bottomSheet = inject(MatBottomSheet)
	private router = inject(Router)
	private route = inject(ActivatedRoute)
	public isDisabled = computed(() => !this.confirm() || !this.eula())
	public partysize = signal(1)
	public checkinDate = new CheckinDate()

	private venueId: string | null = null
	public showCelebrationOptions = signal(false)

	vm$ = combineLatest([
		this.store.select(getClient),
		this.store.select(getCheckinStatusAvailabilities),
		this.store.select(getCheckinAvailabilities),
		this.store.select(getCheckinStatusConfirm),
		this.store.select(getSelectedVenue),
		this.store.select(getVoucherValueCheckin),
	]).pipe(
		map(([client, statusAvailability, availabilities, statusConfirm, venue, voucherValue]) => ({
			client,
			statusAvailability,
			availabilities,
			statusConfirm,
			venue,
			voucherValue,
		}))
	)

	public toggleConfirm(value: boolean) {
		this.confirm.set(value)
	}
	public toggleTermsAndConditions(value: boolean) {
		this.eula.set(value)
	}

	constructor() {
		this.store
			.select(getClient)
			.pipe(takeUntilDestroyed())
			.subscribe(client => {
				this.eulaFromClient.set(client?.agreements.eula ?? false)
				this.eula.set(client?.agreements.eula ?? false)
				if (client?.voucher?.value) {
					this.showCelebrationOptions.set(true)
				}
			})

		this.actions$.pipe(takeUntilDestroyed(), ofType(CheckinActions.checkInConfirmFailure)).subscribe({
			next: ({ error }) => {
				this.confirm.set(false)
				this.bottomSheet.open(GenericErrorComponent, {
					data: {
						error: error,
						title: 'Erro ao confirmar check-in',
						btnMessage: 'Fechar',
					},
				})
			},
		})
	}

	ngOnInit(): void {
		this.store.dispatch(CheckinActions.checkinReset())
		this.venueId = this.route.snapshot.paramMap.get('venueId')
		if (this.venueId) {
			this.store.dispatch(VenueActions.getOneVenue({ venueId: this.venueId }))
			if (!this.showCelebrationOptions()) {
				this.store.dispatch(CheckinActions.loadAvailabilities({ venueId: this.venueId }))
			}
		}
	}

	setCelebration(value: string | null, client: Client) {
		if (value === 'voucher' && this.venueId) {
			this.store.dispatch(
				CheckinActions.loadAvailabilities({ venueId: this.venueId, voucherId: client.voucher?.id })
			)
		} else if (value === 'benefits' && this.venueId) {
			this.store.dispatch(CheckinActions.loadAvailabilities({ venueId: this.venueId }))
		}
		this.showCelebrationOptions.set(false)
	}

	confirmCheckin() {
		this.store.dispatch(
			CheckinActions.checkInConfirmation({ checkInDate: this.checkinDate, partySize: this.partysize() })
		)
		// só vou atualizar o cliente se o eula from client for false e o eula for true
		if (!this.eulaFromClient() && this.eula()) {
			this.store.dispatch(
				ClientActions.updateClientBackend({ client: { agreements: { eula: this.eula() } } })
			)
		}
	}

	okClick() {
		this.router.navigate(['/'])
	}

	increase() {
		if (this.partysize() < 10) {
			this.partysize.update(value => value + 1)
		}
	}

	decrease() {
		if (this.partysize() > 1) {
			this.partysize.update(value => value - 1)
		}
	}
}
