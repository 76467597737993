import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatIconModule } from '@angular/material/icon'
import { Client, getClient } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { SelectCardOptionsComponent } from './select-card-options/select-card-options.component'
import { RegisterOrLoginComponent } from '../simple-bottom-sheets/register-or-login.component'

@Component({
	standalone: true,
	selector: 'feature-pm-select-card',
	imports: [CommonModule, MatIconModule],
	templateUrl: './select-card.component.html',
	styleUrls: ['./select-card.component.scss'],
})
export class SelectCardComponent {
	private store = inject(Store)
	public client$ = this.store.select(getClient)
	private bottomSheet = inject(MatBottomSheet)

	constructor() {}

	openSelectCardOptions(client: Client | null) {
		if (!client) {
			this.bottomSheet.open(RegisterOrLoginComponent)
			return
		}
		this.bottomSheet.open(SelectCardOptionsComponent)
	}
}
