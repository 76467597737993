<feature-pm-select-card />
<feature-pm-celebrations-card />

<ui-pm-swiper-container>
	<swiper-slide *ngFor="let banner of banners">
		<figure class="image-container" (click)="banner.click()">
			<img [src]="banner.imageUrl" [alt]="banner.altText" style="cursor: pointer" />
			<figcaption class="image-text">
				<h2 class="image-title">{{banner.title}}</h2>
				<p class="image-description" [innerHTML]="banner.description"></p>
			</figcaption>
		</figure>
	</swiper-slide>
</ui-pm-swiper-container>

<article class="venue-filter">
	<feature-pm-filter></feature-pm-filter>
</article>

<main class="venue-list">
	<feature-pm-venue-card-list />
</main>
