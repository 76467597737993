import { CommonModule } from '@angular/common'
import {
	AfterViewInit,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	ElementRef,
	inject,
	ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'ui-pm-swiper-container',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	template: `
		<swiper-container init="false" #swiperContainer style="z-index: 0; position: relative;">
			<ng-content></ng-content>
		</swiper-container>
	`,
	styles: `
  
    `,
})
export class SwiperUiComponent implements AfterViewInit {
	@ViewChild('swiperContainer')
	swiperContainer!: ElementRef

	public router = inject(Router)

	private config = {
		injectStyles: [
			`
      .swiper-pagination-bullet {
            width: 8px;
			height: 8px;
			background-color: var(--content-txt);
			opacity: 0.5;
			border-radius: 30px;
			cursor: pointer;
			transition: background-color 0.3s; 
      }

      .swiper-pagination-bullet-active {
			    width: 24px;
				opacity: 1;
				background-color: white;
      }
      `,
		],
		pagination: {
			clickable: true,
		},
		autoplay: { delay: 5000 },
		speed: 700,
		loop: true,
	}

	ngAfterViewInit(): void {
		Object.assign(this.swiperContainer.nativeElement, this.config)
		this.swiperContainer.nativeElement.initialize()
	}
}
