import { inject, Injectable, signal } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Cuisine, getClient } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { tap } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class FilterState {
	public categories = ['Casual', 'Sofisticado', 'Bares']

	public excludeBenefitIds = [{ _id: 0, display: 'Sem Desconto', name: 'desconto' }]

	private store = inject(Store)

	public benefitIds: { _id: number; display: string; name: string }[] = []

	public searchChange = signal('')
	public selectedCategory = signal<Array<string>>([])
	public selectedBenefitIds = signal<typeof this.benefitIds>([])
	public selectedExcludeBenefitIds = signal<typeof this.excludeBenefitIds>([])
	public selectedCuisine = signal<Array<Cuisine>>([])
	public michelinGuide = signal({ name: 'Guia MICHELIN', selected: false })
	public newRestaurant = signal({ name: 'Novo restaurante', selected: false })
	public count = signal(0)

	constructor() {
		this.store
			.select(getClient)
			.pipe(
				tap(client => {
					this.benefitIds = [
						{ _id: 0, display: 'Desconto', name: 'desconto' },
						{ _id: 1, display: 'Mesa Vip Espumante', name: 'espumante' },
						{ _id: 2, display: 'Mesa Vip Entrada', name: 'entrada' },
						{ _id: 3, display: 'Sobremesa', name: 'sobremesa' },
						...(client?.isPrincipalSelected()
							? [{ _id: 4, display: 'Isenção de taxa de rolha', name: 'rolha' }]
							: []),
					]
				}),
				takeUntilDestroyed()
			)
			.subscribe()
	}

	setCategory(category: string) {
		// Toggle category in selectedCategory array
		this.selectedCategory.set(
			this.selectedCategory().includes(category)
				? this.selectedCategory().filter(c => c !== category)
				: [...this.selectedCategory(), category]
		)
	}

	setBenefit(benefitIds: (typeof this.benefitIds)[0]) {
		this.selectedBenefitIds.set(
			this.includesBenefit(benefitIds)
				? this.selectedBenefitIds().filter(c => c._id !== benefitIds._id)
				: [...this.selectedBenefitIds(), benefitIds]
		)
	}

	setCuisine(cuisine: Cuisine) {
		// Toggle cuisine in selectedCuisine array
		this.selectedCuisine.set(
			this.includesCuisine(cuisine)
				? this.selectedCuisine().filter(c => c._id !== cuisine._id)
				: [...this.selectedCuisine(), cuisine]
		)
	}

	setTrueMichelinGuide() {
		this.michelinGuide.update(current => ({ ...current, selected: true }))
	}

	toggleMichilenGuide() {
		this.michelinGuide.update(current => ({ ...current, selected: !current.selected }))
	}

	toggleNewRestaurante() {
		this.newRestaurant.update(current => ({ ...current, selected: !current.selected }))
	}

	includesCategory(category: string) {
		return this.selectedCategory().includes(category)
	}

	includesCuisine(cuisine: Cuisine) {
		return this.selectedCuisine().some(c => c._id === cuisine._id)
	}

	includesBenefit(benefitIds: (typeof this.benefitIds)[0]) {
		return this.selectedBenefitIds().some(c => c._id === benefitIds._id)
	}

	setExcludeBenefitIds(excludeBenefitIds: (typeof this.excludeBenefitIds)[0]) {
		this.selectedExcludeBenefitIds.set(
			this.includesExcludeBenefit(excludeBenefitIds)
				? this.selectedExcludeBenefitIds().filter(c => c._id !== excludeBenefitIds._id)
				: [...this.selectedExcludeBenefitIds(), excludeBenefitIds]
		)
	}

	includesExcludeBenefit(excludeBenefitIds: (typeof this.excludeBenefitIds)[0]) {
		return this.selectedExcludeBenefitIds().some(c => c._id === excludeBenefitIds._id)
	}

	resetState() {
		this.selectedCategory.set([])
		this.selectedCuisine.set([])
		this.selectedBenefitIds.set([])
		this.selectedExcludeBenefitIds.set([])
		this.michelinGuide.update(current => ({ ...current, selected: false }))
		this.newRestaurant.update(current => ({ ...current, selected: false }))
	}

	assignCount() {
		let count = 0
		if (this.selectedCategory().length !== 0) count += this.selectedCategory().length
		if (this.selectedCuisine().length !== 0) count += this.selectedCuisine().length
		if (this.selectedBenefitIds().length !== 0) count += this.selectedBenefitIds().length
		if (this.selectedExcludeBenefitIds().length !== 0) count += this.selectedExcludeBenefitIds().length
		if (this.michelinGuide().selected) count++
		if (this.newRestaurant().selected) count++
		this.count.set(count)
	}
}
