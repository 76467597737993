import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'

@Component({
	standalone: true,
	imports: [MatButtonModule, CommonModule],
	selector: 'feature-pm-terms-and-conditions-checkbox',
	template: `
		<label style=" bottom: 110px; cursor: pointer;" [ngStyle]="{ position: this.position }">
			Li e aceito os
			<span style="color:var(--content-primary)">termos e condições</span> para utilizar {{ use }}.
			<input type="checkbox" (change)="changeConfirm($event)" />
		</label>
	`,
	styles: `
        input[type="checkbox"] {
            accent-color: var(--content-primary); 
        }
    `,
})
export class TermsAndConditionsCheckboxComponent {
	@Output() changeInput = new EventEmitter<boolean>()
	@Input() position: 'fixed' | 'static' = 'fixed'
	@Input() use: 'benefícios' | 'o Programa Menu' = 'benefícios'

	public changeConfirm(event: Event) {
		const target = event.target as HTMLInputElement
		this.changeInput.emit(target.checked)
	}
}
