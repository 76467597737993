import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, inject } from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatIconModule } from '@angular/material/icon'
import { Client, ClientActions, getClient } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { CelebrationsComponent } from '../simple-bottom-sheets/celebrations.component'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { CelebrationsModalComponent } from './celebrations-modal/celebrations-modal.component'
import { combineLatest, filter, map, take } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'

@Component({
	standalone: true,
	selector: 'feature-pm-celebrations-card',
	imports: [CommonModule, MatIconModule, MatDialogModule],
	templateUrl: './celebrations-card.component.html',
	styleUrls: ['./celebrations-card.component.scss'],
})
export class CelebrationsCardComponent implements AfterViewInit {
	private store = inject(Store)
	private actions$ = inject(Actions)
	public client$ = this.store.select(getClient)
	private bottomSheet = inject(MatBottomSheet)
	private dialog = inject(MatDialog)

	constructor() {}

	ngAfterViewInit() {
		combineLatest([this.client$, this.actions$.pipe(ofType(ClientActions.loadClientSuccess))])
			.pipe(
				map(([client]) => client),
				filter(
					(client): client is Client & { voucher: { value: string } } =>
						!!client && !!client.voucher?.value
				),
				take(1)
			)
			.subscribe(client => {
				this.dialog.open(CelebrationsModalComponent, {
					width: '307px',
					height: 'auto',
					data: client.voucher.value,
				})
			})
	}

	openCelebrations(voucherValue: string | number) {
		this.bottomSheet.open(CelebrationsComponent, { data: voucherValue })
	}
}
