import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { ClientActions } from '@monorepo-channels/channels/domain'
import { BottomSheetHeaderComponent, FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'
import { Store } from '@ngrx/store'

@Component({
	standalone: true,
	imports: [CommonModule, BottomSheetHeaderComponent, FooterButtonsComponent, MatButtonModule],
	selector: 'feature-pm-exit',
	template: `
		<ui-pm-bottom-sheet-header title="Sair"></ui-pm-bottom-sheet-header>
		<div style="padding-left: 20px; padding-right: 20px; font-size: 14px; color: var(--content-txt)">
			<p>
				Você está logado como <span style="color: var(--content-primary)"> {{ data.client }} </span>,
				<br />
				tem certeza que deseja sair?
			</p>
			<p
				style="color: var(--content-primary); text-decoration: underline; cursor: pointer; margin-top: 40px"
				(click)="logout()"
			>
				Fazer login com outra conta.
			</p>
		</div>
		<ui-pm-footer-buttons>
			<button mat-raised-button (click)="bottomSheetRef.dismiss()">Fechar</button>
			<button mat-raised-button color="primary" (click)="logout()">Sair</button>
		</ui-pm-footer-buttons>
	`,
})
export class ExitComponent {
	private store = inject(Store)
	public data: { client: string } = inject(MAT_BOTTOM_SHEET_DATA)
	public bottomSheetRef = inject(MatBottomSheetRef)

	logout() {
		this.bottomSheetRef.dismiss()
		this.store.dispatch(ClientActions.logoutClient())
	}
}
