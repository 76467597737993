<main class="celebrations">
	<div class="celebrations__wrapper">
		<div class="celebrations__circle-wrapper">
			<div class="celebrations__circle">
				<div class="celebrations__icon-wrapper">
					<mat-icon class="celebrations__icon" svgIcon="pm-icons:celebrations"
						>celebrations</mat-icon
					>
				</div>
			</div>
		</div>
	</div>
	<h1 class="celebrations__title">Celebrations!</h1>
	<p class="celebrations__description">
		Você ganhou <span class="celebrations__amount">{{ voucherValue | currency: 'BRL' }}</span> de crédito
		no mês do seu aniversáro para utilizar no Programa Menu. Explore os restaurantes parceiros e realize
		uma reserva!
	</p>
	<button mat-raised-button class="celebrations__button" (click)="matDialogRef.close()">
		Ver Restaurantes
	</button>
</main>
