import { CommonModule } from '@angular/common'
import { Component, inject, signal } from '@angular/core'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { BottomSheetHeaderComponent, LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { catchError, finalize, of, startWith, Subject, switchMap, take } from 'rxjs'
import { ClientService } from '../user-pages/common/client.service'
import { countdownFrom } from '../user-pages/register/register.component'
import { GenericErrorComponent } from './generic-error.component'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		BottomSheetHeaderComponent,
		MatButtonModule,
		ReactiveFormsModule,
		LoadingPageComponent,
	],
	providers: [ClientService],
	selector: 'feature-pm-otp-component',
	styleUrls: ['../user-pages/common/page-common.scss'],
	template: `
		<ui-pm-bottom-sheet-header title="Código"></ui-pm-bottom-sheet-header>
		<article class="container">
			<p style="margin-bottom: 16px">
				Para confirmar a alteração, por favor, verifique o
				{{
					data.dto.key === 'email' || data.dto.key === 'cpf'
						? 'código enviado para o e-mail ' + data.email
						: 'código enviado para o telefone: ' + data.dto.value
				}}
			</p>
			<form [formGroup]="confirmCodeForm" (ngSubmit)="confirmCode()">
				<section class="otp-input-container">
					<input
						type="text"
						maxlength="1"
						class="otp-input"
						(keyup)="onKeyUp($event)"
						(keydown)="onKeyDown($event, i)"
						(paste)="onPaste($event)"
						(input)="onInput($event)"
						*ngFor="let item of [0, 1, 2, 3, 4, 5]; let i = index"
						[formControlName]="'otp' + i"
					/>
				</section>
				@if (isLoadingResend()) {
					<p class="resend">Reenviando...</p>
				} @else {
					<ng-container *ngIf="countdown$ | async as value; else resend">
						<p class="resend">Reenviar o código em {{ value }} segundos.</p>
					</ng-container>
					<ng-template #resend>
						<p class="resend">
							Não recebeu o código?
							<span class="resend-txt" (click)="sendValidationCode()">Reenviar</span>
						</p>
					</ng-template>
				}
				@if (isLoading()) {
					<ui-pm-loading-page style="--height: 100px" />
				} @else {
					<button
						mat-raised-button
						style="margin-top: 32px;padding-left: 20px; padding-right: 20px; margin-bottom: 20px;"
						[disabled]="!confirmCodeForm.valid"
						[color]="!confirmCodeForm.valid ? '' : 'primary'"
					>
						Salvar
					</button>
				}
			</form>
		</article>
	`,
})
export class OtpComponent {
	private bottomSheetRef = inject(MatBottomSheetRef)
	private matBottomSheet = inject(MatBottomSheet)
	public data: { clientId: string; dto: { key: string; value: string }; email?: string } =
		inject(MAT_BOTTOM_SHEET_DATA)
	private fb = inject(FormBuilder)
	private clientService = inject(ClientService)

	public confirmCodeForm = this.fb.nonNullable.group({
		otp0: ['', [Validators.required]],
		otp1: ['', [Validators.required]],
		otp2: ['', [Validators.required]],
		otp3: ['', [Validators.required]],
		otp4: ['', [Validators.required]],
		otp5: ['', [Validators.required]],
	})

	public isLoadingResend = signal(false)
	public isLoading = signal(false)
	private readonly resendSubject = new Subject<void>()
	readonly resend$ = this.resendSubject.asObservable()
	readonly countdown$ = this.resend$.pipe(
		startWith(0),
		switchMap(() => countdownFrom(60))
	)

	constructor() {
		this.resendSubject.next()
		this.sendValidationCode()
	}

	onKeyUp(event: KeyboardEvent) {
		const currentInput = event.target as HTMLInputElement
		if (currentInput.value.length === 1) {
			const nextInput = currentInput.nextElementSibling as HTMLInputElement
			if (nextInput) {
				nextInput.focus()
			}
		}
	}

	onKeyDown(event: KeyboardEvent, index: number) {
		const currentInput = event.target as HTMLInputElement
		if (event.key === 'Backspace' && currentInput.value.length === 0 && index > 0) {
			const previousInput = currentInput.previousElementSibling as HTMLInputElement
			if (previousInput) {
				previousInput.focus()
			}
		}
	}

	onPaste(event: ClipboardEvent) {
		let pastedData = event.clipboardData?.getData('text/plain')
		pastedData = pastedData?.trim()
		if (pastedData) {
			this.confirmCodeForm.patchValue({
				otp0: pastedData[0],
				otp1: pastedData[1],
				otp2: pastedData[2],
				otp3: pastedData[3],
				otp4: pastedData[4],
				otp5: pastedData[5],
			})

			const lastInput = event.target as HTMLInputElement
			if (lastInput && lastInput.nextElementSibling) {
				;(lastInput.nextElementSibling as HTMLInputElement).focus()
			}
		}
	}

	onInput(event: Event) {
		const currentInput = event.target as HTMLInputElement
		currentInput.value = currentInput.value.toUpperCase()
	}

	sendValidationCode() {
		this.isLoadingResend.set(true)
		this.confirmCodeForm.reset()
		this.resendSubject.next()
		const type = ['email', 'cpf'].includes(this.data.dto.key) ? 'email' : 'sms'
		this.clientService
			.sendValidationCode(this.data.clientId, type)
			.pipe(
				finalize(() => this.isLoadingResend.set(false)),
				catchError(this.getError),
				take(1)
			)
			.subscribe()
	}

	confirmCode() {
		if (this.confirmCodeForm.invalid) return
		const registrationCode = [
			this.confirmCodeForm.controls.otp0.value,
			this.confirmCodeForm.controls.otp1.value,
			this.confirmCodeForm.controls.otp2.value,
			this.confirmCodeForm.controls.otp3.value,
			this.confirmCodeForm.controls.otp4.value,
			this.confirmCodeForm.controls.otp5.value,
		]
			.join('')
			.toUpperCase()

		this.isLoading.set(true)
		this.clientService
			.updateOTP(this.data.clientId, { registrationCode, [this.data.dto.key]: this.data.dto.value })
			.pipe(
				take(1),
				finalize(() => this.isLoading.set(false)),
				catchError(this.getError)
			)
			.subscribe({
				next: () => {
					this.bottomSheetRef.dismiss()
				},
			})
	}

	private getError = (error: { error: { message: string } }) => {
		this.bottomSheetRef.dismiss()
		this.matBottomSheet.open(GenericErrorComponent, {
			data: {
				error: error?.error?.message,
				title: 'Erro',
				btnMessage: 'Fechar',
			},
		})
		return of(null)
	}

	clickBtn() {
		this.bottomSheetRef.dismiss()
	}
}
