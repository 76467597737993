import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { Router } from '@angular/router'
import { BottomSheetHeaderComponent, FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	imports: [CommonModule, BottomSheetHeaderComponent, FooterButtonsComponent, MatButtonModule],
	selector: 'feature-pm-register-card',
	template: `
		<ui-pm-bottom-sheet-header title="Complete o cadastro do cartão"></ui-pm-bottom-sheet-header>

		<p style="padding: 20px">
			Complete o cadastro do seu cartão de crédito bradesco para realizar reservas e usufruir de seus
			benefícios.
		</p>

		<ui-pm-footer-buttons>
			<button mat-raised-button color="primary" (click)="registerCard()">Completar cadastro</button>
		</ui-pm-footer-buttons>
	`,
})
export class RegisterCardComponent {
	private router = inject(Router)
	public bottomSheetRef = inject(MatBottomSheetRef)

	registerCard() {
		this.router.navigate(['carteira/cadastrar-cartao'])
		this.bottomSheetRef.dismiss()
	}
}
