import { CommonModule } from '@angular/common'
import { booleanAttribute, Component, Input } from '@angular/core'

@Component({
	standalone: true,
	selector: 'ui-pm-footer-buttons',
	imports: [CommonModule],
	template: `
		<div style="height: 46px" *ngIf="type === 'fixed'"></div>
		<div
			[ngClass]="{
				'bottom-sheet-footer': true,
				fixed: type === 'fixed' || type === 'fixed-order',
				'fixed-order': type === 'fixed-order',
				'no-border': !border
			}"
		>
			<ng-content></ng-content>
		</div>
	`,
	styles: `
		@import 'mixin';
		.bottom-sheet-footer {
			display: flex;
			justify-content: space-between;
			height: 96px;
			align-items: center;
			padding: 20px;
			padding-top: 24px;
			gap: 24px;
			border-top: 1px solid var(--content-border);
			margin-top: 19px;
		}
		.no-border {
			border-top: none;
		}
		.fixed {
			position: fixed;
			z-index: 99;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: var(--background-page);
			@include laptop-up{
				max-width: var(--max-width-desktop);
				margin: 0 auto;
			}
		}

		.fixed-order {
			@include tablet-up{
				max-width: var(--max-width-tablet);
				margin: 0 auto;
			}

		}
`,
})
export class FooterButtonsComponent {
	@Input() type: 'fixed' | 'fixed-order' | 'static' = 'static'
	@Input({ transform: booleanAttribute }) border: boolean = true
}
