<table mat-table class="client-table" [dataSource]="dataSource.data">
	<!-- Nome Column -->
	<ng-container matColumnDef="nome">
		<th mat-header-cell *matHeaderCellDef class="custom-column-title">Nome</th>
		<td mat-cell *matCellDef="let client">{{ client.name }} {{ client.lastName }}</td>
	</ng-container>

	<!-- CPF Column -->
	<ng-container matColumnDef="cpf">
		<th mat-header-cell *matHeaderCellDef class="custom-column-title">CPF</th>
		<td mat-cell *matCellDef="let client">{{ client.cpf | mask: '000.000.000-00' }}</td>
	</ng-container>

	<!-- Telefone Column -->
	<ng-container matColumnDef="telefone">
		<th mat-header-cell *matHeaderCellDef class="custom-column-title">Telefone</th>
		<td mat-cell *matCellDef="let client">{{ client.phone | mask: '+00 (00) 00000-0000' }}</td>
	</ng-container>

	<!-- E-mail Column -->
	<ng-container matColumnDef="email">
		<th mat-header-cell *matHeaderCellDef class="custom-column-title">E-mail</th>
		<td mat-cell *matCellDef="let client">{{ client.email }}</td>
	</ng-container>

	<!-- Cartão do cliente Column -->
	<ng-container matColumnDef="cartao">
		<th mat-header-cell *matHeaderCellDef class="custom-column-title">Cartão do cliente</th>
		<td mat-cell *matCellDef="let client">{{ client.categories[0].name }}</td>
	</ng-container>

	<!-- Ações Column -->
	<ng-container matColumnDef="acoes">
		<th mat-header-cell *matHeaderCellDef class="custom-column-title">Ações</th>
		<td mat-cell *matCellDef="let client">
			<div class="actions-buttons">
				<button
					mat-raised-button
					color="primary"
					style="width: 110px; height: 30px"
					(click)="loginClient(client)"
				>
					Selecionar
				</button>
				<button mat-raised-button style="width: 110px; height: 30px">Editar</button>
			</div>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<!-- Not found clients card-->
@if (dataSource.data.length === 0) {
	<div class="not-found-card">
		<mat-icon svgIcon="pm-icons:close-circle"></mat-icon>
		<h2>Nenhum cliente encontrado.</h2>
		<p>Revise as informações fornecidas na busca.</p>
	</div>
}
