import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { Category, ClientActions, isLoadingRemoveCard } from '@monorepo-channels/channels/domain'
import {
	BottomSheetHeaderComponent,
	FooterButtonsComponent,
	LoadingPageComponent,
} from '@monorepo-channels/components/ui-pm'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { take } from 'rxjs'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		BottomSheetHeaderComponent,
		FooterButtonsComponent,
		MatButtonModule,
		MatIconModule,
		LoadingPageComponent,
	],
	selector: 'feature-pm-remove-card',
	template: `
		<ui-pm-bottom-sheet-header title="Remover cartão"></ui-pm-bottom-sheet-header>

		<p style="padding-left: 20px; padding-right: 20px">Tem certeza que deseja remover o cartão?</p>

		<ul class="card-list">
			<li class="card-list__item">
				<div class="card-list__moving">
					<mat-icon svgIcon="pm-icons:wallet" style="margin-right: 18px" />
					<div style="display: flex; flex-direction: column">
						<p class="card-list__name">{{ card.name }}</p>
						<p style="display: flex; align-items: center" *ngIf="card.lastFourDigits">
							<mat-icon svgIcon="pm-icons:combined-shape" style="width: 40px" />
							<span class="card-list__number">{{ card.lastFourDigits }}</span>
						</p>
					</div>
					<!-- <mat-icon
						svgIcon="pm-icons:right-arrow-grey"
						style="width: 11px; height: 12px; margin-left: auto"
					/> -->
				</div>
			</li>
		</ul>
		@if (loading$ | async) {
			<div
				style="display: flex; justify-content: center; align-items: center; height: 96px; border-top: 1px solid var(--content-border); margin-top: 19px;"
			>
				<ui-pm-loading-page></ui-pm-loading-page>
			</div>
		} @else {
			<ui-pm-footer-buttons>
				<button mat-raised-button (click)="bottomSheetRef.dismiss()">Cancelar</button>
				<button mat-raised-button color="primary" (click)="removeCard()">Remover</button>
			</ui-pm-footer-buttons>
		}
	`,
	styles: `
.card-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 12px;
	gap: 12px;

	ul, li {
		list-style: none;
		padding: 0;
	}

	&__item {
		position: relative;
		width: 335px;
		height: 60px;
	}

	& .icon-container {
		position: absolute;
		height: 100%;
		display: flex;
		gap: 14px;
		right: 20px;
		top: 0;
	}
	& .icon-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__moving {
		display: flex;
		align-items: center;
		padding: 12px 20px;
		border-radius: 8px;
		box-shadow: var(--card-box-shadow);
		position: relative;
		background-color: var(--background-page);
		z-index: 2;

		width: 335px;
		height: 60px;

		transform: translate(0);
		transition: all 0.5s;
		cursor: pointer;
	}

	&__name {
		font-size: 14px;
		font-weight: 480;
		color: var(--content-txt);
	}

	&__number {
		font-size: 12px;
		font-weight: 360;
		color: var(--content-txt);
	}



	& .lastFourDigits {
		position: absolute;
		top: 16px;
		left: 63px;
	}
}
	`,
})
export class RemoveCardComponent {
	public bottomSheetRef = inject(MatBottomSheetRef)
	public card: Category = inject(MAT_BOTTOM_SHEET_DATA)
	private store = inject(Store)
	private actions$ = inject(Actions)
	public loading$ = this.store.select(isLoadingRemoveCard)

	removeCard() {
		this.bottomSheetRef.disableClose = true
		this.store.dispatch(ClientActions.removeCategory({ categoryId: this.card.categoryId }))
		this.actions$.pipe(ofType(ClientActions.removeCardSuccess), take(1)).subscribe(() => {
			this.bottomSheetRef.dismiss()
		})
	}
}
