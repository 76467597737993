import { CommonModule } from '@angular/common'
import { Component, signal } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { RouterLink } from '@angular/router'
import { FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	imports: [CommonModule, FooterButtonsComponent, MatButtonModule, RouterLink],
	templateUrl: './how-it-works.page.html',
	styleUrl: './how-it-works.page.scss',
})
export class HowItWorksPageComponent {
	steps = [
		{
			id: 1,
			title: 'Cadastre-se no Programa Menu',
			content:
				'com o seu cartão de crédito Bradesco e comece a aproveitar diferenciais especiais em seleção de restaurantes conceituados',
		},
		{
			id: 2,
			title: 'Navegue pelos restaurantes',
			content:
				'Acesse a página de restaurantes e veja os diversos tipos de culinárias presentes no programa',
		},
		{
			id: 3,
			title: 'Faça uma reserva ou check-in',
			content:
				'entrando na página de detalhes do restaurante, selecionando data, horário e ambiente desejados. Você também pode fazer a reserva através do concierge',
		},
		{
			id: 4,
			title: 'Usufrua dos seus benefícios no restaurante',
			content:
				'Aproveite seus benefícios no restaurante pagando a conta com seu cartão de crédito bradesco utilizado na reserva',
		},
	]

	public selectedStep = signal(1)
}
