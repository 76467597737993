<div>
	<mat-card class="checkin-card" *ngFor="let checkin of checkins">
		<div class="checkin-content">
			<div class="restaurant-image">
				<img
					src="{{ env.coverUrl }}{{ checkin.venue.partner.images.thumb[0].coverUrl }}"
					alt="Restaurant image"
				/>
			</div>

			<div class="checkin-details">
				<div class="header">
					<h2 class="restaurant-name">{{ checkin.venue.name }}</h2>
					<div class="status-badge">{{ getStatusName(checkin.status) }}</div>
				</div>

				<mat-divider></mat-divider>

				<div class="details-grid">
					<div class="details-column">
						<div class="detail-item">
							<p class="label">
								Data: <span>{{ checkin.reservationDay | date: 'dd/MM/yyy' }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								Horário: <span>{{ checkin.reservationTime }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								Mesa: <span>{{ checkin.partySize | formatPartySize }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								Benefícios: <span>{{ getFormattedBenefits(checkin) }}</span>
							</p>
						</div>
					</div>

					<div class="details-column">
						<div class="detail-item">
							<p class="label">
								Nome: <span>{{ checkin.client.name }} {{ checkin.client.lastName }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								CPF: <span>{{ checkin.client.cpf | mask: '000.000.000-00' }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								Telefone:
								<span>{{ checkin.client.phone | mask: '+00 (00) 00000-0000' }}</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-card>
	<!-- Not found card-->
	@if (checkins.length === 0) {
		<div class="not-found-card">
			<mat-icon svgIcon="pm-icons:close-circle"></mat-icon>
			<h2>Nenhum checkin encontrado.</h2>
			<p>Revise as informações fornecidas na busca.</p>
		</div>
	}
</div>
