import { CommonModule } from '@angular/common'
import { Component, inject, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { Client } from '../../control-panel.service'
import { MatButtonModule } from '@angular/material/button'
import { NgxMaskPipe } from 'ngx-mask'
import { Store } from '@ngrx/store'
import { ClientActions } from '@monorepo-channels/channels/domain'
import { Router } from '@angular/router'

@Component({
	standalone: true,
	selector: 'feature-pm-clients-tab-content',
	templateUrl: './tab-clients.component.html',
	styleUrls: ['./tab-clients.component.scss'],
	imports: [MatTableModule, CommonModule, MatIconModule, MatButtonModule, NgxMaskPipe],
})
export class TabClientsComponent {
	@Input() isLoadingLogin!: boolean
	@Input() dataSource!: MatTableDataSource<Client>
	@Input() displayedColumns!: string[]
	private store = inject(Store)
	private router = inject(Router)

	loginClient(data: Client): void {
		this.store.dispatch(
			ClientActions.loginClientByEmailAndPhone({ email: data.email, phone: data.phone })
		)
	}
}
