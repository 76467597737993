import { validateCPF } from '@monorepo-channels/shared/util-helpers'
import { CommonModule } from '@angular/common'
import { Component, inject, signal } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { MatCardModule } from '@angular/material/card'
import { Client, ControlPanelService, Order, Voucher } from './control-panel.service'
import { LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import { GenericErrorComponent } from '../../simple-bottom-sheets/generic-error.component'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { HttpErrorResponse } from '@angular/common/http'
import { TabClientsComponent } from './tabs/clients/tab-clients.component'
import { distinctUntilChanged, take } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NgxMaskDirective } from 'ngx-mask'
import { TabCheckinsComponent } from './tabs/checkins/tab-checkins.component'
import { TabBenefitsCelebrationsComponent } from './tabs/benefits-celebrations/tab-benefits-celebrations.component'
import { TabReservationsComponent } from './tabs/reservations/tab-reservations.component'

@Component({
	standalone: true,
	providers: [],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatTableModule,
		MatDividerModule,
		ReactiveFormsModule,
		LoadingPageComponent,
		MatCardModule,
		TabClientsComponent,
		TabReservationsComponent,
		TabCheckinsComponent,
		TabBenefitsCelebrationsComponent,
		NgxMaskDirective,
	],
	selector: 'feature-pm-control-panel-concierge',
	templateUrl: './control-panel.component.html',
	styleUrls: ['./control-panel.component.scss'],
})
export class ControlPanelComponent {
	public searchControl = new FormControl('', { nonNullable: true })
	private controlPanelService = inject(ControlPanelService)
	private bottomSheet = inject(MatBottomSheet)
	public displayedColumns: string[] = ['nome', 'cpf', 'telefone', 'email', 'cartao', 'acoes']
	public displayedCelebrationsColumns: string[] = [
		'nome',
		'cpf',
		'telefone',
		'email',
		'cartao',
		'cadastro',
		'acoes',
	]
	public dataSource: MatTableDataSource<Client> = new MatTableDataSource<Client>()
	public selectedTab = signal('Clientes')
	selectedTabValue: string = 'Clientes'
	public isLoadingLogin = false
	reservations: Order[] = []
	checkins: Order[] = []
	vouchers: Voucher[] = []

	constructor() {
		this.searchControl.valueChanges
			.pipe(distinctUntilChanged(), takeUntilDestroyed())
			.subscribe(value => {
				if (value.length === 11 && validateCPF.bind(value)) {
					this.isLoadingLogin = true
					this.fetchClientByCpf(value)
					this.fetchOrdersByCpf(value)
				}
				// // Caso seja necessário limpar os dados se input for vazio
				else if (value === '') {
					this.reservations = []
					this.checkins = []
					this.vouchers = []
					this.dataSource.data = []
					this.isLoadingLogin = false
				}
			})
	}

	private handleError(message: string, title: string): void {
		this.isLoadingLogin = false
		this.bottomSheet.open(GenericErrorComponent, {
			data: {
				error: message,
				title,
				btnMessage: 'Fechar',
			},
		})
	}

	// Busca cliente por CPF
	fetchClientByCpf(cpf: string): void {
		this.controlPanelService
			.getClientByCpf(cpf)
			.pipe(take(1))
			.subscribe({
				next: (data: Client[]) => {
					this.isLoadingLogin = false
					this.dataSource.data = data
					if (data && data.length > 0) {
						const clientId = data[0]._id
						this.fetchBenefitsHistory(clientId)
					}
					console.log(data)
				},
				error: (error: HttpErrorResponse) => {
					const errorMessage = error.error?.message || 'Erro desconhecido'
					this.handleError(errorMessage, 'Erro ao buscar Clientes')
					this.dataSource.data = []
				},
			})
	}

	// Buscar orders por CPF
	fetchOrdersByCpf(cpf: string): void {
		this.controlPanelService
			.getOrdersByCpf(cpf)
			.pipe(take(1))
			.subscribe({
				next: (data: Order[]) => {
					this.isLoadingLogin = false
					console.log(data)
					this.reservations = data.filter(order => order.intent === 'reservation')
					this.checkins = data.filter(order => order.intent === 'checkin')
				},
				error: (error: HttpErrorResponse) => {
					const errorMessage = error.error?.message || 'Erro desconhecido'
					this.handleError(errorMessage, 'Erro ao buscar Orders')
					this.reservations = []
					this.checkins = []
				},
			})
	}

	// Buscar Histórico de Vouchers
	fetchBenefitsHistory(clientId: string): void {
		this.controlPanelService
			.getHistoryBenefits(clientId)
			.pipe(take(1))
			.subscribe({
				next: (data: Voucher[]) => {
					this.isLoadingLogin = false
					this.vouchers = data
					console.log(data)
				},
				error: (error: HttpErrorResponse) => {
					const errorMessage = error.error?.message || 'Erro desconhecido'
					this.handleError(errorMessage, 'Erro ao buscar Orders')
					this.vouchers = []
				},
			})
	}

	selectTab(tab: string): void {
		this.selectedTab.set(tab)
	}
}
