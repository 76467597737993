<main class="container">
	<h1 class="title">Política de privacidade</h1>

	<h2 class="welcome-title">Bem-vindo ao Programa Menu!</h2>
	<ng-container *ngTemplateOutlet="content"></ng-container>

	<div style="height: 120px"></div>
	<ui-pm-footer-buttons type="fixed-order">
		<button mat-raised-button routerLink="/">Ver restaurantes</button>
	</ui-pm-footer-buttons>
</main>

<ng-template #content>
	<ol>
		<li>INFORMAÇÕES GERAIS SOBRE O PROGRAMA</li>
		<ol>
			<li>
				O PROGRAMA permite ao USUÁRIO ter acesso a sugestões de estabelecimentos gastronômicos, seus
				serviços, tais como Menu completo, sistema de reserva e espera, entre outros, nas principais
				capitais e cidades do Brasil.
			</li>
			<li>
				Para acessar o PROGRAMA de forma segura é de inteira responsabilidade do USUÁRIO dispor de
				recursos compatíveis, serviço de conexão à Internet com antivírus e firewall habilitados,
				softwares devidamente atualizados – tais como navegadores e gerenciadores de download e upload
				–, além da adoção de medidas de segurança mínimas, tais como uso de senha segura.
			</li>
			<li>
				O PROGRAMA e suas funcionalidades são apresentadas aos USUÁRIOS na maneira como estão
				disponíveis, podendo passar por constantes aprimoramentos e atualizações, obrigando-se a TAGME
				a:
			</li>
			<ol>
				<li>
					Preservar a funcionalidade do PROGRAMA, evitando links quebrados e na utilização de layout
					adequado a usabilidade e navegabilidade, sempre que possível. Envidaremos nossos melhores
					esforços no sentido de manter as informações e materiais contidos neste PROGRAMA tão
					precisos, atualizados e completos quanto possível.
				</li>
				<li>
					Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que
					exista a exata percepção das operações realizadas.
				</li>
				<li>
					Garantir, por meio do estado da técnica disponível, o sigilo dos dados pessoais quando
					inseridos nas funcionalidades oferecidas em seu PROGRAMA, somente sendo acessíveis pela
					TAGME e por quem o USUÁRIO consentir, além de si próprio.
				</li>
				<li>Remover os conteúdos que forem publicados contendo:</li>
				<ul>
					<li>Ofensa à honra, imagem, reputação e dignidade de terceiros;</li>
					<li>Pornografia, pedofilia, e outras modalidades de satisfação sexual;</li>
					<li>Racismo ou discriminação de qualquer natureza;</li>
					<li>
						Bullying, stalking ou qualquer outra espécie de constrangimento ilegal ou assédio;
					</li>
					<li>Manifesta violação a direito autoral ou direito de imagem e personalidade;</li>
					<li>Utilização de marcas, símbolos, logotipos ou emblemas de terceiros;</li>
					<li>
						Instigação ou apologia à prática de crimes, como tráfico ou uso de entorpecentes,
						estupro, homicídio, estelionato, dentre outros;
					</li>
					<li>Manifesta prática de ato contrário à lei, à ordem pública ou aos bons costumes;</li>
					<li>
						Uso da violência, disseminação de ódio ou qualquer outra forma de agressão ao
						bem-estar físico e/ou psicológico de alguém;
					</li>
					<li>
						Atividade comercial similar a TAGME ou que o utilize para angariar recursos por
						publicidade de terceiros ao invés de praticar a interação regular do ambiente; e Erros
						ou suspeita de equívocos.
					</li>
				</ul>
			</ol>
			<li>
				A TAGME envida seus esforços para a disponibilidade contínua e permanente do PROGRAMA. No
				entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de
				manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais,
				falhas ou colapsos nos sistemas centrais de comunicação e acesso à Internet ou fatos de
				terceiro que fogem de sua esfera de vigilância e responsabilidade.
				<ol>
					<li>
						Se isso ocorrer, a TAGME fará o que estiver ao seu alcance para restabelecer o acesso
						ao PROGRAMA o mais breve possível, dentro das limitações técnicas de seus serviços e
						dos serviços de terceiros, dos quais o PROGRAMA depende para ficar disponível.
					</li>
				</ol>
			</li>
			<li>
				Eventuais procedimentos de manutenção serão informados por meio dos canais oficiais de
				comunicação da TAGME (a exemplo, mas não se limitando a, e-mails, perfis oficiais em mídias
				sociais ou telefone de atendimento), caso seja necessário que esta fique indisponível por
				longos períodos.
			</li>
		</ol>
		<li>
			FUNCIONALIDADES DO PROGRAMA
			<ol>
				<li>
					O PROGRAMA permite ao USUÁRIO ter acesso a sugestões de estabelecimentos gastronômicos,
					seus serviços, tais como Menu completo, sistema de reserva e espera, entre outros, nas
					principais capitais e cidades do Brasil.
				</li>
				<li>
					Os serviços do PROGRAMA são destinados a pessoas físicas maiores de 18 (dezoito) anos.
					Caso o USUÁRIO não esteja de acordo com o exigido, deverá solicitar a assistência de seus
					responsáveis legais.
				</li>
				<li>
					Para compartilhar as dicas disponibilizadas no PROGRAMA, o USUÁRIO deverá realizar o seu
					login por meio de seu número de telefone celular e e-mail, entretanto para usufruir de
					todos os benefícios oferecidos pelo PROGRAMA o usuário deverá cadastrar seu CPF e os seis
					(6) primeiros dígitos do cartão de crédito.
					<ol>
						<li>
							Se a qualquer momento for constatado que o USUÁRIO forneceu informações falsas ou
							não condizentes com a realidade, a TAGME se reserva ao direito de suspender o seu
							uso, sem prejuízo de adotar as medidas que entender cabíveis.
						</li>
					</ol>
				</li>
			</ol>
		</li>
		<li>
			DAS RESPONSABILIDADES E OBRIGAÇÕES DO USUÁRIO
			<ol>
				<li>
					O USUÁRIO se obriga a utilizar com retidão e de forma ética o APLICATIVO, obedecendo aos
					propósitos aqui estabelecidos, sempre respeitando as condições que regem a utilização do
					PROGRAMA e sua finalidade.
				</li>
				<li>
					O USUÁRIO reconhece e afirma que todos os acessos realizados após sua autenticação
					bem-sucedida são interpretados como tendo sido feitos por ele próprio de forma
					incontestável. Por isso, será responsável por todos os acessos e operações no PROGRAMA
					praticados e após sua identificação bem-sucedida, inclusive aqueles derivados de uso
					indevido ou divulgação desta para terceiros.
				</li>
				<li>
					É de responsabilidade do USUÁRIO deixar seus sistemas de AntiSpam, filtros similares ou
					configurações de redirecionamento de mensagens ajustados de modo que não interfiram no
					recebimento dos comunicados e materiais da TAGME, não sendo aceitável nenhuma escusa caso
					não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos
					mencionados.
				</li>
				<li>
					Ao acessar o PROGRAMA, o USUÁRIO declara que irá respeitar todos os direitos de
					propriedade intelectual de titularidade da TAGME, incluindo, mas não se limitando a
					marcas, software, direitos autorais, patentes, tal qual todos os direitos referentes a
					terceiros que porventura estejam, ou estiveram, de alguma forma disponíveis no PROGRAMA.
				</li>
				<li>
					As funcionalidades que compõem o PROGRAMA são oferecidas na forma de prestação de serviço,
					não conferindo ao USUÁRIO nenhum direito sobre o software utilizado pelo TAGME ou sobre
					suas estruturas de informática que sustentam o PROGRAMA.
					<ul>
						<li>
							O USUÁRIO está ciente de que poderá ter seu acesso ao PROGRAMA cancelado sem aviso
							prévio caso o utilize para realizar qualquer atividade que viole direitos do TAGME
							ou de terceiros.
						</li>
					</ul>
				</li>
				<li>
					Não é permitido o acesso às áreas de programação PROGRAMA, seu banco de dados ou qualquer
					outro conjunto de informações que faça parte da atividade de Webmastering.
				</li>
				<li>
					Também não é autorizado ao USUÁRIO realizar ou permitir engenharia reversa, nem traduzir,
					decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar,
					transmitir, emprestar, distribuir ou, de outra maneira, dispor das ferramentas do PROGRAMA
					e de suas funcionalidades.
				</li>
				<li>
					No PROGRAMA é proibida a utilização de softwares spider, ou de mineração de dados, de
					qualquer tipo ou espécie, além de outro aqui não tipificado, mas que atue de modo
					automatizado, tanto para realizar operações massificadas ou para quaisquer outras
					finalidades.
				</li>
				<li>
					O descumprimento de quaisquer das obrigações aqui estipuladas poderá acarretar na
					suspensão das funcionalidades e do acesso do USUÁRIO, conforme previsto neste documento.
				</li>
				<li>
					A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade do
					PROGRAMA em decorrência de alguma reclamação, deverá ser sempre compreendida como
					demonstração de boa-fé e intenção de solução amigável de conflitos, jamais como
					reconhecimento de culpa ou de qualquer infração pela TAGME a direito de terceiro.
				</li>
				<li>
					Na incidência de danos à TAGME ou a terceiros, o responsável se compromete a arcar com
					todas as obrigações de indenizar o sujeito lesado.
				</li>
			</ol>
		</li>
		<li>
			DA ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE DA TAGME
			<ol>
				<li>
					A TAGME não se responsabiliza por quaisquer usos indevidos dos conteúdos disponibilizados
					no PROGRAMA. É obrigação exclusiva do USUÁRIO verificar a quem pertence a titularidade dos
					conteúdos e solicitar a licença ou autorização necessária. As licenças e autorizações
					obtidas pela TAGME não permitem o uso dos conteúdos por terceiros.
				</li>
				<li>
					A TAGME não se responsabiliza por qualquer dano direto ou indireto ocasionado por eventos
					de terceiros, como ataque de hackers, falhas no sistema, no servidor ou na conexão à
					internet, inclusive por ações de softwares maliciosos como vírus, cavalos de Tróia, e
					outros que possam, de algum modo, danificar recursos ou a conexão do USUÁRIO em
					decorrência do acesso, utilização ou navegação no PROGRAMA bem como a transferência de
					dados, arquivos, imagens, textos, áudios ou vídeos contidos neste.
				</li>
				<li>
					O USUÁRIO não possui qualquer direito para exigir a disponibilidade do PROGRAMA conforme
					melhor lhes convêm, tampouco poderá pleitear indenização ou reparação de danos no caso do
					PROGRAMA permanecer fora do ar, independente da motivação.
				</li>
				<li>
					A TAGME não detém qualquer responsabilidade pela navegação do USUÁRIO nos links externos
					publicados no PROGRAMA, sendo dever a leitura de eventuais Termos de Uso e Política de
					Privacidade dos links acessados e agir conforme o determinado.
					<ol>
						<li>
							A TAGME não verifica, controla, aprova ou garante a adequação ou exatidão das
							informações ou dados disponibilizados em tais links, não sendo, portanto,
							responsável por prejuízos, perdas ou danos ocorridos pela visita a tais sites,
							cabendo ao interessado verificar a confiabilidade das informações e dados ali
							exibidos antes de tomar alguma decisão ou praticar algum ato.
						</li>
					</ol>
				</li>
			</ol>
		</li>
		<li>
			PRIVACIDADE DO USUÁRIO
			<ol>
				<li>
					Os dados pessoais são obtidos quando o USUÁRIO os inserir voluntariamente no PROGRAMA ou
					entrar em contato por meio dos canais de contato disponíveis.
				</li>
				<li>
					O PROGRAMA pode realizar a coleta de dados do USUÁRIO para funcionar adequadamente e
					oferecer a melhor experiência possível da comodidade proposta, a saber:
					<ol>
						<li>
							Dados de uso e acesso ao PROGRAMA, quais sejam:
							<ul>
								<li>Conexões existentes com outros USUÁRIOS, conforme a espécie;</li>
								<li>Quantidade de interações com outros USUÁRIOS, conforme a espécie;</li>
								<li>Quantidade de interações com o PROGRAMA, conforme a espécie;</li>
								<li>
									Lugares marcados como visitados ou nos quais o USUÁRIO se encontra durante
									determinada interação com o PROGRAMA ou com demais USUÁRIOS; e,
								</li>
								<li>
									Frequência de uso do PROGRAMA e duração de interações realizadas, quando
									possível de serem medidas.
								</li>
							</ul>
						</li>
					</ol>
				</li>
				<li>
					Os dados coletados estarão armazenados em ambiente seguro, observado o estado da técnica
					disponível, e somente serão ser acessados por pessoas qualificadas e autorizadas pela
					TAGME.
				</li>
				<li>
					Considerando que nenhum sistema de segurança é absolutamente seguro, a TAGME se exime de
					quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas,
					vírus ou invasões do banco de dados utilizado pelo PROGRAMA, salvo nos casos em que tiver
					agido com dolo ou culpa.
				</li>
				<li>
					Os dados do USUÁRIO descritos em 5.1., além dos conteúdos de caráter não privado poderão
					ser utilizados para as seguintes finalidades:
					<ul>
						<li>Responder a eventuais dúvidas e solicitações do USUÁRIO;</li>
						<li>Cumprimento de ordem legal ou judicial;</li>
						<li>
							Constituir, defender ou exercer regularmente direitos em âmbito judicial ou
							administrativo;
						</li>
						<li>Garantir a segurança do USUÁRIO;</li>
						<li>
							Manter atualizados os dados de cadastro do USUÁRIO e para fins de contato por
							telefone fixo, celular, correio eletrônico, SMS, mala direta, redes sociais ou por
							outros meios de comunicação;
						</li>
						<li>
							Realizar o enriquecimento da base de dados dos USUÁRIOS com a finalidade de
							customização dos serviços, melhor atendimento, promoção do PROGRAMA e
							desenvolvimento de novos produtos da TAGME;
						</li>
						<li>
							Promover o PROGRAMA e/ou de futuros softwares desenvolvidos ou comercializados
							pela TAGME aos contatos do USUÁRIO por meio de envio de mensagens instantâneas ou
							mensagens por meio do PROGRAMA;
						</li>
						<li>
							Promover o serviço e seus parceiros, comerciais ou não, e informar sobre novas
							oportunidades e benefícios para o USUÁRIO;
						</li>
						<li>
							Gerar análises e estudos, sejam estatísticos ou identificáveis, com base no
							comportamento de uso das funcionalidades do PROGRAMA;
						</li>
						<li>
							Aperfeiçoar o uso e a experiência interativa durante a navegação do USUÁRIO no
							PROGRAMA; e,
						</li>
						<li>
							Aprimorar o funcionamento do PROGRAMA bem como dos demais serviços da TAGME que se
							relacionam, direta ou indiretamente, com o PROGRAMA, seu ambiente ou propostas de
							uso.
						</li>
					</ul>
				</li>
				<li>
					A base de dados formada pela TAGME, por meio da coleta de dados do pelo PROGRAMA não será
					compartilhada, vendida, cedida, transferida, informada ou alugada a terceiros, podendo ser
					compartilhada apenas com o grupo econômico da TAGME ou parceiros de negócios.
				</li>
				<li>
					Os dados adquiridos somente poderão ser acessados por profissionais devidamente
					autorizados pela TAGME, respeitando a necessidade a que serão submetidos, a relevância
					para os objetivos do PROGRAMA e os interesses do USUÁRIO, além de preservar sua
					privacidade, nos termos da lei e deste documento.
				</li>
				<li>
					Caso o USUÁRIO deixe de utilizar os serviços disponibilizados pelo PROGRAMA, a TAGME
					poderá, para fins de auditoria e preservação de direitos, permanecer com o registro de
					dados do USUÁRIO pelo período máximo de 5 (cinco) anos, a não ser que seja exigido
					mantê-los por período superior por motivo legal, requisição administrativa de autoridade
					ou ordem judicial, possuindo a faculdade de excluí-los definitivamente segundo sua
					conveniência, nos termos da lei.
				</li>
				<li>
					O USUÁRIO poderá exigir da TAGME os dados registrados que lhe dizem respeito, da mesma
					forma que poderá solicitar sua alteração ou exclusão definitiva quando encerrar o uso do
					PROGRAMA. Para tanto, deverá entrar em contato através dos meios disponibilizados no
					PROGRAMA para solicitar essas providências caso não seja oferecida a funcionalidade
					correspondente na interface.
					<ol>
						<li>
							O pedido de exclusão de dados feito pelo USUÁRIO será totalmente atendido após
							vencer o prazo indicado em 5.8, contudo, ainda que armazenados para fins de
							auditoria e judiciais, não mais passarão pelo tratamento discriminado em 5.5, f.,
							g., h., i., j. e k.
						</li>
					</ol>
				</li>
				<li>
					A TAGME efetuará registro de todas as atividades efetuadas pelo USUÁRIO no PROGRAMA,
					também por meio de logs, incluindo:
					<ul>
						<li>Endereço IP do USUÁRIO;</li>
						<li>Ações efetuadas pelo USUÁRIO no PROGRAMA;</li>
						<li>Mensagens, avisos e informações acessadas pelo USUÁRIO no uso do PROGRAMA;</li>
						<li>Ferramentas e funcionalidades acessadas pelo USUÁRIO;</li>
						<li>Data e horário de cada ação no PROGRAMA;</li>
						<li>
							Informações sobre os recursos utilizados pelo USUÁRIO, localização, sistema
							operacional e outros disponíveis;
						</li>
						<li>Session ID, quando disponível; e,</li>
						<li>
							Outras informações necessárias para a sua adequada autenticação, quando
							necessário.
						</li>
					</ul>
				</li>
				<li>
					Os registros mencionados no item 5.1 poderão ser utilizados pela TAGME como meio
					probatório em casos de:
					<ul>
						<li>Atos ilícitos ou contrários a este documento;</li>
						<li>Identificação de alterações indevidas em seus sistemas e cadastros;</li>
						<li>
							Demais ações que possam colocar em risco a segurança e continuidade da plataforma,
							do PROGRAMA, de seus USUÁRIOS ou dispositivos nos quais o PROGRAMA está instalado.
						</li>
					</ul>
				</li>
				<li>
					Outras tecnologias poderão ser utilizadas para a obtenção de dados de utilização do
					PROGRAMA pelo USUÁRIO, respeitando sempre os termos deste documento e as opções do USUÁRIO
					a respeito de sua coleta e armazenamento.
				</li>
			</ol>
		</li>
		<li>
			DISPOSIÇÕES GERAIS
			<ol>
				<li>
					Os presentes Termos e Condições de Uso estão sujeitos a constante melhoria e
					aprimoramento. Assim, a TAGME se reserva ao direito de modificá-los a qualquer momento,
					conforme a finalidade do PROGRAMA ou conveniência de sua controladora, tal qual para
					adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica
					equivalente, cabendo ao USUÁRIO verificá-lo sempre que efetuar o acesso ao PROGRAMA.
				</li>
				<li>
					A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente
					instrumento não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou
					inibirá a exigibilidade das mesmas a qualquer tempo.
				</li>
				<li>
					Caso alguma disposição destes Termos de Uso seja julgada inaplicável ou sem efeito, o
					restante do documento continua a viger, sem a necessidade de medida judicial que declare
					tal assertiva.
				</li>
				<li>O PROGRAMA tem como base a data e horários oficiais de Brasília.</li>
			</ol>
		</li>
		<li>
			LEI APLICÁVEL E JURISDIÇÃO
			<ol>
				<li>
					Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação
					brasileira, no idioma português, sendo eleito o Foro da Capital do Estado de Rio de
					Janeiro para dirimir qualquer litígio ou controvérsia envolvendo o presente documento,
					salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação
					aplicável.
				</li>
			</ol>
		</li>
		<li>
			8. GLOSSÁRIO
			<ol>
				<li>
					8.1. Para os fins deste documento, devem se considerar as seguintes definições e
					descrições para seu melhor entendimento:
					<ul>
						<li>
							Anti-Spam: Sistema que evita correspondências não desejadas, como publicidade em
							massa, pelo bloqueio de mensagens ou as movendo para pasta específica.
						</li>
						<li>PROGRAMA: Designa o PROGRAMA MENU.</li>
						<li>
							TAGME: TAGME – ALVES CUNHA TORRES INFORMATICA LTDA., pessoa jurídica de direito
							privado com sede na cidade do Rio de Janeiro, Estado do Rio de Janeiro, à Rua
							Dalcídio Jurandir, nº 255, Barra da Tijuca, inscrita no CNPJ/MF sob o nº
							10.842.304/0001-50.
						</li>
						<li>
							IP: Abreviatura de Internet Protocolo. É conjunto alfanumérico que identifica os
							dispositivos dos USUÁRIOS na Internet.
						</li>
						<li>Layout: Conjunto compreendido entre aparência, design e fluxos do PROGRAMA.</li>
						<li>Link: Terminologia para endereço de Internet.</li>
						<li>USUÁRIOS: Pessoas que acessam ou interagem com as atividades oferecidas.</li>
					</ul>
				</li>
			</ol>
		</li>
	</ol>
</ng-template>
