<main class="container">
	<form
		[formGroup]="forgotPasswordForm"
		(ngSubmit)="confirmCode()"
		*ngIf="step() === 'forgot-password' || step() === 'confirm-code'"
	>
		<ng-container *ngIf="step() === 'forgot-password'">
			<section class="title-container" style="margin-top: 182px" style="width: 240px">
				<h1 class="title">Esqueceu a senha?</h1>
				<p class="text">Informe o CPF vinculado a conta</p>
			</section>
			<div class="form-field">
				<input
					type="text"
					id="cpf"
					inputmode="numeric"
					class="form-field__input"
					formControlName="cpf"
					mask="000.000.000-00"
					(focus)="isCpfFocused.set(true)"
					(blur)="isCpfFocused.set(false)"
					[ngClass]="{ focused: isCpfFocused(), filled: forgotPasswordForm.controls.cpf.value }"
				/>
				<label for="cpf" class="form-field__label">CPF</label>
				<span class="form-field__underline"></span>
			</div>

			<section class="container-clients" *ngIf="clients().length > 1 && !selectedClient()">
				<p style="margin-top: 32px; margin-bottom: 28px">
					Identificamos mais de uma conta no CPF, <span>escolha com qual deseja seguir:</span>
				</p>
				<ul
					class="clients"
					*ngFor="let client of clients()"
					(click)="selectClientAndSendCode(client)"
				>
					<li class="clients__item">
						<mat-icon svgIcon="pm-icons:email" />
						<p>{{ client.email }}</p>
					</li>
					<li class="clients__item">
						<mat-icon svgIcon="pm-icons:phone" />
						<p>{{ client.phone }}</p>
					</li>
				</ul>
			</section>
		</ng-container>
		<ng-container *ngIf="step() === 'confirm-code'">
			<section class="title-container">
				<h1 class="title">Redefinir senha</h1>
				<p class="text">
					Informe o código enviado para o e-mail
					<span style="font-weight: 480">{{ selectedClient()?.email }}</span>
				</p>
			</section>
			<section class="otp-input-container">
				<input
					type="text"
					maxlength="1"
					class="otp-input"
					(keyup)="onKeyUp($event)"
					(keydown)="onKeyDown($event, i)"
					(paste)="onPaste($event)"
					(input)="onInput($event)"
					*ngFor="let item of [0, 1, 2, 3, 4, 5]; let i = index"
					[formControlName]="'otp' + i"
				/>
			</section>
			@if (isLoadingResend()) {
				<p class="resend">Reenviando...</p>
			} @else {
				<ng-container *ngIf="countdown$ | async as value; else resend">
					<p class="resend">Reenviar o código em {{ value }} segundos.</p>
				</ng-container>
				<ng-template #resend>
					<p class="resend">
						Não recebeu o código? <span class="resend-txt" (click)="resendCode()">Reenviar</span>
					</p>
				</ng-template>
			}
		</ng-container>

		@if (isLoading()) {
			<ui-pm-loading-page style="--height: 100px" />
		} @else {
			<button
				mat-raised-button
				style="margin-top: 32px"
				[disabled]="!forgotPasswordForm.valid"
				[color]="!forgotPasswordForm.valid ? '' : 'primary'"
			>
				Continuar
			</button>
			<div class="login-container" routerLink="/entrar">
				<mat-icon svgIcon="pm-icons:left-arrow-circle" style="width: 13px; height: 13px"></mat-icon>
				<p>Voltar para Login</p>
			</div>
		}
	</form>

	<ng-container *ngIf="step() === 'update-password'">
		<section class="title-container" style="margin-top: 182px" style="width: 240px">
			<h1 class="title">Redefinir senha</h1>
		</section>
		<form [formGroup]="passwordGroupForm" (ngSubmit)="updatePassword()">
			<div class="field" style="margin-top: 24px; position: relative">
				<div
					class="form-field"
					style="margin-top: 24px; position: relative"
					[ngClass]="{
						error:
							passwordGroupForm.controls.password.invalid &&
							passwordGroupForm.controls.password.touched
					}"
				>
					<input
						[type]="showPassword.password ? 'text' : 'password'"
						id="password"
						class="form-field__input"
						formControlName="password"
						(focus)="isPasswordFocused.set(true)"
						(blur)="isPasswordFocused.set(false)"
						[ngClass]="{
							focused: isPasswordFocused(),
							filled: passwordGroupForm.controls.password.value
						}"
					/>
					<label for="password" class="form-field__label">Senha</label>
					<span class="form-field__underline"></span>
					<mat-icon
						[svgIcon]="showPassword.password ? 'pm-icons:eye-on' : 'pm-icons:eye-off'"
						style="
							position: absolute;
							right: 8px;
							top: 50%;
							transform: translateY(-70%);
							cursor: pointer;
							width: 20px;
							height: 20px;
						"
						(click)="showPassword.password = !showPassword.password"
					></mat-icon>
				</div>
			</div>
			<div class="field" style="margin-top: 24px; position: relative">
				<div
					class="form-field"
					style="margin-top: 24px; position: relative"
					[ngClass]="{
						error:
							passwordGroupForm.controls.confirmPassword.getError('passwordMismatch') &&
							passwordGroupForm.controls.confirmPassword.touched
					}"
				>
					<input
						[type]="showPassword.confirmPassword ? 'text' : 'password'"
						id="confirmPassword"
						class="form-field__input"
						formControlName="confirmPassword"
						(focus)="isConfirmPasswordFocused.set(true)"
						(blur)="isConfirmPasswordFocused.set(false)"
						[ngClass]="{
							focused: isConfirmPasswordFocused(),
							filled: passwordGroupForm.controls.confirmPassword.value
						}"
					/>
					<label for="confirmPassword" class="form-field__label">Confirmar senha</label>
					<span class="form-field__underline"></span>
					<span class="form-field__error">As senhas não coincidem.</span>
					<mat-icon
						[svgIcon]="showPassword.confirmPassword ? 'pm-icons:eye-on' : 'pm-icons:eye-off'"
						style="
							position: absolute;
							right: 8px;
							top: 50%;
							transform: translateY(-70%);
							cursor: pointer;
							width: 20px;
							height: 20px;
						"
						(click)="showPassword.confirmPassword = !showPassword.confirmPassword"
					></mat-icon>
				</div>
			</div>

			<ng-container *ngIf="passwordGroupForm.controls as group">
				<ul class="field__feedback">
					<p style="font-size: 14px; font-weight: 480; margin-bottom: 12px; margin-top: 40px">
						Sua senha deve conter
					</p>
					<li
						[ngClass]="{
							underline: !group.password.hasError('passwordLength')
						}"
					>
						Mín. 8 caracteres e Máx 20 caracteres.
					</li>
					<li [ngClass]="{ underline: !group.password.hasError('passwordUppercase') }">
						Min. 1 dígito em letra maiúscula.
					</li>
					<li [ngClass]="{ underline: !group.password.hasError('passwordLowercase') }">
						Mín. 1 dígito em letra minúscula.
					</li>
					<li [ngClass]="{ underline: !group.password.hasError('passwordSpecial') }">
						Mín. 1 caractere especial (!, &commat; #, $, &, *)
					</li>
				</ul>
			</ng-container>

			@if (isLoading()) {
				<ui-pm-loading-page style="--height: 100px" />
			} @else {
				<button
					mat-raised-button
					style="margin-top: 32px"
					[disabled]="!passwordGroupForm.valid"
					[color]="!passwordGroupForm.valid ? '' : 'primary'"
				>
					Enviar
				</button>
			}
		</form>
	</ng-container>
	<ng-container *ngIf="step() === 'password-finished'">
		<mat-icon
			svgIcon="pm-icons:check-circle-red"
			style="width: 80px; height: 80px; margin: 80px auto 24px; display: block"
		></mat-icon>
		<h1 class="title">Senha redefinda!</h1>
		<p class="text" style="width: 255px; margin: 0 auto; margin-top: 24px; text-align: center">
			Você já pode fazer login e aproveitar os benefícios do seu cartão bradesco nos melhores
			restaurantes
		</p>
		<button mat-raised-button routerLink="/entrar" style="margin-top: 52px">Fazer login</button>
	</ng-container>
</main>
