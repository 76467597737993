import { inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@monorepo-channels/shared/util-environments'
import { ConciergeAuthService } from '@monorepo-channels/channels/domain'

export interface Client {
	_id: string
	cpf: string
	password: string
	email: string
	phone: string
	name: string
	lastName: string
	categories: Categories[]
}

interface Categories {
	categoryId: string
	digits: number
	gatewayId: string
	benefits: string[]
	brand: string
}
interface Venue {
	name: string
	partner: { images: { thumb: { coverUrl: string }[] } }
}
export interface Order {
	reservationDay: string
	reservationTime: string
	partySize: number
	intent: string
	benefits: { name: string }[]
	client: Client
	venue: Venue
	status: string
}

export interface Voucher {
	_id: string
	clientId: string
	validFrom: string
	validUntil: string
	description: string
	reason: string
	status: string
	value: string
	enabled: boolean
	conciergeUser: string
	isDeleted: boolean
	createdAt: string
}

@Injectable({
	providedIn: 'root',
})
export class ControlPanelService {
	private adminAuthService = inject(ConciergeAuthService)
	private token = this.adminAuthService.getToken()
	private baseUrl = `${environment.channelsAPIURL}/api/v1`
	private http = inject(HttpClient)

	constructor() {}

	getClientByCpf(cpf: string): Observable<Client[]> {
		return this.http.get<Client[]>(`${this.baseUrl}/clients/cpf/${cpf}`)
	}
	getOrdersByCpf(cpf: string): Observable<Order[]> {
		return this.http.get<Order[]>(`${this.baseUrl}/orders/admin/history/?cpf=${cpf}`)
	}
	getHistoryBenefits(clientId: string): Observable<Voucher[]> {
		return this.http.post<Voucher[]>(`${this.baseUrl}/vouchers/history`, { clientId })
	}
}
