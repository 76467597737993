<main class="container">
	<h1 class="page-title">Cadastro de cartão</h1>
	<p class="page-description">
		Cadastre seu cartão para realizar reservas ou check-ins e usufruir de seus benefícios exclusivos!
	</p>

	@if (status$ | async; as status) {
		<div class="bar-container">
			<mat-icon
				[svgIcon]="
					status === 'idle' || status === 'failure' || status === 'pending'
						? 'pm-icons:bar-card-primary'
						: 'pm-icons:bar-card'
				"
				style="width: 100%; height: 4px"
			/>
			<mat-icon
				[svgIcon]="status === 'success' ? 'pm-icons:bar-card-red' : 'pm-icons:bar-card'"
				style="width: 100%; height: 4px"
			/>
		</div>

		<section *ngIf="status === 'pending'">
			<ui-pm-loading-page></ui-pm-loading-page>
		</section>

		<section *ngIf="status === 'idle' || status === 'failure'">
			<div class="card">
				<p class="card__text">Crédito</p>
				<p class="card__number" *ngIf="creditCardForm.controls.number.value as number">
					<span *ngIf="number">
						<ng-container *ngIf="number.length === 16">
							{{ number | mask: '0000 0000 0000 0000' }}
						</ng-container>
						<ng-container *ngIf="number.length < 16">
							{{ number | mask: '0000 000000 00000' }}
						</ng-container>
						<span *ngIf="!number">0000 0000 0000 0000</span>
					</span>
				</p>
				<div style="display: flex; justify-content: space-between">
					<p class="card__text">
						{{ creditCardForm.controls.name.value || 'Nome impresso no cartão' }}
					</p>
					<p class="card__text">
						{{ (creditCardForm.controls.validity.value | mask: '00/00') || 'MM/AA' }}
					</p>
				</div>
			</div>

			<form [formGroup]="creditCardForm" class="payment-form">
				<ng-container *ngIf="creditCardForm.controls.number as cardNumber">
					<label for="cardNumber" class="form-label">Número do cartão</label>
					<input
						id="cardNumber"
						type="text"
						formControlName="number"
						inputmode="numeric"
						placeholder="Digite o número do cartão"
						class="form-input"
						mask="0000 0000 0000 0000||0000 000000 00000"
						[ngClass]="{ 'form-input--invalid': cardNumber.invalid && cardNumber.touched }"
					/>
					<div *ngIf="cardNumber?.invalid && cardNumber?.touched" class="error-message">
						<p *ngIf="cardNumber?.errors?.['required']">Número do cartão é obrigatório.</p>
						<p *ngIf="cardNumber?.errors?.['minlength'] || cardNumber?.errors?.['maxlength']">
							O número do cartão deve ter entre 15 a 16 dígitos.
						</p>
					</div>
				</ng-container>
				<ng-container *ngIf="creditCardForm.controls.name as name">
					<label for="name" class="form-label">Nome no cartão</label>
					<input
						id="name"
						type="text"
						formControlName="name"
						placeholder="Digite o nome do cartão"
						class="form-input"
						pattern="[^()/><\][\x22,;|.]+"
						[ngClass]="{ 'form-input--invalid': name.invalid && name.touched }"
					/>
					<div *ngIf="name?.invalid && name?.touched" class="error-message">
						<p *ngIf="name?.errors?.['required']">Nome do cartão é obrigatório.</p>
					</div>
				</ng-container>
				<div style="display: flex; gap: 14px">
					<div
						*ngIf="creditCardForm.controls.validity as validity"
						style="display: flex; flex-direction: column"
					>
						<label for="validity" class="form-label">Validade</label>
						<input
							id="validity"
							type="text"
							formControlName="validity"
							placeholder="MM/AA"
							[dropSpecialCharacters]="false"
							inputmode="numeric"
							mask="00/00"
							class="form-input"
							[ngClass]="{ 'form-input--invalid': validity.invalid && validity.touched }"
							style="max-width: 112px"
						/>
						<div *ngIf="validity?.invalid && validity?.touched" class="error-message">
							<p *ngIf="validity?.errors?.['required']">Data inválida</p>
						</div>
					</div>
					<div
						*ngIf="creditCardForm.controls.cvv as cvv"
						style="display: flex; flex-direction: column"
					>
						<label for="cvv" class="form-label">CVV</label>
						<div style="position: relative">
							<input
								id="cvv"
								[type]="showCvv ? 'text' : 'password'"
								formControlName="cvv"
								placeholder="000"
								inputmode="numeric"
								mask="0000||000"
								class="form-input"
								[ngClass]="{ 'form-input--invalid': cvv.invalid && cvv.touched }"
								style="max-width: 112px"
							/>
							<mat-icon
								[svgIcon]="showCvv ? 'pm-icons:eye-on' : 'pm-icons:eye-off'"
								style="
									position: absolute;
									right: 8px;
									top: 50%;
									transform: translateY(-50%);
									cursor: pointer;
								"
								(click)="toggleCvvVisibility()"
							></mat-icon>
						</div>
						<div *ngIf="cvv?.invalid && cvv?.touched" class="error-message">
							<p *ngIf="cvv?.errors?.['required']">Insira CVV válido</p>
						</div>
					</div>
				</div>
				<section
					formGroupName="billingAddress"
					*ngIf="creditCardForm.controls.billingAddress.controls.zipCode as zipCode"
					style="display: flex; flex-direction: column"
				>
					<label for="zipCode" class="form-label">CEP</label>
					<input
						id="zipCode"
						type="text"
						formControlName="zipCode"
						placeholder="00000-000"
						inputmode="numeric"
						mask="00000-000"
						class="form-input"
						[ngClass]="{ 'form-input--invalid': zipCode.invalid && zipCode.touched }"
						style="max-width: 112px"
					/>
					<div *ngIf="zipCode?.invalid && zipCode?.touched" class="error-message">
						<p *ngIf="zipCode?.errors?.['required']">Data inválida</p>
					</div>
				</section>
			</form>

			<ul class="card-box-shadow" style="gap: 8px; margin-top: 18px" *ngIf="neighborhood()">
				<li class="card-box-shadow__item" style="font-weight: 480">
					<mat-icon svgIcon="pm-icons:map" style="width: 23px; height: 23px"> </mat-icon>
					{{ creditCardForm.controls.billingAddress.controls.city.value }}
				</li>
				<li class="card-box-shadow__item" style="margin-left: 35px">
					{{ creditCardForm.controls.billingAddress.controls.address.value }} - {{ neighborhood() }}
				</li>
			</ul>

			<div style="height: 200px"></div>
			<ui-pm-footer-buttons type="fixed-order">
				<button
					mat-raised-button
					[color]="!creditCardForm.invalid ? 'primary' : ''"
					[disabled]="creditCardForm.invalid"
					(click)="handleSubmit()"
				>
					Continuar
				</button>
			</ui-pm-footer-buttons>
		</section>

		<section *ngIf="status === 'success'">
			<mat-icon
				svgIcon="pm-icons:check-circle"
				style="width: 80px; height: 80px; margin: 0 auto; display: block"
			></mat-icon>
			<p class="registered-card">Cartão cadastrado!</p>
			<p class="registered-card__text">
				Você já pode visualizar seu cartão na carteira e fazer reservas nos melhores restaurantes
			</p>
			<button mat-raised-button style="margin-bottom: 16px" routerLink="/carteira">Ver carteira</button>
			<button mat-raised-button routerLink="/">Ver restaurantes</button>
		</section>
	}
</main>
