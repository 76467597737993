export function newRestaurant() {
	const twoMonthsAgo = new Date()
	twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2)
	twoMonthsAgo.setHours(0, 0, 0, 0)
	return twoMonthsAgo
}

export function isNewEntity(createdAt: string | undefined) {
	if (!createdAt) return false
	const createdDate = new Date(createdAt)
	return createdDate.getTime() > newRestaurant().getTime()
}
