import {
	AfterViewInit,
	Component,
	ElementRef,
	HostBinding,
	inject,
	QueryList,
	signal,
	ViewChildren,
} from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatExpansionModule } from '@angular/material/expansion'
import { CommonModule } from '@angular/common'
import { MatBottomSheetRef, MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { BottomSheetHeaderComponent, LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import { Store } from '@ngrx/store'
import {
	Address,
	AddressActions,
	getAddresses,
	getSelectedAddress,
	getSelectedNeighborhood,
	getStatusAddress,
	VenueActions,
} from '@monorepo-channels/channels/domain'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { removeAccents } from '@monorepo-channels/shared/util-helpers'
import { startWith, distinctUntilChanged, switchMap, map, combineLatest, take } from 'rxjs'
import { FormControl, ReactiveFormsModule } from '@angular/forms'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatExpansionModule,
		MatIconModule,
		MatBottomSheetModule,
		MatButtonModule,
		BottomSheetHeaderComponent,
		MatProgressSpinnerModule,
		LoadingPageComponent,
		ReactiveFormsModule,
	],
	selector: 'feature-pm-cities',
	templateUrl: './cities.component.html',
	styleUrls: ['./cities.component.scss'],
})
export class CitiesComponent implements AfterViewInit {
	private store = inject(Store)

	public statusAddress$ = this.store.select(getStatusAddress)
	public addresses$ = this.store.select(getAddresses)

	private bottomSheetRef = inject<MatBottomSheetRef<CitiesComponent>>(MatBottomSheetRef)

	public searchCityControl = new FormControl('', { nonNullable: true })

	filteredAddresses$ = this.searchCityControl.valueChanges.pipe(
		startWith(''), // Garante que todos os endereços sejam exibidos inicialmente
		distinctUntilChanged(),
		switchMap((searchCity: string) => {
			if (!searchCity.trim()) {
				return this.addresses$ // sem filtro se a pesquisa estiver vazia
			} else {
				return this.addresses$.pipe(
					map(addresses =>
						addresses.filter(
							address =>
								removeAccents(address.city)
									.toLowerCase()
									.includes(removeAccents(searchCity).toLowerCase()) ||
								address.state.toLowerCase().includes(searchCity.toLowerCase()) ||
								address.neighborhoods.some(neighborhood =>
									removeAccents(neighborhood.neighborhood)
										.toLowerCase()
										.includes(removeAccents(searchCity).toLowerCase())
								)
						)
					)
				)
			}
		})
	)

	city = signal('')
	neigh = signal('')

	@ViewChildren('cityItem', { read: ElementRef })
	cities!: QueryList<ElementRef>

	constructor() {}

	ngAfterViewInit(): void {
		combineLatest([this.store.select(getSelectedAddress), this.store.select(getSelectedNeighborhood)])
			.pipe(
				take(1),
				map(([address, neighborhood]) => ({ address, neighborhood }))
			)
			.subscribe(vm => {
				if (vm.address) {
					this.city.set(vm.address.city)
					const panel = this.cities.toArray().find(panel => {
						const inputCity = panel.nativeElement.querySelector('input[type="hidden"]')
						return inputCity && inputCity.value === vm.address?.city
					})

					if (panel) {
						// Find the scrollable container
						const scrollContainer = panel.nativeElement.closest('div[style*="overflow-y: auto"]')
						if (scrollContainer) {
							// Wait for the expansion panel animation to complete
							setTimeout(() => {
								// Calculate the scroll position
								const containerRect = scrollContainer.getBoundingClientRect()
								const panelRect = panel.nativeElement.getBoundingClientRect()
								const scrollOffset = panelRect.top - containerRect.top - 100 // 100px offset from top

								// Smooth scroll to the calculated position
								scrollContainer.scrollBy({
									top: scrollOffset,
									behavior: 'smooth',
								})
							}, 100) // Small delay to ensure panel is expanded
						}
					}
				}
				if (vm.neighborhood && vm.address) {
					this.neigh.set(`${vm.address.city}_${vm.neighborhood}`)
				}
				if (!vm.neighborhood && vm.address) {
					this.neigh.set(`${vm.address.city}_TODOS`)
				}
			})
	}

	@HostBinding('style')
	get style() {
		return {
			// '--mat-expansion-header-expanded-state-height': '38px',
			'--mat-expansion-header-collapsed-state-height': '38px',
		}
	}

	setCity(city: string) {
		this.city.set(city)
	}

	selectCity() {
		this.bottomSheetRef.dismiss()
	}

	setCurrentNeighborhoodAndAddress(neighborhood: string | undefined, address: Address | null) {
		this.store.dispatch(
			AddressActions.setCurrentNeighborhood({
				neighborhood: neighborhood === 'Todos' ? undefined : neighborhood,
			})
		)
		if (address) this.store.dispatch(AddressActions.setCurrentAddress({ address }))
		this.store.dispatch(VenueActions.applyFilter())
		this.bottomSheetRef.dismiss()
	}
}
