import { Cuisine } from '../../gateways/venues.gateway'
import { Status } from '../helper.state'

export const featureCuisineName = 'cuisine'

export interface CuisinesState {
	cuisines: Cuisine[] | null
	selectedCuisines: Cuisine[]
	status: Status
	error: string
}

export const DEFAULT_CUISINE_TEXT = 'Tipo de Culinária'

export const initialStateCusisine: CuisinesState = {
	cuisines: null,
	selectedCuisines: [{ _id: '', name: DEFAULT_CUISINE_TEXT }],
	status: 'idle',
	error: '',
}
