<main *ngIf="vm$ | async as vm">
	<section class="container">
		<h1 class="title">Detalhes da reserva</h1>
	</section>

	<ui-pm-swiper-container>
		<swiper-slide *ngFor="let url of vm.order?.venue?.banners">
			<figure class="image-container">
				<img [src]="url.value" />
			</figure>
		</swiper-slide>
	</ui-pm-swiper-container>

	<section class="container" *ngIf="vm.order">
		<p class="restaurant">Restaurante</p>
		<p class="venue-title">{{ vm.order.venue.name }}</p>
	</section>

	<section class="container">
		<ul class="card-box-shadow" style="margin-top: 24px; margin-bottom: 24px">
			<li class="card-box-shadow__item">
				<mat-icon svgIcon="pm-icons:profile" style="width: 24px; height: 24px"></mat-icon>
				{{ vm.client?.fullName }}
			</li>
			<li class="card-box-shadow__item" *ngIf="vm.order && vm.order.sectionLabel">
				<mat-icon svgIcon="pm-icons:map" style="width: 23px; height: 23px"> </mat-icon>
				{{ vm.order.sectionLabel }}
			</li>
			<li class="card-box-shadow__item">
				<mat-icon svgIcon="pm-icons:profile-plus" style="width: 23px; height: 23px"> </mat-icon>
				{{ vm.order?.partySize }} {{ vm.order?.partySize === 1 ? 'pessoa' : 'pessoas' }}
			</li>
			<li class="card-box-shadow__item">
				<mat-icon svgIcon="pm-icons:calendar" style="width: 23px; height: 23px"> </mat-icon>
				{{ vm.order?.reservationDateTime | date: "EEEE, d 'de' MMMM" | capitalizeFirst }}
			</li>
			<li class="card-box-shadow__item">
				<mat-icon svgIcon="pm-icons:time" style="width: 23px; height: 23px"> </mat-icon>
				{{ vm.order?.date?.time }}
			</li>
			<li class="card-box-shadow__item">
				<mat-icon svgIcon="pm-icons:map" style="width: 24px; height: 24px; flex-shrink: 0">
				</mat-icon>
				{{ vm.order?.venue?.address?.value }}
			</li>
		</ul>
		<section class="order-card__cancel" style="margin-bottom: 12px" *ngIf="vm.order?.isCanceled">
			Reserva cancelada
		</section>
	</section>

	<ng-container *ngIf="vm.order && vm.order.benefits">
		<div style="background: var(--celebrations-background)">
			<p class="benefits-title tablet-up-center" style="margin-bottom: 20px; padding: 12px 20px">
				Benefícios do seu cartão
			</p>
		</div>
		<section class="container">
			<ui-pm-benefits type="expansion" [benefits]="vm.order.benefits"></ui-pm-benefits>
		</section>
	</ng-container>
	<section>
		<ui-pm-celebrations-ribbon
			*ngIf="vm.order?.voucher?.value"
			[voucherValue]="vm.order?.voucher?.value"
		></ui-pm-celebrations-ribbon>
	</section>

	<section class="container" style="margin-top: 35px">
		<div style="display: flex; gap: 21px; z-index: 0; position: relative">
			<a [href]="vm?.order?.venue?.googleMaps?.mapsLink" target="_blank">
				<button mat-button class="normal-button">Ver localização</button>
			</a>
			<a [href]="vm.order?.venue?.liveMenu?.liveMenuURL" target="_blank">
				<button mat-button class="normal-button">Cardápio digital</button>
			</a>
		</div>
		<div style="height: 40px"></div>
	</section>
	<div style="height: 90px"></div>
	<ui-pm-footer-buttons type="fixed">
		<button mat-raised-button (click)="goBack()">Voltar</button>
		<button
			mat-raised-button
			color="primary"
			(click)="cancelOrder(vm.order)"
			*ngIf="vm.order?.canCancel$ | async"
		>
			Cancelar
		</button>
	</ui-pm-footer-buttons>
</main>
