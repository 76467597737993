import { Component, inject } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonModule } from '@angular/material/button'
import { Store } from '@ngrx/store'
import { getClient } from '@monorepo-channels/channels/domain'
import { CommonModule } from '@angular/common'
import { Router, RouterLink } from '@angular/router'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { ExitComponent, SelectThemeOptionsComponent } from '@monorepo-channels/feature-pm'
import { take } from 'rxjs'
import { UserProfileComponent } from '@monorepo-channels/components/ui-pm'
import { environment } from '@monorepo-channels/shared/util-environments'

@Component({
	imports: [
		MatIconModule,
		DivisorUiComponent,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		MatListModule,
		MatButtonModule,
		CommonModule,
		RouterLink,
		UserProfileComponent,
	],
	selector: 'pm-sidebar',
	standalone: true,
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
	private store = inject(Store)
	public router = inject(Router)
	public client$ = this.store.select(getClient)
	public bottomSheet = inject(MatBottomSheet)
	public env = environment

	logout() {
		this.client$.pipe(take(1)).subscribe(client => {
			this.bottomSheet.open(ExitComponent, { data: { client: client?.firstName } })
		})
	}

	openOptionsTheme() {
		this.bottomSheet.open(SelectThemeOptionsComponent)
	}
}
