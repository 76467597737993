import { Component } from '@angular/core'
import { ReservationComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	selector: 'pm-reservation-page',
	imports: [ReservationComponent],
	template: '<feature-pm-reservation/>',
})
export class ReservationPageComponent {}
