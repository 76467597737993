import { booleanAttribute, Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'ui-pm-tag',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
})
export class TagUiComponent {
	@Input({ transform: booleanAttribute }) optionalBorder: boolean = true
	@Input() isMichelin: string = ''
	@Input({ transform: booleanAttribute }) isNew: boolean = false
}
