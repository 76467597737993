import { Route } from '@angular/router'
import { VenueListPageComponent } from './pages/venues-list/venues-list.page'
import { authGuard } from '@monorepo-channels/channels/domain'
import { CheckinPageComponent } from './pages/checkin/checkin.page'
import { NewCardPageComponent } from './pages/new-card/new-card.page'
import { ReservationPageComponent } from './pages/reservation/reservation.page'
import { OrdersHistoryPageComponent } from './pages/order-history/order-history.page'
import { OrdersDetailsPageComponent } from './pages/order-history/order-details.page'
import { WalletPageComponent } from './pages/wallet/wallet.page'
import { LoginAutomaticPageComponent } from './pages/login-automatic/login-automatic.page'
import { FavoritesPageComponent } from './pages/favorites/favorites.page'
import { VenueDetailsPageComponent } from './pages/venues-list/venue-details/venue-details.page'
import { FAQPageComponent } from './pages/menu/faq/faq.page'
import { HowItWorksPageComponent } from './pages/menu/how-it-works/how-it-works.page'
import { PrivacyPolicyPageComponent } from './pages/menu/privacy-policy/privacy-policy.page'
import { AboutTagmePageComponent } from './pages/menu/about-tagme/about-tagme.page'
import { TermsOfUsePageComponent } from './pages/menu/terms-of-use/terms-of-use.page'
import { LoginClientPageComponent } from './pages/login-client/login-client.page'
import { RegisterPageComponent } from './pages/register/register.page'
import { ConciergePageComponent } from './pages/concierge/concierge.component'
import { UpdatePasswordPageComponent } from './pages/update-password/update-password.page'
import { EditProfilePageComponent } from './pages/edit-profile/edit-profile.page'

export const appRoutes: Route[] = [
	{
		path: '',
		component: VenueListPageComponent,
		title: 'Bradesco Programa Menu - Home',
	},
	{
		path: 'favoritos',
		component: FavoritesPageComponent,
		title: 'Bradesco Programa Menu - Favoritos',
		canActivate: [authGuard],
	},
	{
		path: 'restaurante/:venueId',
		component: VenueDetailsPageComponent,
		title: 'Bradesco Programa Menu - Restaurante',
	},
	{
		path: 'restaurante/:venueId/checkin',
		component: CheckinPageComponent,
		title: 'Bradesco Programa Menu - Check-in',
		canActivate: [authGuard],
	},
	{
		path: 'restaurante/:venueId/reserva',
		component: ReservationPageComponent,
		title: 'Bradesco Programa Menu - Restaurante - Reserva',
		canActivate: [authGuard],
	},
	{
		path: 'carteira/cadastrar-cartao',
		component: NewCardPageComponent,
		title: 'Bradesco Programa Menu - Cadastrar Cartão',
		canActivate: [authGuard],
	},
	{
		path: 'reservas-e-checkins',
		component: OrdersHistoryPageComponent,
		title: 'Bradesco Programa Menu - Histórico de reservas/checkins',
		canActivate: [authGuard],
	},
	{
		path: 'reservas-e-checkins/pedido/:orderId',
		title: 'Bradesco Programa Menu - Detalhes do Pedido',
		component: OrdersDetailsPageComponent,
		canActivate: [authGuard],
	},
	{
		path: 'carteira',
		component: WalletPageComponent,
		title: 'Bradesco Programa Menu - Carteira',
		canActivate: [authGuard],
	},
	{
		path: 'login',
		component: LoginAutomaticPageComponent,
		title: 'Programa Menu - Recuperando Login',
	},
	{
		path: 'entrar',
		component: LoginClientPageComponent,
		title: 'Programa Menu - Login',
	},
	{
		path: 'cadastrar',
		component: RegisterPageComponent,
		title: 'Programa Menu - Cadastrar',
	},
	{
		path: 'atualizar-senha',
		component: UpdatePasswordPageComponent,
		title: 'Bradesco Programa Menu - Atualizar senha',
	},
	{
		path: 'concierge',
		component: ConciergePageComponent,
		title: 'Bradesco Programa Menu - Concierge',
	},
	{
		title: 'Bradesco Programa Menu - Perfil',
		path: 'perfil/editar',
		component: EditProfilePageComponent,
		// canActivate: [authGuard],
	},
	{
		title: 'Bradesco Programa Menu - Menu',
		path: 'menu',
		children: [
			{
				path: 'faq',
				component: FAQPageComponent,
				title: 'Bradesco Programa Menu - FAQ',
			},
			{
				path: 'como-funciona',
				component: HowItWorksPageComponent,
				title: 'Bradesco Programa Menu - Como Funciona',
			},
			{
				path: 'politica-de-privacidade',
				component: PrivacyPolicyPageComponent,
				title: 'Bradesco Programa Menu - Política de Privacidade',
			},
			{
				path: 'sobre-a-tagme',
				component: AboutTagmePageComponent,
				title: 'Bradesco Programa Menu - Sobre a Tagme',
			},
			{
				path: 'termos-de-uso',
				component: TermsOfUsePageComponent,
				title: 'Bradesco Programa Menu - Termos de uso',
			},
		],
	},
	{ path: '**', redirectTo: '/' },
]
