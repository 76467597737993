import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'

import { LoginHomologationDirective } from '@monorepo-channels/channels/domain'
import {
	CelebrationsCardComponent,
	FilterComponent,
	SelectCardComponent,
	VenueCardListComponent,
} from '@monorepo-channels/feature-pm'
import { SwiperUiComponent } from '@monorepo-channels/components/ui-pm'
import { NgFor } from '@angular/common'

@Component({
	standalone: true,
	imports: [
		SwiperUiComponent,
		VenueCardListComponent,
		NgFor,
		FilterComponent,
		SelectCardComponent,
		CelebrationsCardComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA], // why? because of the custom element <swiper-element>
	templateUrl: 'venues-list.page.html',
	styleUrls: ['venues-list.page.scss'],
	hostDirectives: [LoginHomologationDirective],
})
export class VenueListPageComponent {
	banners = [
		{
			imageUrl:
				'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/banner_pm_novo_mob.png',
			altText: 'banner',
			title: 'Menu',
			description: 'O programa Menu está de cara <br/>nova e com muitas novidades!',
			click: () => console.log('banner clicked'),
		},
	]
}
