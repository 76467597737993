import { CommonModule, formatDate } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { FooterButtonsComponent } from '../footer/footer-buttons.component'
import { MatButtonModule } from '@angular/material/button'
import { Client, VenueDetail } from '@monorepo-channels/channels/domain'
import { CapitalizeFirstPipe } from '@monorepo-channels/shared/util-helpers'
import { BenefitsUiComponent } from '../benefits/benefits.component'
import { take, timer } from 'rxjs'
import { trigger, transition, style, animate } from '@angular/animations'
import { CelebrationsRibbonComponent } from '../celebrations-ribbon/celebrations-ribbon.component'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		FooterButtonsComponent,
		MatButtonModule,
		CapitalizeFirstPipe,
		BenefitsUiComponent,
		CelebrationsRibbonComponent,
	],
	selector: 'ui-pm-order-success',
	templateUrl: './order-success.component.html',
	styleUrls: ['./order-success.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
			transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
		]),
	],
})
export class OrderSuccessComponent implements OnInit {
	@Input({ required: true }) type!: 'checkin' | 'reservation'
	@Input({ required: true }) partySize!: number
	@Input({ required: true }) orderDate!: Date | string
	@Input({ required: false }) sectionLabel?: string //Check-In doen't have sectionLabel
	@Input({ required: true }) reservationTime!: string
	@Input({ required: true }) venue!: VenueDetail
	@Input({ required: true }) client!: Client
	@Input({ required: false }) voucherValue!: string | null
	@Output() ok = new EventEmitter<void>()

	public copied = signal(false)

	ngOnInit(): void {
		this.orderDate =
			typeof this.orderDate === 'string'
				? new Date(`${this.orderDate}T10:00:00Z`)
				: new Date(this.orderDate)
	}

	cancelReservation() {}

	shareReservation() {
		// compartilha a reserva (dados do input)
		const date = formatDate(this.orderDate, 'dd/MM/yyyy', 'pt-br')
		const text = `Confira a reserva que fiz no Bradesco Programa Menu! 🍽️
🏨: ${this.venue.name}
👥: ${this.partySize} ${this.partySize === 1 ? 'pessoa' : 'pessoas'}
⏰: ${this.reservationTime}
📅: ${date}`

		if (navigator.share) {
			navigator
				.share({
					title: this.venue.name,
					text,
				})
				.catch(error => console.log('Sharing failed', error))
		} else {
			navigator.clipboard.writeText(text).then(() => {
				this.showCopiedNotification()
			})
		}
	}
	showCopiedNotification() {
		this.copied.set(true)
		timer(2000)
			.pipe(take(1))
			.subscribe(() => {
				this.copied.set(false)
			})
	}
}
