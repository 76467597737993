import { CommonModule } from '@angular/common'
import { Component, inject, Signal, signal } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { RouterLink } from '@angular/router'
import { getClient } from '@monorepo-channels/channels/domain'
import { UserProfileComponent } from '@monorepo-channels/components/ui-pm'
import { Store } from '@ngrx/store'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NgxMaskDirective } from 'ngx-mask'
import { MatIconModule } from '@angular/material/icon'
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { GenericErrorComponent } from '../../simple-bottom-sheets/generic-error.component'
import { OtpComponent } from '../../simple-bottom-sheets/otp-component'
import { take } from 'rxjs'
import { validateCPF } from '@monorepo-channels/shared/util-helpers'

interface FieldState {
	focused: Signal<boolean>
	editing: Signal<boolean>
	setFocused: (value: boolean) => void
	setEditing: (value: boolean) => void
}

@Component({
	selector: 'feature-pm-edit-profile',
	imports: [
		UserProfileComponent,
		CommonModule,
		MatButtonModule,
		RouterLink,
		ReactiveFormsModule,
		NgxMaskDirective,
		MatIconModule,
		MatBottomSheetModule,
	],
	standalone: true,
	templateUrl: './edit-profile.component.html',
	styleUrls: [`edit-profile.component.scss`, '../common/page-common.scss'],
})
export class EditProfileComponent {
	private store = inject(Store)
	private fb = inject(FormBuilder)
	private bottomSheet = inject(MatBottomSheet)
	public client$ = this.store.select(getClient)

	public step = signal<'confirm_code' | null>(null)

	private emailValidator = Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
	private phoneValidator = Validators.pattern(/^\d{10,11}$/)

	public userForm = this.fb.nonNullable.group({
		email: [{ value: '', disabled: true }, [Validators.required, this.emailValidator]],
		phone: [{ value: '', disabled: true }, [Validators.required, this.phoneValidator]],
		cpf: [{ value: '', disabled: true }, [Validators.required, validateCPF.bind(this)]],
	})

	private fieldStates = new Map<string, FieldState>()

	public isAnyFieldEditing = signal(false)
	public currentEditingField = signal<string | null>(null)

	constructor() {
		;['email', 'phone', 'cpf'].forEach(fieldName => {
			this.initializeFieldState(fieldName)
		})
		this.client$.pipe(takeUntilDestroyed()).subscribe(client => {
			if (!client) return
			this.userForm.patchValue({
				email: client.email,
				phone: client?.phone ?? '',
				cpf: client.cpf,
			})
		})
	}

	toggleFieldEditing(fieldName: string) {
		const control = this.userForm.get(fieldName)
		const fieldState = this.getFieldState(fieldName)

		if (control?.disabled) {
			// If another field is being edited, don't allow editing
			if (this.isAnyFieldEditing()) {
				this.bottomSheet.open(GenericErrorComponent, {
					data: {
						title: 'Aviso',
						error: 'Você só pode editar um item de cada vez.',
						btnMessage: 'Fechar',
					},
				})
				return
			}

			control.enable()
			fieldState.setEditing(true)
			this.isAnyFieldEditing.set(true)
			this.currentEditingField.set(fieldName)
		} else {
			control?.disable()
			fieldState.setEditing(false)
			this.isAnyFieldEditing.set(false)
			this.currentEditingField.set(null)
		}
	}
	private initializeFieldState(fieldName: string) {
		const focused = signal(false)
		const editing = signal(false)

		this.fieldStates.set(fieldName, {
			focused,
			editing,
			setFocused: (value: boolean) => focused.set(value),
			setEditing: (value: boolean) => editing.set(value),
		})
	}

	getFieldState(fieldName: string): FieldState {
		return this.fieldStates.get(fieldName)!
	}

	saveChanges() {
		const editingField = this.currentEditingField()
		if (editingField) {
			const control = this.userForm.get(editingField)
			const fieldState = this.getFieldState(editingField)
			this.client$.pipe(take(1)).subscribe({
				next: client => {
					if (!client?.id) return
					this.bottomSheet.open(OtpComponent, {
						data: {
							clientId: client.id,
							dto: { key: editingField, value: control?.value },
							email: client.email,
						},
						disableClose: true,
					})
				},
			})
			control?.disable()
			fieldState.setEditing(false)
			this.isAnyFieldEditing.set(false)
			this.currentEditingField.set(null)
		}
	}
}
