<main class="container">
	<ng-container *ngIf="step() === 'receive_code'">
		<section class="title-container">
			<h1 class="title">
				Bem-vindo(a) ao <br />
				Programa Menu
			</h1>
			<p class="text">Realize o cadastro e usufrua <br />dos benefícios do seu cartão!</p>
		</section>

		<form [formGroup]="receiveCodeForm" (ngSubmit)="receiveCode()">
			<div class="form-field">
				<input
					type="text"
					id="cpf"
					class="form-field__input"
					formControlName="cpf"
					mask="000.000.000-00"
					(focus)="setFocus('cpf', true)"
					(blur)="setFocus('cpf', false)"
					[ngClass]="{ focused: focusStates.cpf, filled: receiveCodeForm.controls.cpf.value }"
				/>
				<label for="cpf" class="form-field__label">CPF</label>
				<span class="form-field__underline"></span>
			</div>
			<ng-container *ngIf="selectedClient()">
				<div class="form-field" style="margin-top: 24px; position: relative">
					<input
						type="email"
						id="email"
						class="form-field__input"
						formControlName="email"
						(focus)="setFocus('email', true)"
						(blur)="setFocus('email', false)"
						[ngClass]="{
							focused: focusStates.email,
							filled: receiveCodeForm.controls.email.value
						}"
					/>
					<label for="email" class="form-field__label">E-mail</label>
					<span class="form-field__underline"></span>
				</div>
			</ng-container>

			@if (isLoading()) {
				<ui-pm-loading-page style="--height: 100px" />
			} @else {
				<button
					mat-raised-button
					style="margin-top: 32px"
					[disabled]="!receiveCodeForm.valid"
					[color]="!receiveCodeForm.valid ? '' : 'primary'"
				>
					Continuar
				</button>
			}
		</form>

		<section class="register">
			<p>Já possui conta?</p>
			<button mat-raised-button routerLink="/entrar">Entrar</button>
		</section>
	</ng-container>

	<ng-container *ngIf="step() === 'confirm_code'">
		<section class="title-container">
			<h1 class="title">Verificação</h1>
			<p class="text">
				Informe o código enviado para o e-mail
				<span style="font-weight: 480">{{ client?.email }}</span>
			</p>
		</section>
		<form [formGroup]="confirmCodeForm" (ngSubmit)="confirmCode()">
			<section class="otp-input-container">
				<input
					type="text"
					maxlength="1"
					class="otp-input"
					(keyup)="onKeyUp($event)"
					(keydown)="onKeyDown($event, i)"
					(paste)="onPaste($event)"
					(input)="onInput($event)"
					*ngFor="let item of [0, 1, 2, 3, 4, 5]; let i = index"
					[formControlName]="'otp' + i"
				/>
			</section>
			@if (isLoadingResend()) {
				<p class="resend">Reenviando...</p>
			} @else {
				<ng-container *ngIf="countdown$ | async as value; else resend">
					<p class="resend">Reenviar o código em {{ value }} segundos.</p>
				</ng-container>
				<ng-template #resend>
					<p class="resend">
						Não recebeu o código? <span class="resend-txt" (click)="resendCode()">Reenviar</span>
					</p>
				</ng-template>
			}
			@if (isLoading()) {
				<ui-pm-loading-page style="--height: 100px" />
			} @else {
				<button
					mat-raised-button
					style="margin-top: 32px"
					[disabled]="!confirmCodeForm.valid"
					[color]="!confirmCodeForm.valid ? '' : 'primary'"
				>
					Continuar
				</button>
			}
		</form>
	</ng-container>

	<ng-container *ngIf="step() === 'complete_registration'">
		<section class="title-container" style="width: 100%">
			<h1 class="title">Completar cadastro</h1>
		</section>

		<form [formGroup]="completeRegistrationForm" (ngSubmit)="completeRegistration()">
			<div
				class="form-field"
				style="margin-top: 24px; position: relative"
				[ngClass]="{
					error:
						completeRegistrationForm.controls.name.getError('invalidName') &&
						completeRegistrationForm.controls.name.touched
				}"
			>
				<input
					type="name"
					id="name"
					class="form-field__input"
					formControlName="name"
					(focus)="setFocus('name', true)"
					(blur)="setFocus('name', false)"
					[ngClass]="{
						focused: focusStates.name,
						filled: completeRegistrationForm.controls.name.value
					}"
				/>
				<label for="name" class="form-field__label">Nome e sobrenome</label>
				<span class="form-field__error"> Por favor, preencha o nome e sobrenome. </span>
				<span class="form-field__underline"></span>
			</div>

			<div
				class="form-field"
				style="margin-top: 24px; position: relative"
				[ngClass]="{
					error:
						completeRegistrationForm.controls.phone.getError('mask') &&
						completeRegistrationForm.controls.phone.touched
				}"
			>
				<input
					type="phone"
					id="phone"
					class="form-field__input"
					formControlName="phone"
					prefix="+55 "
					[validation]="true"
					mask="(00) 00000-0000"
					(focus)="setFocus('phone', true)"
					(blur)="setFocus('phone', false)"
					[ngClass]="{
						focused: focusStates.phone,
						filled: completeRegistrationForm.controls.phone.value
					}"
				/>
				<label for="phone" class="form-field__label">Telefone</label>
				<span class="form-field__error">Por favor, complete o telefone.</span>
				<span class="form-field__underline"></span>
			</div>
			<div
				class="form-field"
				style="margin-top: 24px; position: relative"
				[ngClass]="{
					error:
						completeRegistrationForm.controls.bin.getError('minlength') &&
						completeRegistrationForm.controls.bin.touched
				}"
			>
				<input
					type="text"
					id="bin"
					class="form-field__input"
					formControlName="bin"
					[maxlength]="6"
					(focus)="setFocus('bin', true)"
					(blur)="setFocus('bin', false)"
					[ngClass]="{
						focused: focusStates.bin,
						filled: completeRegistrationForm.controls.bin.value
					}"
				/>
				<label for="bin" class="form-field__label">6 primeiros dígitos do cartão</label>
				<!-- <span class="form-field__error">Por favor, complete.</span> -->
				<span class="form-field__underline"></span>
			</div>
			<div formGroupName="passwordGroup">
				<div class="field" style="margin-top: 24px; position: relative">
					<div
						class="form-field"
						style="margin-top: 24px; position: relative"
						[ngClass]="{
							error:
								completeRegistrationForm.controls.passwordGroup.controls.password.invalid &&
								completeRegistrationForm.controls.passwordGroup.controls.password.touched
						}"
					>
						<input
							[type]="showPassword.password ? 'text' : 'password'"
							id="password"
							class="form-field__input"
							formControlName="password"
							(focus)="setFocus('password', true)"
							(blur)="setFocus('password', false)"
							[ngClass]="{
								focused: focusStates.password,
								filled: completeRegistrationForm.controls.passwordGroup.controls.password
									.value
							}"
						/>
						<label for="password" class="form-field__label">Senha</label>
						<span class="form-field__underline"></span>
						<mat-icon
							[svgIcon]="showPassword.password ? 'pm-icons:eye-on' : 'pm-icons:eye-off'"
							style="
								position: absolute;
								right: 8px;
								top: 50%;
								transform: translateY(-70%);
								cursor: pointer;
								width: 20px;
								height: 20px;
							"
							(click)="showPassword.password = !showPassword.password"
						></mat-icon>
					</div>
				</div>
				<div class="field" style="margin-top: 24px; position: relative">
					<div
						class="form-field"
						style="margin-top: 24px; position: relative"
						[ngClass]="{
							error:
								completeRegistrationForm.controls.passwordGroup.controls.confirmPassword.getError(
									'passwordMismatch'
								) &&
								completeRegistrationForm.controls.passwordGroup.controls.confirmPassword
									.touched
						}"
					>
						<input
							[type]="showPassword.confirmPassword ? 'text' : 'password'"
							id="confirmPassword"
							class="form-field__input"
							formControlName="confirmPassword"
							(focus)="setFocus('confirmPassword', true)"
							(blur)="setFocus('confirmPassword', false)"
							[ngClass]="{
								focused: focusStates.confirmPassword,
								filled: completeRegistrationForm.controls.passwordGroup.controls
									.confirmPassword.value
							}"
						/>
						<label for="confirmPassword" class="form-field__label">Confirmar senha</label>
						<span class="form-field__underline"></span>
						<span class="form-field__error">As senhas não coincidem.</span>
						<mat-icon
							[svgIcon]="showPassword.confirmPassword ? 'pm-icons:eye-on' : 'pm-icons:eye-off'"
							style="
								position: absolute;
								right: 8px;
								top: 50%;
								transform: translateY(-70%);
								cursor: pointer;
								width: 20px;
								height: 20px;
							"
							(click)="showPassword.confirmPassword = !showPassword.confirmPassword"
						></mat-icon>
					</div>
				</div>

				<ng-container *ngIf="completeRegistrationForm.controls.passwordGroup.controls as group">
					<ul class="field__feedback">
						<p style="font-size: 14px; font-weight: 480; margin-bottom: 12px; margin-top: 40px">
							Sua senha deve conter
						</p>
						<li
							[ngClass]="{
								underline: !group.password.hasError('passwordLength')
							}"
						>
							Mín. 8 caracteres e Máx 20 caracteres.
						</li>
						<li [ngClass]="{ underline: !group.password.hasError('passwordUppercase') }">
							Min. 1 dígito em letra maiúscula.
						</li>
						<li [ngClass]="{ underline: !group.password.hasError('passwordLowercase') }">
							Mín. 1 dígito em letra minúscula.
						</li>
						<li [ngClass]="{ underline: !group.password.hasError('passwordSpecial') }">
							Mín. 1 caractere especial (!, &commat; #, $, &, *)
						</li>
					</ul>
				</ng-container>
				<div style="height: 24px"></div>
				<feature-pm-terms-and-conditions-checkbox
					position="static"
					use="o Programa Menu"
					(changeInput)="toggleTermsAndConditions($event)"
				/>
			</div>
			@if (isLoading()) {
				<ui-pm-loading-page style="--height: 100px" />
			} @else {
				<button
					mat-raised-button
					style="margin-top: 32px"
					[disabled]="!completeRegistrationForm.valid || !eula()"
					[color]="!completeRegistrationForm.valid || !eula() ? '' : 'primary'"
				>
					Enviar
				</button>
			}
		</form>
	</ng-container>

	<ng-container *ngIf="step() === 'registration_finished'">
		<mat-icon
			svgIcon="pm-icons:check-circle-red"
			style="width: 80px; height: 80px; margin: 80px auto 24px; display: block"
		></mat-icon>
		<h1 class="title">Cadastro realizado!</h1>
		<p class="text" style="width: 255px; margin: 0 auto; margin-top: 24px; text-align: center">
			Você já pode fazer login e aproveitar os benefícios do seu cartão bradesco nos melhores
			restaurantes
		</p>
		<button mat-raised-button routerLink="/entrar" style="margin-top: 52px">Fazer login</button>
	</ng-container>
</main>
