<div *ngIf="dataSource.data.length !== 0">
	<!-- Dados Pessoais -->
	<div class="personal-data-section">
		<mat-card-title>Dados pessoais</mat-card-title>
		<mat-table [dataSource]="dataSource" class="mat-elevation-z8" class="celebrations-table">
			<ng-container matColumnDef="nome">
				<mat-header-cell *matHeaderCellDef class="custom-column-title"> Nome </mat-header-cell>
				<mat-cell *matCellDef="let client"> {{ client.name }} {{ client.lastName }}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="cpf">
				<mat-header-cell *matHeaderCellDef class="custom-column-title"> CPF </mat-header-cell>
				<mat-cell *matCellDef="let client"> {{ client.cpf | mask: '000.000.000-00' }} </mat-cell>
			</ng-container>

			<ng-container matColumnDef="telefone">
				<mat-header-cell *matHeaderCellDef class="custom-column-title"> Telefone </mat-header-cell>
				<mat-cell *matCellDef="let client">
					{{ client.phone | mask: '+00 (00) 00000-0000' }}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="email">
				<mat-header-cell *matHeaderCellDef class="custom-column-title"> E-mail </mat-header-cell>
				<mat-cell *matCellDef="let client"> {{ client.email }} </mat-cell>
			</ng-container>

			<ng-container matColumnDef="cartao">
				<mat-header-cell *matHeaderCellDef class="custom-column-title">
					Cartão do cliente
				</mat-header-cell>
				<mat-cell *matCellDef="let client">
					<span *ngFor="let category of client.categories; let last = last">
						{{ category.name }} - {{ category.digits }} ({{ isCardComplete(category) }})
						{{ !last ? ', ' : '' }}
					</span>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="cadastro" style="flex: 2">
				<mat-header-cell *matHeaderCellDef class="custom-column-title"> Cadastro </mat-header-cell>
				<mat-cell *matCellDef="let client"> {{ isFullRegistration(client) }} </mat-cell>
			</ng-container>

			<!-- Ações Column -->
			<ng-container matColumnDef="acoes">
				<mat-header-cell *matHeaderCellDef class="custom-column-title">Ações</mat-header-cell>
				<mat-cell *matCellDef="let client">
					<button mat-raised-button color="primary" style="width: 110px; height: 30px">
						Selecionar
					</button>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>
	</div>

	<mat-divider></mat-divider>

	<!-- Benefício Celebrations -->
	<div class="celebrations-section">
		<mat-card-title>Celebrations</mat-card-title>
		<!-- <p>Possui benefício Celebrations? <span>{{celebrationsBenefit}}</span></p>
		<p>Data início: <span>{{celebrationsStartDate | date: 'dd/MM/yyyy'}}</span></p>
		<p>Data final: <span>{{celebrationsEndDate | date: 'dd/MM/yyyy'}}</span></p> -->
		<button mat-raised-button color="primary" style="width: 210px; height: 35px">
			Adicionar benefício
		</button>
	</div>

	<mat-divider></mat-divider>

	<!-- Histórico -->
	<div class="history-section">
		<div class="history-header">
			<mat-card-title>Histórico</mat-card-title>

			<div class="history-nav-buttons">
				<button
					mat-icon-button
					(click)="handlePrevious()"
					[disabled]="currentIndex === 0"
					class="nav-button"
					[ngStyle]="{ background: currentIndex === 0 ? '#DDDDDD' : 'var(--background-primary)' }"
				>
					<mat-icon
						svgIcon="pm-icons:left-arrow"
						style="transform: translateY(-2px); --icon-color-red: #fff"
					></mat-icon>
				</button>
				<button
					mat-icon-button
					(click)="handleNext()"
					[disabled]="currentIndex >= vouchers.length - itemsPerPage"
					class="nav-button"
					[ngStyle]="{
						background:
							currentIndex >= vouchers.length - itemsPerPage
								? '#DDDDDD'
								: 'var(--background-primary)'
					}"
				>
					<mat-icon
						svgIcon="pm-icons:right-arrow"
						style="transform: translateY(-2px); --icon-color-black: #fff"
					></mat-icon>
				</button>
			</div>
		</div>

		<div class="carousel-container">
			<mat-card class="history-card" *ngFor="let voucher of visibleVouchers">
				<div
					class="history-card-content"
					[ngClass]="{ 'expired-class': voucher.status === 'expired' }"
				>
					<div class="info-container">
						<p><span>Data da criação: </span>{{ voucher.createdAt | date: 'dd/MM/yyyy' }}</p>
						<p><span>Usuário: </span>{{ getClientName(voucher.clientId) }}</p>
						<p><span>Horário: </span>{{ voucher.createdAt | date: 'HH:mm:ss' }}</p>
						<p><span>Tipo: </span>Bônus</p>
						<p><span>Motivo: </span>{{ voucher.reason }}</p>
						<p><span>Concedido por: </span>{{ voucher.conciergeUser }}</p>
						<p>
							<span style="color: var(--content-primary)">Data início: </span
							>{{ voucher.validFrom | date: 'dd/MM/yyyy' }}
						</p>
						<p>
							<span style="color: var(--content-primary)">Data final: </span
							>{{ voucher.validFrom | date: 'dd/MM/yyyy' }}
						</p>
						<p>
							<span style="color: var(--content-primary)">Valor: </span
							>{{ voucher.value | currency: 'BRL' }}
						</p>
						<button class="cancel-button" *ngIf="voucher.status === 'active'">
							Cancelar voucher
						</button>
					</div>
					<div class="status-container">
						<div class="status-badge">{{ getStatusName(voucher.status) }}</div>
					</div>
				</div>
			</mat-card>
		</div>
	</div>
</div>

<!-- Ajustar lógica para status de requisição IDLE, PENDING, ERROR, SUCCESS -->
@if (dataSource.data.length === 0) {
	<div class="not-found-card">
		<mat-icon svgIcon="pm-icons:close-circle"></mat-icon>
		<h2>Nenhum benefício encontrado.</h2>
		<p>Revise as informações fornecidas na busca.</p>
	</div>
}
