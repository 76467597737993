import { ContentChild, Input } from '@angular/core'
import { Component } from '@angular/core'
import { AccordionContentDirective } from './directives/accordion-content.directive'
import { AccordionHeaderDirective } from './directives/accordion-header.directive'
import { AccordionTitleDirective } from './directives/accordion-title.directive'

@Component({ selector: 'ui-common-accordion-item', template: `` })
export class AccordionItemComponent {
	@Input() title = ''
	@Input() disabled = false
	@Input() expanded = false
	@ContentChild(AccordionContentDirective) content!: AccordionContentDirective
	@ContentChild(AccordionTitleDirective) customTitle!: AccordionContentDirective
	@ContentChild(AccordionHeaderDirective) customHeader!: AccordionHeaderDirective
}
