import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { Router } from '@angular/router'
import { BottomSheetHeaderComponent, FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	imports: [CommonModule, BottomSheetHeaderComponent, FooterButtonsComponent, MatButtonModule],
	selector: 'feature-pm-checkin-disabled',
	template: `
		<ui-pm-bottom-sheet-header title="Check-in não habilitado"></ui-pm-bottom-sheet-header>

		<p style="padding-left: 20px; padding-right: 20px">
			Este restaurante não aceita check-in, mas você pode fazer uma reserva para este restaurante.
		</p>

		<ui-pm-footer-buttons>
			<button mat-raised-button color="primary" (click)="reserve()">Reservar</button>
		</ui-pm-footer-buttons>
	`,
})
export class CheckinDisabledComponent {
	private router = inject(Router)
	private venueId: string = inject(MAT_BOTTOM_SHEET_DATA)
	public bottomSheetRef = inject(MatBottomSheetRef)

	reserve() {
		this.bottomSheetRef.dismiss()
		this.router.navigate(['restaurante', this.venueId, 'reserva'])
	}
}
