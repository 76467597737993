import { Component } from '@angular/core'
import { LoginConciergeComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	selector: 'pm-login-concierge-page',
	imports: [LoginConciergeComponent],
	template: '<feature-pm-login-concierge/>',
})
export class LoginConciergePageComponent {}
