<ui-pm-bottom-sheet-header title="Filtros" />

@if ((statusCuisines$ | async) === 'pending') {
	<ui-pm-loading-page></ui-pm-loading-page>
} @else {
	<div style="height: 50vh; overflow-y: auto">
		<p style="font-size: 18px; margin-bottom: 20px; padding: 0 16px">Categoria</p>
		<div class="buttons-group">
			<button
				mat-stroked-button
				*ngFor="let category of categories"
				(click)="filterState.setCategory(category)"
				[color]="filterState.includesCategory(category) ? 'primary' : ''"
			>
				{{ category }}
			</button>
			<button
				mat-stroked-button
				[color]="michelinGuide().selected ? 'primary' : ''"
				(click)="filterState.toggleMichilenGuide()"
			>
				{{ michelinGuide().name }}
			</button>
			<button
				mat-stroked-button
				[color]="newRestaurant().selected ? 'primary' : ''"
				(click)="filterState.toggleNewRestaurante()"
			>
				{{ newRestaurant().name }}
			</button>
		</div>
		<p style="font-size: 18px; margin: 20px 0; padding: 0 16px">Tipo de culinária</p>
		<div class="buttons-group">
			<button
				mat-stroked-button
				*ngFor="let cuisine of cuisines$ | async"
				(click)="filterState.setCuisine(cuisine)"
				[color]="filterState.includesCuisine(cuisine) ? 'primary' : ''"
			>
				{{ cuisine.name }}
			</button>
		</div>
	</div>
	<ui-pm-footer-buttons>
		<button mat-raised-button (click)="cleanFilter()">Limpar filtros</button>
		<button mat-raised-button color="primary" (click)="applyFilter()">Filtrar</button>
	</ui-pm-footer-buttons>
}
