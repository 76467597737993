import { createReducer, on } from '@ngrx/store'
import { CuisinesState, DEFAULT_CUISINE_TEXT, initialStateCusisine } from './cuisine.state'
import { VenueActions } from '../venue/venue.actions'
import { CuisineActions } from './cuisine.actions'

export const cuisinesReducer = createReducer<CuisinesState>(
	initialStateCusisine,
	on(CuisineActions.setCurrentCuisine, (state, action): CuisinesState => {
		return {
			...state,
			selectedCuisines: action.cuisines,
		}
	}),

	on(CuisineActions.loadCuisines, (state): CuisinesState => {
		return {
			...state,
			status: 'pending',
		}
	}),
	on(CuisineActions.loadCuisinesSuccess, (state, action): CuisinesState => {
		return {
			...state,
			cuisines: action.cuisines?.length ? action.cuisines : null,
			status: 'success',
			error: '',
		}
	}),
	on(CuisineActions.loadCuisinesFailure, (state, action): CuisinesState => {
		return {
			...state,
			cuisines: null,
			error: action.error,
			status: 'failure',
		}
	}),
	on(VenueActions.cleanFilter, (state): CuisinesState => {
		return {
			...state,
			selectedCuisines: [{ _id: '', name: DEFAULT_CUISINE_TEXT }],
		}
	}),
	on(CuisineActions.cleanCuisines, (state): CuisinesState => {
		return {
			...state,
			selectedCuisines: [{ _id: '', name: DEFAULT_CUISINE_TEXT }],
		}
	})
)
