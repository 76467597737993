import { CheckinDate } from '../../models/vo/checkin-date'
import { Status } from '../helper.state'
import { AvailabilityCheckin } from './checkin'

export const featureCheckinName = 'checkin'

export interface CheckInState {
	availabilities: AvailabilityCheckin | null
	statusAvailabilities: Status | 'unavailable'
	errorAvailabilities: string | null
	orderId: string | null
	checkinDate: CheckinDate | null
	statusConfirm: Status
	errorConfirm: string | null
	voucherValue: string | null // voucherValue after confirm Checkin
}

export const initialStateCheckin: CheckInState = {
	availabilities: null,
	statusAvailabilities: 'idle',
	errorAvailabilities: null,
	orderId: null,
	checkinDate: null,
	statusConfirm: 'idle',
	errorConfirm: null,
	voucherValue: null,
}
