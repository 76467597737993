import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatDividerModule } from '@angular/material/divider'
import { Order } from '../../control-panel.service'
import { FormatPartySize } from '@monorepo-channels/shared/util-helpers'
import { NgxMaskPipe } from 'ngx-mask'
import { environment } from '@monorepo-channels/shared/util-environments'

@Component({
	standalone: true,
	selector: 'feature-pm-checkins-tab-content',
	templateUrl: './tab-checkins.component.html',
	styleUrls: ['./tab-checkins.component.scss'],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatCardModule,
		MatDividerModule,
		FormatPartySize,
		NgxMaskPipe,
	],
})
export class TabCheckinsComponent {
	@Input() isLoadingCheckins!: boolean
	@Input() checkins!: Order[]
	env = environment

	// Método para formatar os benefícios
	getFormattedBenefits(checkin: Order): string {
		return checkin.benefits?.map(benefit => benefit.name).join(', ') || 'Nenhum benefício'
	}

	getStatusName(status: string): string {
		if (status === 'OPEN') return 'Aguardando'
		if (status === 'CHK_DONE') return 'Confirmado'
		if (status === 'CHK_CANCEL') return 'Cancelado'
		if (status === 'VALIDATED') return 'Validado'
		return 'Desconhecido'
	}
}
