import { createReducer, on } from '@ngrx/store'
import { CheckInState, initialStateCheckin } from './checkin.state'
import { CheckinActions } from './checkin.actions'

export const checkinReducer = createReducer<CheckInState>(
	initialStateCheckin,
	on(CheckinActions.loadAvailabilities, (state): CheckInState => {
		return {
			...state,
			statusAvailabilities: 'pending',
		}
	}),
	on(CheckinActions.loadAvailabilitiesSuccess, (state, action): CheckInState => {
		return {
			...state,
			availabilities: action.availabilities,
			statusAvailabilities: 'success',
			errorAvailabilities: null,
		}
	}),
	on(CheckinActions.loadAvailabilitiesFail, (state, action): CheckInState => {
		return {
			...state,
			errorAvailabilities: action.error,
			statusAvailabilities: 'failure',
		}
	}),
	on(CheckinActions.updateAvailabilities, (state, action): CheckInState => {
		return {
			...state,
			availabilities: action.availabilities,
		}
	}),
	on(CheckinActions.setOrderIdAfterLoadingAvailabilities, (state, action): CheckInState => {
		return {
			...state,
			orderId: action.orderId,
		}
	}),
	on(CheckinActions.checkInConfirmation, (state, action): CheckInState => {
		return {
			...state,
			checkinDate: action.checkInDate,
			statusConfirm: 'pending',
			errorConfirm: null,
		}
	}),
	on(CheckinActions.checkInConfirmSuccess, (state): CheckInState => {
		return {
			...state,
			errorConfirm: null,
			statusConfirm: 'success',
		}
	}),
	on(CheckinActions.checkInConfirmFailure, (state, action): CheckInState => {
		return {
			...state,
			errorConfirm: action.error,
			statusConfirm: 'failure',
		}
	}),
	on(CheckinActions.checkInAvailabilitiesUnavailable, (state, action): CheckInState => {
		return {
			...state,
			statusAvailabilities: 'unavailable',
			errorAvailabilities: action.error,
			availabilities: action.availabilities,
		}
	}),
	on(CheckinActions.setVoucherValueAfterCheckInConfirm, (state, action): CheckInState => {
		return {
			...state,
			voucherValue: action.voucherValue,
		}
	}),
	on(CheckinActions.checkinReset, (): CheckInState => {
		return { ...initialStateCheckin }
	})
)
