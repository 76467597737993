// config
export * from './lib/config/ngx-currency.provider'
// functions
export * from './lib/functions/capitalize-each-word'
export * from './lib/functions/format-date'
export * from './lib/functions/remove-accents'
export * from './lib/functions/round2decimals'
export * from './lib/functions/is-mobile'
export * from './lib/functions/new-restaurant'
export * from './lib/functions/normalize-email-phone'

//directives
export * from './lib/directives/clickable-tooltip.directive'

// services
export * from './lib/services/local-storage.service'
export * from './lib/services/cep.service'
export * from './lib/services/geo-location.service'
export * from './lib/services/pcke.service'
export * from './lib/services/campaign.service'
export * from './lib/services/body-observer.service'
export * from './lib/services/suggest-restaurant.service'

// misc
export * from './lib/misc/partners.util'
export * from './lib/services/gtm/data-layer.service'
export * from './lib/services/icons.provider'

// pipes
export * from './lib/pipes/format-date-hrs.pipe'
export * from './lib/pipes/format-date-weekday.pipe'
export * from './lib/pipes/format-partysize.pipe'
export * from './lib/pipes/capitalize-first.pipe'

// validators
export * from './lib/validators/password'
export * from './lib/validators/name'
export * from './lib/validators/cpf'
