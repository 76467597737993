import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { Router } from '@angular/router'
import { BottomSheetHeaderComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	imports: [CommonModule, BottomSheetHeaderComponent],
	selector: 'feature-pm-reservation-disabled',
	template: `
		<ui-pm-bottom-sheet-header title="Reserva indisponível"></ui-pm-bottom-sheet-header>
		<p style="padding-left: 20px; padding-right: 20px">Esse restaurante ainda não realiza reservas.</p>
		<div style="height:136px "></div>
	`,
})
export class ReservationDisabledComponent {
	private router = inject(Router)

	reserve() {
		this.router.navigate(['reserva'])
	}
}
