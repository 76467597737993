import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router'
import { ConciergeAuthService } from './admin-auth.service'

export const AdminAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const router = inject(Router)
	const authService = inject(ConciergeAuthService)
	authService.redirectURL = state.url
	const isAuthenticated = (): boolean => !!authService.getToken()

	return isAuthenticated() ? true : router.createUrlTree(['/concierge-teste'])
}

export const GuestAuthGuard: CanActivateFn = () => {
	const router = inject(Router)
	const authService = inject(ConciergeAuthService)
	const isAuthenticated = (): boolean => !!authService.getToken()

	return isAuthenticated() ? router.createUrlTree(['/concierge/painel-de-controle']) : true
}
