import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { Router } from '@angular/router'
import { VenueDetail } from '@monorepo-channels/channels/domain'
import { BottomSheetHeaderComponent, FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	imports: [CommonModule, BottomSheetHeaderComponent, FooterButtonsComponent, MatButtonModule],
	selector: 'feature-pm-checkin-unavailable',
	template: `
		<ui-pm-bottom-sheet-header title="Check-in indisponível"></ui-pm-bottom-sheet-header>

		<section>
			<p style="padding-right: 20px; padding-left: 20px;">O check-in não está habilitado no momento.</p>
			<div style="padding: 12px 20px; margin: 16px 0; background-color: var(--card-background)">
				<p style="font-weight: 480; margin-bottom:10px;">Horário de check-in:</p>
				<p>De {{ checkin.time?.startTime }} às {{ checkin.time?.endTime }}</p>
			</div>
			<p style="padding-left: 20px; padding-right: 20px;">
				mas você pode fazser uma reserva para este restaurante.
			</p>
		</section>

		<ui-pm-footer-buttons>
			<button mat-raised-button color="primary" (click)="reserve()">Reservar</button>
		</ui-pm-footer-buttons>
	`,
})
export class CheckinUnavailableComponent {
	private router = inject(Router)
	private data: { checkin: VenueDetail['checkin']; venueId: string } = inject(MAT_BOTTOM_SHEET_DATA)

	public bottomSheetRef = inject(MatBottomSheetRef)
	public checkin = this.data.checkin
	public venueId = this.data.venueId

	reserve() {
		this.bottomSheetRef.dismiss()
		this.router.navigate(['restaurante', this.venueId, 'reserva'])
	}
}
