<div class="search-input">
	<mat-icon
		class="search-icon"
		svgIcon="pm-icons:search"
		style="width: 14px; height: 14px; cursor: pointer"
		(click)="searchInput.focus()"
	></mat-icon>
	<input type="text" placeholder="Faça sua busca" [formControl]="searchControl" #searchInput />
</div>

<div style="height: 16px"></div>

<div class="filter-container-desktop">
	<article class="filter-container">
		<div class="filter-container__item">
			<button
				class="filter-selector"
				[class.selected]="selectedAddress$ | async"
				(click)="openBottomSheetCities()"
			>
				@if (selectedAddress$ | async; as selectedAddress) {
					<span>{{ selectedAddress.city }} - {{ selectedAddress.state }}</span>
				} @else {
					<span>Cidades</span>
				}
				<mat-icon svgIcon="pm-icons:bottom-arrow" style="width: 16px; height: 16px"></mat-icon>
			</button>

			<div
				*ngIf="selectedAddress$ | async"
				style="
					display: flex;
					align-items: center;
					gap: 5px;
					margin-top: 8px;
					margin-left: 5px;
					cursor: pointer;
				"
				(click)="cleanFilterAddress()"
			>
				<span style="font-size: 10px; font-weight: 360; text-decoration: underline" class="selected">
					{{ (selectedNeighborhood$ | async) || 'Todos' }}
				</span>
				<mat-icon svgIcon="pm-icons:close-circle" style="width: 14px; height: 14px"></mat-icon>
			</div>
		</div>

		<div class="filter-container__item">
			<button class="filter-selector" (click)="openBottomSheetCategoriesCusiines()">
				<span>Filtrar</span>
				<div class="filterSelected" *ngIf="filterCount() !== 0">{{ filterCount() }}</div>
				<mat-icon
					svgIcon="pm-icons:bottom-arrow"
					*ngIf="filterCount() === 0"
					style="width: 16px; height: 16px"
				></mat-icon>
			</button>
		</div>
	</article>
</div>
