<main class="container" *ngIf="client$ | async as client">
	<p class="title">Carteira</p>

	<ul class="card-list">
		@for (category of client.categories; track category.bin; let idx = $index) {
			<li class="card-list__item">
				<div
					class="card-list__moving"
					[ngClass]="{ click: selectedCard() === idx }"
					(click)="selectCard(idx)"
				>
					<mat-icon svgIcon="pm-icons:wallet" style="margin-right: 18px" />
					<div style="display: flex; flex-direction: column">
						<p class="card-list__name">{{ category.name }}</p>
						<p style="display: flex; align-items: center" *ngIf="category.lastFourDigits">
							<mat-icon svgIcon="pm-icons:combined-shape" style="width: 40px" />
							<span class="card-list__number">{{ category.lastFourDigits }}</span>
						</p>
						<p
							style="display: flex; align-items: center"
							*ngIf="!category.lastFourDigits && category.bin"
						>
							<span class="card-list__number">{{ category.bin }}</span>
						</p>
					</div>
					<mat-icon
						svgIcon="pm-icons:right-arrow-grey"
						style="width: 11px; height: 12px; margin-left: auto"
					/>
				</div>
				<ul class="icon-container">
					<li class="icon-item" *ngIf="!category.gatewayId" (click)="openRegisterCard(category)">
						<mat-icon svgIcon="pm-icons:edit-card" />
					</li>
					<li class="icon-item" (click)="removeCard(category)">
						<mat-icon svgIcon="pm-icons:trash" />
					</li>
				</ul>
			</li>
		} @empty {
			<mat-icon svgIcon="pm-icons:no-cards" style="width: 37px; height: 32px" />
			<p style="font-size: 16px; font-weight: 600; color: var(--content-txt)">
				Nenhum cartão cadastrado
			</p>
			<p style="font-size: 14px; font-weight: 360; color: var(--content-txt)">
				Você ainda não tem nenhum cartão cadastrado. Cadastre seu cartão e aproveite os benefícios.
			</p>
		}
		<button mat-raised-button (click)="openRegisterCard()" style="max-width: 334px; font-size: 12px">
			+ Adicionar cartão
		</button>
	</ul>
</main>
