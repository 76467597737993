import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { BottomSheetHeaderComponent, FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	imports: [CommonModule, BottomSheetHeaderComponent, FooterButtonsComponent, MatButtonModule],
	selector: 'feature-pm-card-failure',
	template: `
		<ui-pm-bottom-sheet-header title="Erro ao cadastrar cartão"></ui-pm-bottom-sheet-header>

		<p style="padding-left: 20px; padding-right: 20px">
			{{ data.error }}
		</p>

		<ui-pm-footer-buttons>
			<button mat-raised-button (click)="bottomSheetRef.dismiss()">Tentar novamente</button>
		</ui-pm-footer-buttons>
	`,
})
export class CardFailureComponent {
	public bottomSheetRef = inject(MatBottomSheetRef)
	public data = inject(MAT_BOTTOM_SHEET_DATA)
}
