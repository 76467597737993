import { VenueDetail } from '../../models/venues/venues-details'
import { VenueFilter } from '../../models/venues/venues-filter'
import { Status } from '../helper.state'

export const featureVenueName = 'venue'

export interface VenueState {
	venues: VenueFilter[] | null
	filterVenues: VenueFilter[] | null
	favoritesVenues: VenueFilter[] | null
	statusGetVenues: Status
	errorGetVenues: string | null
	statusFavoritesVenues: Status
	errorFavoritesVenues: string | null
	allLoaded: boolean
	selectedVenue: VenueDetail | null
	statusSelectedVenue: Status
	erroGetVenue: string | null
	statusLoadingMoreVenues: Status
	errorLoadingMoreVenues: string | null
	location: { latitude: number; longitude: number; maxDistance: number } | null
	name: string | undefined
	categories: string[]
	michelinGuide?: boolean
	newRestaurant?: Date
}

export const initialStateVenue: VenueState = {
	venues: null,
	filterVenues: null,
	favoritesVenues: null,
	statusGetVenues: 'idle',
	errorGetVenues: null,
	statusFavoritesVenues: 'idle',
	errorFavoritesVenues: null,
	selectedVenue: null,
	allLoaded: false,
	statusSelectedVenue: 'idle',
	erroGetVenue: null,
	statusLoadingMoreVenues: 'idle',
	errorLoadingMoreVenues: null,
	location: null,
	name: undefined,
	categories: [],
	michelinGuide: undefined,
	newRestaurant: undefined,
}
