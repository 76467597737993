import { environment } from '@monorepo-channels/shared/util-environments'

export class GoogleMapsLink {
	mapsLink: string
	mapsMobile: string
	mapsDesk: string
	constructor(lat: number, long: number) {
		const latLong = `${long || 0}, ${lat || 0}`
		this.mapsMobile = `https://maps.googleapis.com/maps/api/staticmap?markers=color:red|${latLong}&center=${latLong}&zoom=18&key=${environment.googleMapsAPIToken}&size=343x205`
		this.mapsDesk = `https://maps.googleapis.com/maps/api/staticmap?markers=color:red|${latLong}&center=${latLong}&zoom=18&key=${environment.googleMapsAPIToken}&size=762x446`
		this.mapsLink = `https://www.google.com/maps?q=${latLong}`
	}
}

export class LivemenuLink {
	liveMenuURL: string
	constructor(id: string, alternateMenuId: string | undefined) {
		this.liveMenuURL = alternateMenuId
			? `${environment.liveMenu.url}/${alternateMenuId}?origin=channels`
			: `${environment.liveMenu.url}/${id}?origin=channels`
	}
}
