import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { environment } from '@monorepo-channels/shared/util-environments'
import { Observable } from 'rxjs'

export interface LoginConciergeDto {
	username: string
	password: string
	partnerId: string
}

interface AccessToken {
	token: string
	createdAt: string
}

export interface LoginResponse {
	_id: string
	email: string
	username: string
	name: string
	lastName: string
	role: string
	access_token: AccessToken
}

@Injectable({ providedIn: 'root' })
export class LoginConciergeService {
	private http = inject(HttpClient)
	private baseURL = `${environment.channelsAPIURL}/api/v1/auth/concierge`

	login(dto: LoginConciergeDto): Observable<LoginResponse> {
		return this.http.post<LoginResponse>(this.baseURL, dto)
	}
}
