<main class="container">
	<section class="title-container">
		<h1 class="title">Concierge</h1>
		<p class="text">Painel de controle</p>
	</section>
	<section class="tab-container">
		<!-- Tab buttons -->
		<div class="tab-buttons">
			<button [class.active]="selectedTab() === 'Clientes'" (click)="selectTab('Clientes')">
				Clientes
			</button>
			<button [class.active]="selectedTab() === 'Reservas'" (click)="selectTab('Reservas')">
				Reservas
			</button>
			<button [class.active]="selectedTab() === 'Checkins'" (click)="selectTab('Checkins')">
				Check-ins
			</button>
			<button [class.active]="selectedTab() === 'Celebrations'" (click)="selectTab('Celebrations')">
				Benefício Celebrations
			</button>
		</div>

		<!-- Search input -->
		<div class="search-input">
			<mat-icon
				class="search-icon"
				svgIcon="pm-icons:search"
				style="width: 14px; height: 14px; cursor: pointer"
			></mat-icon>
			<input
				type="text"
				placeholder="Buscar por CPF"
				[formControl]="searchControl"
				#searchInput
				mask="000.000.000-00"
			/>
		</div>

		<!-- Clientes TAB -->
		<div class="tab-content" *ngIf="selectedTab() === 'Clientes'">
			@if (isLoadingLogin === true) {
				<ui-pm-loading-page style="--height: 100px" />
			} @else {
				<feature-pm-clients-tab-content
					[isLoadingLogin]="isLoadingLogin"
					[dataSource]="dataSource"
					[displayedColumns]="displayedColumns"
				></feature-pm-clients-tab-content>
			}
		</div>

		<!-- Reservas TAB -->
		<div class="tab-content" *ngIf="selectedTab() === 'Reservas'">
			@if (isLoadingLogin === true) {
				<ui-pm-loading-page style="--height: 100px" />
			} @else {
				<feature-pm-reservations-tab-content
					[isLoadingReservations]="isLoadingLogin"
					[reservations]="reservations"
				></feature-pm-reservations-tab-content>
			}
		</div>

		<!-- Checkins TAB -->
		<div class="tab-content" *ngIf="selectedTab() === 'Checkins'">
			@if (isLoadingLogin === true) {
				<ui-pm-loading-page style="--height: 100px" />
			} @else {
				<feature-pm-checkins-tab-content
					[checkins]="checkins"
					[isLoadingCheckins]="isLoadingLogin"
				></feature-pm-checkins-tab-content>
			}
		</div>
		<!-- Benefits Celebrations TAB -->
		<div class="tab-content" *ngIf="selectedTab() === 'Celebrations'">
			@if (isLoadingLogin === true) {
				<ui-pm-loading-page style="--height: 100px" />
			} @else {
				<feature-pm-benefits-celebrations-tab-content
					[dataSource]="dataSource"
					[vouchers]="vouchers"
					[displayedColumns]="displayedCelebrationsColumns"
					[isLoadingLogin]="isLoadingLogin"
				>
				</feature-pm-benefits-celebrations-tab-content>
			}
		</div>
	</section>
</main>
