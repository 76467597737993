import { Component } from '@angular/core'
import { OrderDetailsComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	selector: 'pm-order-details-page',
	imports: [OrderDetailsComponent],
	template: '<feature-pm-order-details/>',
})
export class OrdersDetailsPageComponent {}
