import { NgIf } from '@angular/common'
import { Component, Input, Output, EventEmitter } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

@Component({
	selector: 'ui-pm-user-profile',
	imports: [MatIconModule, NgIf],
	standalone: true,
	template: `
		<div class="user-profile">
			<mat-icon svgIcon="pm-icons:profile-circle" class="user-icon"> account_circle </mat-icon>
			<div class="user-info">
				<span>{{ fullName }}</span>
				<a *ngIf="editProfile.observers.length > 0" class="edit-profile" (click)="editProfile.emit()">
					Editar perfil
				</a>
			</div>
			<mat-icon
				*ngIf="editIcon.observers.length > 0"
				svgIcon="pm-icons:edit-card"
				class="edit-icon"
				(click)="editIcon.emit()"
			>
				account_circle
			</mat-icon>
		</div>
	`,
	styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
	@Input() fullName!: string
	@Output() editProfile = new EventEmitter<void>()
	@Output() editIcon = new EventEmitter<void>()
}
