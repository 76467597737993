import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { RouterLink } from '@angular/router'
import { FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	selector: 'pm-about-tagme-page',
	imports: [FooterButtonsComponent, MatButtonModule, RouterLink, CommonModule],
	styles: `
        @import 'mixin';
        body.all {
            .sub-title {
                --content-primary: white;
            }
        }

        .title {
            font-size: 24px;
            font-weight: 600;
            color: var(--content-txt);
            margin-top: 34px;
            margin-bottom: 44px;
        }

        .sub-title {
            font-size: 16px;
            font-weight: 480;
            color: var(--content-primary);
            margin-bottom: 32px;
        }

        .content {
            font-size: 14px;
            font-weight: 400;
            color: var(--content-txt);
            line-height: 24px
        }

        .container {
            padding: 0 20px;
            @include tablet-up {
                max-width: var(--max-width-tablet);
                margin: 0 auto;
            }
        }
    `,
	template: `
		<main class="container">
			<h1 class="title">Sobre a Tagme</h1>

			<h2 class="sub-title">Uma nova perspectiva de eficiência no segmento de restaurantes.</h2>

			<p class="content">
				Atuando desde 2009, a Tagme oferece canais e soluções de reserva, lista de espera, menu
				digital e plataformas de marketing para restaurantes, com o objetivo de criar experiências
				personalizadas do cliente.
			</p>

			<div style="height: 120px"></div>
			<ui-pm-footer-buttons type="fixed-order">
				<button mat-raised-button routerLink="/">Ver restaurantes</button>
			</ui-pm-footer-buttons>
		</main>
	`,
})
export class AboutTagmePageComponent {}
