export const environment = {
	production: false,
	env: 'development',
	channelsAPIURL: 'https://channelsapi-dev.tagme.com.br',
	livelo: {
		auth: 'https://acesso-uat.livelo.com.br',
		clientId: 'tagme-bbgastronomia',
		redirectUri: 'https://bb-gastronomia-dev.tagme.com.br',
	},
	giftAPIURL: 'https://giftcardtagme-dev.azurewebsites.net',
	pubImageURL: 'https://tagmepub.azureedge.net/pubimg',
	googleMapsAPIToken: 'AIzaSyAZ-N7OLjJFVViV6m4Rk5BV7eeuw97DJx8',
	liveMenu: {
		url: 'https://live-menu-staging.tagme.com.br/menu',
	},
	conciergeURL: 'https://wonderful-meadow-0e30ae20f.1.azurestaticapps.net/concierge',
	coverUrl:
		'https://tagmeimages.azureedge.net/?q=90&output=webp&url=https://tagmepub.azureedge.net/pubimg/',
}
