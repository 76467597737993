import { Component } from '@angular/core'
import { EditProfileComponent } from '@monorepo-channels/feature-pm'

@Component({
	selector: 'pm-edit-profile',
	standalone: true,
	imports: [EditProfileComponent],
	template: `
		<main class="container">
			<feature-pm-edit-profile />
		</main>
	`,
	styles: `
	@import 'mixin';
	.container {
		padding: 0 20px;
		@include tablet-up {
			max-width: var(--max-width-tablet);
			margin: 0 auto;
		}
	}
	`,
})
export class EditProfilePageComponent {}
