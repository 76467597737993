import { Component, inject, OnInit, signal } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { CommonModule } from '@angular/common'
import { Store } from '@ngrx/store'
import {
	getOrders,
	loadingOrderHistory,
	OrderHistory,
	OrderHistoryActions,
} from '@monorepo-channels/channels/domain'
import { trigger, transition, style, animate } from '@angular/animations'
import { combineLatest, map } from 'rxjs'
import { LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { CancelOrderComponent } from '../simple-bottom-sheets/cancel-order.component'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
@Component({
	selector: 'feature-pm-order-history',
	standalone: true,
	imports: [CommonModule, MatIconModule, MatButtonModule, LoadingPageComponent, RouterLink],
	templateUrl: './order-history.component.html',
	styleUrls: ['./order-history.component.scss'],
	animations: [
		trigger('slideIn', [
			transition(':enter', [
				style({ transform: 'translateX(100%)', opacity: 0 }),
				animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 })),
			]),
			transition(':leave', [
				animate('100ms ease-in', style({ transform: 'translateX(-100%)', opacity: 0 })),
			]),
		]),
	],
})
export class OrderHistoryComponent implements OnInit {
	private store = inject(Store)
	private orders$ = this.store.select(getOrders)
	private categorizedOrders$ = this.orders$.pipe(
		map(orders => ({
			activeOrders: orders?.filter(order => order.active) ?? [],
			inactiveOrders: orders?.filter(order => !order.active) ?? [],
		}))
	)
	private router = inject(Router)
	private route = inject(ActivatedRoute)
	public loadingHistory$ = this.store.select(loadingOrderHistory)
	public selectedTab = signal('Ativos')
	public bottomSheet = inject(MatBottomSheet)

	public vm$ = combineLatest([this.categorizedOrders$, this.loadingHistory$]).pipe(
		map(([orders, loading]) => ({
			actriveOrders: orders.activeOrders,
			inactiveOrders: orders.inactiveOrders,
			loading,
		}))
	)

	ngOnInit(): void {
		this.store.dispatch(OrderHistoryActions.loadOrders())
	}

	selectTab(tab: string): void {
		this.selectedTab.set(tab)
	}

	cancelOrder(order: OrderHistory | null) {
		if (!order) return
		this.bottomSheet.open(CancelOrderComponent, { data: order })
	}

	selectOrder(orderId: string): void {
		this.store.dispatch(OrderHistoryActions.setSelectedOrderId({ orderId }))
		this.router.navigate(['pedido', orderId], { relativeTo: this.route })
	}
}
