import { Injectable, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { fromEvent, BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { isAllLoaded, loadingMoreVenues } from './venue.selectors'

@Injectable({ providedIn: 'root' })
export class InfiniteScrollService {
	private store = inject(Store)
	private targetElement$ = new BehaviorSubject<HTMLElement | Document>(document)

	// Observable que emite eventos de scroll para o elemento alvo
	scrollAction$: Observable<Event> = this.targetElement$.pipe(switchMap(el => fromEvent(el, 'scroll')))

	isAllLoaded$: Observable<boolean> = this.store.select(isAllLoaded)
	isLoadingMoreVenues$ = this.store.select(loadingMoreVenues)

	loadMoreVenue$: Observable<boolean> = combineLatest([
		this.scrollAction$,
		this.isAllLoaded$,
		this.isLoadingMoreVenues$,
	]).pipe(
		map(([, allLoaded, isLoadingMoreVenues]) => {
			const el =
				this.targetElement$.value instanceof Document
					? document.documentElement
					: (this.targetElement$.value as HTMLElement)

			if (!allLoaded && !isLoadingMoreVenues) {
				return el.scrollTop >= 0.8 * el.scrollHeight - el.clientHeight
			}
			return false
		})
	)

	setScrollTarget(element: HTMLElement | Document) {
		this.targetElement$.next(element)
	}
}
