import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { isLoggedIn } from '../state/client/client.selectors'
import { map } from 'rxjs'
import { RedirectToken } from '../tokens/tokens'
import { AuthService } from './auth.service'

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const store = inject(Store)
	const router = inject(Router)
	const redirectLoginPage = inject(RedirectToken)
	inject(AuthService).redirectURL = state.url
	return store.select(isLoggedIn).pipe(
		map(value => {
			if (!value) {
				return router.createUrlTree([redirectLoginPage])
			}
			return true
		})
	)
}
