import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { Client, Voucher } from '../../control-panel.service'
import { NgxMaskPipe } from 'ngx-mask'
import { MatDividerModule } from '@angular/material/divider'
import { Category } from '@monorepo-channels/channels/domain'

@Component({
	standalone: true,
	selector: 'feature-pm-benefits-celebrations-tab-content',
	templateUrl: './tab-benefits-celebrations.component.html',
	styleUrls: ['./tab-benefits-celebrations.component.scss'],
	imports: [
		MatTableModule,
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatCardModule,
		MatDividerModule,
		NgxMaskPipe,
	],
})
export class TabBenefitsCelebrationsComponent {
	@Input() isLoadingLogin!: boolean
	@Input() dataSource!: MatTableDataSource<Client>
	@Input() vouchers!: Voucher[]
	@Input() displayedColumns!: string[]

	currentIndex: number = 0
	itemsPerPage: number = 3

	// Retorna os vouchers visíveis de acordo com o índice atual
	get visibleVouchers(): Voucher[] {
		return this.vouchers.slice(this.currentIndex, this.currentIndex + this.itemsPerPage)
	}

	handlePrevious(): void {
		if (this.currentIndex > 0) this.currentIndex--
	}

	handleNext(): void {
		if (this.currentIndex < this.vouchers.length - this.itemsPerPage) this.currentIndex++
	}

	isFullRegistration(client: Client): string {
		if (client.name && client.phone && client.email && client.password && client.categories.length > 0) {
			return 'Completo'
		}
		return 'Incompleto'
	}

	isCardComplete(category: Category): string {
		return category.gatewayId !== '' ? 'Completo' : 'Incompleto'
	}

	getClientName(clientId: string): string {
		const clientArray = Array.isArray(this.dataSource) ? this.dataSource : this.dataSource.data
		const client = clientArray.find(c => c._id === clientId)
		return client ? client.name + ' ' + client.lastName : 'Cliente não encontrado'
	}

	getStatusName(status: string): string {
		if (status === 'active') return 'Ativo'
		if (status === 'expired') return 'Expirado'
		return 'Desconhecido'
	}
}
