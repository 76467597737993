import { Component, inject } from '@angular/core'
import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router'
import {
	ClientActions,
	getClient,
	getFavoritesVenuesState,
	isLoggedIn,
	VenueFilter,
} from '@monorepo-channels/channels/domain'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { combineLatest, filter, map } from 'rxjs'
import { VenueActions } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'

import { DataLayerService } from '@monorepo-channels/shared/util-helpers'
import { LoadingPageComponent, VenueCardUiComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	selector: 'pm-favorites',
	standalone: true,
	imports: [NgIf, NgFor, AsyncPipe, VenueCardUiComponent, LoadingPageComponent],
	styles: `
	@import 'mixin';
	.venue-list {
		display: grid;
		grid-template-columns: 1fr;
		gap: 24px;
		@include laptop-up {
			grid-template-columns: repeat(3, 1fr);
			margin: 0 auto;
			gap: 32px;
			max-width: var(--container-desktop);
		}
	}

	.title-container {
		max-width: 343px;
		margin: 32px auto;
		@include laptop-up {
			max-width: 1090px;
		}
	}
	.title {
		font-size: 24px;
		font-weight: 600;
		color: var(--content-txt);
	}
	`,
	template: `
		@if (vm$ | async; as vm) {
			@if (vm.favoritesState.status === 'pending') {
				<div style="padding: 58px 0;">
					<ui-pm-loading-page />
				</div>
			} @else {
				<div class="title-container">
					<h1 class="title">Favoritos</h1>
				</div>
				<main style=" display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
					<div class="venue-list">
						<ui-pm-venue-card
							*ngFor="let venue of vm.favoritesState.favoritesVenues"
							[venue]="venue"
							[celebrations]="vm.client?.voucher?.id ? true : false"
							[client]="vm.client"
							(clickCard)="clickCard(venue)"
							(clickFavorite)="toggleFavorite($event)"
						/>
					</div>
				</main>
			}
		}
	`,
})
export class FavoritesPageComponent {
	private store = inject(Store)
	public router = inject(Router)
	public activatedRoute = inject(ActivatedRoute)
	public dataLayerService = inject(DataLayerService)
	public client = this.store.select(getClient)
	private isLoggedIn = this.store.select(isLoggedIn)

	favoritesState$ = this.store.select(getFavoritesVenuesState)
	vm$ = combineLatest([this.store.select(getFavoritesVenuesState), this.store.select(getClient)]).pipe(
		map(([favoritesState, client]) => ({ favoritesState, client }))
	)

	constructor() {
		this.isLoggedIn
			.pipe(filter(Boolean))
			.pipe(takeUntilDestroyed())
			.subscribe(() => {
				this.store.dispatch(VenueActions.filterVenuesByFavorites())
			})
	}

	clickCard(venue: VenueFilter) {
		this.dataLayerService.logEvent({
			event: 'CH_venue_card_click',
			venueId: venue.id,
			venueName: venue.name,
		})
		this.router.navigate(['/restaurante', venue.id])
	}

	toggleFavorite(venueId: string) {
		this.store.dispatch(ClientActions.toggleFavorite({ venueId }))
	}
}
