import { Component } from '@angular/core'
import { CheckinComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	selector: 'pm-checkin-page',
	imports: [CheckinComponent],
	template: '<feature-pm-checkin></feature-pm-checkin>',
})
export class CheckinPageComponent {}
