<mat-sidenav-container class="sidenav-container">
	<mat-sidenav #sidenav mode="over" class="sidenav">
		<div class="menu-header">
			<mat-icon svgIcon="pm-icons:logo" style="width: 34px; height: 30px"></mat-icon>
			<button mat-icon-button (click)="sidenav.close()" class="menu-close" [disableRipple]="true">
				<mat-icon svgIcon="pm-icons:close" style="transform: scale(0.5)">close</mat-icon>
			</button>
		</div>

		<ui-common-divisor />

		@if (client$ | async; as client) {
			<!-- Perfil do usuário -->

			<div class="menu-section">
				<ui-pm-user-profile
					[fullName]="client.fullName"
					(editProfile)="router.navigate(['perfil/editar']); sidenav.close()"
				/>
				<h3>Conta</h3>
				<mat-nav-list>
					<a mat-list-item routerLink="carteira" (click)="sidenav.close()">
						<div class="center-text">
							<mat-icon svgIcon="pm-icons:wallet">account_balance_wallet</mat-icon>
							<span>Carteira</span>
						</div>
					</a>
					<a mat-list-item routerLink="favoritos" (click)="sidenav.close()">
						<div class="center-text">
							<mat-icon svgIcon="pm-icons:favorite">favorite_border</mat-icon>
							<span>Favoritos</span>
						</div>
					</a>
					<a mat-list-item routerLink="reservas-e-checkins" (click)="sidenav.close()">
						<div class="center-text">
							<mat-icon svgIcon="pm-icons:calendar">history</mat-icon>
							<span>Histórico</span>
						</div>
					</a>
					<a mat-list-item (click)="openOptionsTheme(); sidenav.close()" *ngIf="!env.production">
						<div class="center-text">
							<mat-icon svgIcon="pm-icons:info">info</mat-icon>
							<span>Alterar tema</span>
						</div>
					</a>

					<a
						mat-list-item
						href="mailto:canais@tagme.com.br?subject=Sugestão novo restaurante (Programa Menu)"
						(click)="sidenav.close()"
					>
						<div class="center-text">
							<mat-icon svgIcon="pm-icons:info">info</mat-icon>
							<span>Sugerir restaurante</span>
						</div>
					</a>

					<a mat-list-item (click)="logout(); sidenav.close()">
						<div class="center-text">
							<mat-icon svgIcon="pm-icons:logout">exit_to_app</mat-icon>
							<span>Sair</span>
						</div>
					</a>
				</mat-nav-list>
			</div>

			<div class="menu-section">
				<ui-common-divisor />
			</div>
		}

		<!-- Ajuda e políticas -->
		<div class="menu-section" style="margin-top: 20px">
			<h3>Ajuda e políticas</h3>
			<mat-nav-list>
				<a mat-list-item routerLink="menu/como-funciona" (click)="sidenav.close()">
					<div class="center-text">
						<mat-icon svgIcon="pm-icons:info">help_outline</mat-icon>
						<span>Como funciona</span>
					</div>
				</a>
				<a mat-list-item routerLink="/menu/faq" (click)="sidenav.close()">
					<div class="center-text">
						<mat-icon svgIcon="pm-icons:help-circle">question_answer</mat-icon>
						<span>FAQ</span>
					</div>
				</a>
				<a mat-list-item routerLink="menu/politica-de-privacidade" (click)="sidenav.close()">
					<div class="center-text">
						<mat-icon svgIcon="pm-icons:shield">security</mat-icon>
						<span>Política de privacidade</span>
					</div>
				</a>
				<a mat-list-item routerLink="menu/termos-de-uso" (click)="sidenav.close()">
					<div class="center-text">
						<mat-icon svgIcon="pm-icons:file-text">description</mat-icon>
						<span>Termos de uso</span>
					</div>
				</a>
				<a mat-list-item routerLink="menu/sobre-a-tagme" (click)="sidenav.close()">
					<div class="center-text">
						<mat-icon svgIcon="pm-icons:info">info</mat-icon>
						<span>Sobre a Tagme</span>
					</div>
				</a>
			</mat-nav-list>
		</div>
	</mat-sidenav>

	<!-- Conteúdo principal -->
	<mat-sidenav-content>
		<header class="header">
			<nav class="menu" aria-label="Navegação Principal">
				<button mat-icon-button class="menu-button" id="hamburger-menu" (click)="sidenav.open()">
					<mat-icon svgIcon="pm-icons:menu-hamburger"></mat-icon>
				</button>
			</nav>
			<div class="logo">
				<button mat-icon-button routerLink="/" aria-label="Home">
					<mat-icon svgIcon="pm-icons:logo" style="width: 34px; height: 30px"></mat-icon>
				</button>
			</div>
			<div class="user">
				@if (client$ | async; as client) {
					<span>Olá, {{ client.firstName }}</span>
				} @else {
					<button mat-button routerLink="entrar">
						<span style="text-decoration: underline; cursor: pointer">Entrar</span>
					</button>
				}
			</div>
		</header>
		<ng-content></ng-content>
	</mat-sidenav-content>
</mat-sidenav-container>
