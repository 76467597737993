import { capitalizeEachWord } from '@monorepo-channels/shared/util-helpers'

type BRAND_PAGARME =
	| 'elo'
	| 'mastercard'
	| 'visa'
	| 'diners'
	| 'amex'
	| 'hipercard'
	| 'vr'
	| 'sodexo'
	| 'alelo'
export class Category {
	categoryId: string
	gatewayId?: string
	bin?: string | number
	lastFourDigits?: string
	name?: string
	nameCapitalized?: string
	isRealCard: boolean
	mainCard?: boolean
	benefits?: string[]
	brand?: BRAND_PAGARME
	imageBrand?: { src: string; alt: string }
	constructor(props: CategoryProps) {
		this.categoryId = props.categoryId
		this.gatewayId = props?.gatewayId
		this.bin = props?.digits
		this.lastFourDigits = props?.lastFourDigits
		this.name = props?.name
		this.nameCapitalized = capitalizeEachWord(props?.name ?? '')
		this.isRealCard = Boolean(props?.gatewayId)
		this.mainCard = Boolean(props?.mainCard)
		this.benefits = props.benefits
		const brand = props?.brand?.toLowerCase() as BRAND_PAGARME | undefined
		this.brand = brand
		// The app should has a directory of assets/images/card-{brand}.svg , like BB-Gastronomia
		this.imageBrand = brand ? { src: `card-${brand}.svg`, alt: brand } : undefined
	}
}

export interface CategoryProps {
	categoryId: string
	digits: number | string
	gatewayId?: string
	lastFourDigits?: string
	mainCard?: boolean
	brand?: BRAND_PAGARME
	name?: string
	benefits?: string[]
}
