import { CommonModule } from '@angular/common'
import { Component, inject, signal } from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { Router, RouterLink } from '@angular/router'
import { getClient } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { MatDividerModule } from '@angular/material/divider'

import { NgxMaskDirective } from 'ngx-mask'
import { MatInputModule } from '@angular/material/input'
import { Validators, FormBuilder, ReactiveFormsModule } from '@angular/forms'
import {
	catchError,
	filter,
	finalize,
	map,
	Observable,
	of,
	startWith,
	Subject,
	switchMap,
	take,
	takeWhile,
	timer,
} from 'rxjs'
import { CpfResponse, ClientService } from '../common/client.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { GenericErrorComponent } from '../../simple-bottom-sheets/generic-error.component'
import { LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import {
	nameIsValid,
	normalizeEmail,
	normalizePhone,
	passwordMatchValidator,
	passwordValidator,
} from '@monorepo-channels/shared/util-helpers'
import { TermsAndConditionsCheckboxComponent } from '../../terms-and-conditions/checkbox/terms-and-conditions-checkbox.componen'

export function countdownFrom(start: number): Observable<number> {
	return timer(0, 1000).pipe(
		map(index => start - index),
		takeWhile<number>(Boolean, true)
	)
}

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		NgxMaskDirective,
		MatFormFieldModule,
		MatInputModule,
		MatDividerModule,
		ReactiveFormsModule,
		LoadingPageComponent,
		TermsAndConditionsCheckboxComponent,
		RouterLink,
	],
	providers: [ClientService],
	selector: 'feature-pm-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss', '../common/page-common.scss'],
})
export class RegisterComponent {
	private router = inject(Router)
	private store = inject(Store)
	public client$ = this.store.select(getClient)
	private bottomSheet = inject(MatBottomSheet)
	private fb = inject(FormBuilder)
	private clientService = inject(ClientService)

	public eula = signal(false)

	public receiveCodeForm = this.fb.nonNullable.group({
		cpf: ['', [Validators.required]],
		email: ['', [Validators.required, Validators.email]],
	})
	public confirmCodeForm = this.fb.nonNullable.group({
		otp0: ['', [Validators.required]],
		otp1: ['', [Validators.required]],
		otp2: ['', [Validators.required]],
		otp3: ['', [Validators.required]],
		otp4: ['', [Validators.required]],
		otp5: ['', [Validators.required]],
	})
	public completeRegistrationForm = this.fb.nonNullable.group({
		name: ['', [Validators.required, nameIsValid]],
		phone: ['', [Validators.required]],
		bin: ['', { validators: [Validators.required, Validators.minLength(6), Validators.maxLength(6)] }],
		passwordGroup: this.fb.nonNullable.group(
			{
				password: ['', [Validators.required, passwordValidator()]],
				confirmPassword: ['', [Validators.required]],
			},
			{ validators: passwordMatchValidator('password', 'confirmPassword') }
		),
	})
	public focusStates = {
		cpf: false,
		email: false,
		name: false,
		phone: false,
		bin: false,
		password: false,
		confirmPassword: false,
	}

	public showPassword = {
		password: false,
		confirmPassword: false,
	}
	public selectedClient = signal<CpfResponse | null | 'sem cadastro'>(null)
	public isLoading = signal(false)
	public step = signal<'receive_code' | 'confirm_code' | 'complete_registration' | 'registration_finished'>(
		'receive_code'
	)
	public isLoadingResend = signal(false)
	public client: {
		_id: string
		email?: string
		access_token: string
		name?: string
		phone?: string
		bin?: number
	} | null = null
	private readonly resendSubject = new Subject<void>()
	readonly resend$ = this.resendSubject.asObservable()
	readonly countdown$ = this.resend$.pipe(
		startWith(0),
		switchMap(() => countdownFrom(120))
	)

	constructor() {
		this.receiveCodeForm.controls.cpf.valueChanges
			.pipe(
				filter(value => value.length === 11),
				switchMap(value =>
					this.clientService.findByCpfBradesco({ cpf: value }).pipe(
						catchError(resp => {
							if (resp.error.statusCode === 404) {
								this.selectedClient.set('sem cadastro')
								return of([])
							}
							this.bottomSheet.open(GenericErrorComponent, {
								data: {
									error: resp.error.message,
									title: 'Erro ao buscar cliente',
									btnMessage: 'Fechar',
								},
							})
							return of([])
						})
					)
				),
				takeUntilDestroyed()
			)
			.subscribe({
				next: clients => {
					if (clients.length === 1) {
						this.selectedClient.set(clients[0])
					}
					// se algum dos clientes possuírem validated true e enabled true, significa que possui um cadastro completo
					if (clients.some(client => client.validated && client.enabled)) {
						this.bottomSheet.open(GenericErrorComponent, {
							data: {
								error: 'Cliente já possui cadastro. Favor realizar o login.',
								title: 'Cadastro já realizado',
								btnMessage: 'Fechar',
							},
						})
					}
					//
				},
			})
		this.receiveCodeForm.controls.cpf.valueChanges
			.pipe(
				filter(value => value.length < 11),
				takeUntilDestroyed()
			)
			.subscribe(() => {
				this.selectedClient.set(null)
			})
	}

	onKeyUp(event: KeyboardEvent) {
		const currentInput = event.target as HTMLInputElement
		if (currentInput.value.length === 1) {
			const nextInput = currentInput.nextElementSibling as HTMLInputElement
			if (nextInput) {
				nextInput.focus()
			}
		}
	}

	onKeyDown(event: KeyboardEvent, index: number) {
		const currentInput = event.target as HTMLInputElement
		if (event.key === 'Backspace' && currentInput.value.length === 0 && index > 0) {
			const previousInput = currentInput.previousElementSibling as HTMLInputElement
			if (previousInput) {
				previousInput.focus()
			}
		}
	}

	onPaste(event: ClipboardEvent) {
		let pastedData = event.clipboardData?.getData('text/plain')
		pastedData = pastedData?.trim()
		if (pastedData) {
			// Update the orm controls with the pasted OTP
			this.confirmCodeForm.patchValue({
				otp0: pastedData[0],
				otp1: pastedData[1],
				otp2: pastedData[2],
				otp3: pastedData[3],
				otp4: pastedData[4],
				otp5: pastedData[5],
			})

			// Move focus to the last input field
			const lastInput = event.target as HTMLInputElement
			if (lastInput && lastInput.nextElementSibling) {
				;(lastInput.nextElementSibling as HTMLInputElement).focus()
			}
		}
	}

	onInput(event: Event) {
		const currentInput = event.target as HTMLInputElement
		currentInput.value = currentInput.value.toUpperCase()
	}

	setFocus(field: keyof typeof this.focusStates, value: boolean) {
		this.focusStates[field] = value
	}

	resendCode() {
		if (!this.client) return
		this.isLoadingResend.set(true)
		this.confirmCodeForm.reset()
		this.resendSubject.next()
		this.clientService
			.sendValidationCode(this.client._id)
			.pipe(
				finalize(() => this.isLoadingResend.set(false)),
				catchError(this.getError),
				take(1)
			)
			.subscribe()
	}

	/* First step */
	receiveCode() {
		if (this.receiveCodeForm.invalid) return
		const dto = {
			email: normalizeEmail(this.receiveCodeForm.controls.email.value),
			cpf: this.receiveCodeForm.controls.cpf.value,
		}
		this.isLoading.set(true)
		this.clientService
			.createClientReceiveCode(dto)
			.pipe(
				take(1),
				finalize(() => this.isLoading.set(false)),
				catchError(this.getError)
			)
			.subscribe({
				next: resp => {
					if (!resp?.clientId) return
					this.client = { _id: resp.clientId, access_token: '', email: resp.email }
					this.step.set('confirm_code')
				},
			})
	}

	/* Second Step */
	confirmCode() {
		if (this.confirmCodeForm.invalid) return
		if (!this.client) return
		const dto = {
			registrationCode: [
				this.confirmCodeForm.controls.otp0.value,
				this.confirmCodeForm.controls.otp1.value,
				this.confirmCodeForm.controls.otp2.value,
				this.confirmCodeForm.controls.otp3.value,
				this.confirmCodeForm.controls.otp4.value,
				this.confirmCodeForm.controls.otp5.value,
			]
				.join('')
				.toUpperCase(),
			clientId: this.client._id,
		}
		this.isLoading.set(true)
		this.clientService
			.confirmCode(dto)
			.pipe(
				take(1),
				finalize(() => this.isLoading.set(false)),
				catchError(this.getError)
			)
			.subscribe({
				next: res => {
					if (!res) return
					if (!this.client) return
					this.client.access_token = res.access_token
					if (res.bin) this.completeRegistrationForm.controls.bin.setValue(String(res.bin))
					if (res.name) this.completeRegistrationForm.controls.name.setValue(res.name)
					if (res.phone) this.completeRegistrationForm.controls.phone.setValue(res.phone.slice(2))
					this.step.set('complete_registration')
				},
			})
	}

	/* Thrid Step */
	completeRegistration() {
		if (this.completeRegistrationForm.invalid) return
		if (!this.client) return
		const dto = {
			clientId: this.client._id,
			cpf: this.receiveCodeForm.controls.cpf.value,
			phone: normalizePhone(this.completeRegistrationForm.controls.phone.value),
			name: this.completeRegistrationForm.controls.name.value,
			bin: parseInt(this.completeRegistrationForm.controls.bin.value),
			password: this.completeRegistrationForm.controls.passwordGroup.controls.password.value,
			eula: this.eula(),
		}
		this.isLoading.set(true)
		this.clientService
			.completeRegistration(dto, this.client?.access_token)
			.pipe(
				take(1),
				finalize(() => this.isLoading.set(false)),
				catchError(this.getError)
			)
			.subscribe({
				next: res => {
					if (!res) return
					this.step.set('registration_finished')
				},
			})
	}

	public toggleTermsAndConditions(value: boolean) {
		this.eula.set(value)
	}

	private getError = (error: { error: { message: string } }) => {
		this.bottomSheet.open(GenericErrorComponent, {
			data: {
				error: error?.error?.message,
				title: 'Erro',
				btnMessage: 'Fechar',
			},
		})
		return of(null)
	}
}
