<main class="container">
	<section class="title-container">
		<h1 class="title">Bem-vindo(a) ao <br />Programa Menu</h1>
		<p class="text">Realize o login para ter acesso <br />aos benefícios do seu cartão!</p>
	</section>

	<form [formGroup]="loginForm" (ngSubmit)="login()">
		<div class="form-field">
			<input
				type="text"
				inputmode="numeric"
				id="cpf"
				class="form-field__input"
				formControlName="cpf"
				mask="000.000.000-00"
				(focus)="isCpfFocused.set(true)"
				(blur)="isCpfFocused.set(false)"
				[ngClass]="{ focused: isCpfFocused(), filled: loginForm.controls.cpf.value }"
			/>
			<label for="cpf" class="form-field__label">CPF</label>
			<span class="form-field__underline"></span>
		</div>
		<section [hidden]="!selectedClient()">
			<div class="form-field" style="margin-top: 24px; position: relative">
				<input
					[type]="showPassword() ? 'text' : 'password'"
					id="password"
					class="form-field__input"
					formControlName="password"
					(input)="onInputChange()"
					(focus)="isPasswordFocused.set(true)"
					(blur)="isPasswordFocused.set(false)"
					[ngClass]="{ focused: isPasswordFocused(), filled: loginForm.controls.password.value }"
					style="transform: translate3d(0, 0, 0)"
				/>
				<label for="password" class="form-field__label">Senha</label>
				<span class="form-field__underline"></span>
				<mat-icon
					[svgIcon]="showPassword() ? 'pm-icons:eye-on' : 'pm-icons:eye-off'"
					style="
						position: absolute;
						right: 8px;
						top: 50%;
						transform: translateY(-70%);
						cursor: pointer;
						width: 20px;
						height: 20px;
					"
					(click)="showPassword.set(!showPassword())"
				></mat-icon>
			</div>
			<p class="forget-password" routerLink="/atualizar-senha">Esqueceu a senha?</p>
		</section>

		<section class="container-clients" *ngIf="clients().length > 1 && !selectedClient()">
			<p style="margin-top: 32px; margin-bottom: 28px">
				Identificamos mais de uma conta no CPF, <span>escolha com qual deseja seguir:</span>
			</p>
			<ul class="clients" *ngFor="let client of clients()" (click)="selectedClient.set(client)">
				<li class="clients__item">
					<mat-icon svgIcon="pm-icons:email" />
					<p>{{ client.email }}</p>
				</li>
				<li class="clients__item">
					<mat-icon svgIcon="pm-icons:phone" />
					<p>{{ client.phone }}</p>
				</li>
			</ul>
		</section>

		@if (isLoadingLogin$ | async; as loading) {
			<ui-pm-loading-page style="--height: 100px" />
		} @else {
			<button
				mat-raised-button
				style="margin-top: 32px"
				[disabled]="!loginForm.valid"
				[color]="!loginForm.valid ? '' : 'primary'"
			>
				Entrar
			</button>
		}
	</form>
	<section class="register">
		<p>Ainda não possui conta?</p>
		<button mat-raised-button routerLink="/cadastrar">Cadastre-se</button>
	</section>
</main>
