import { OperationHour } from '../venues/venues-details.interface'
export class OperationHours {
	dayTranslation: Record<string, string> = {
		'1': 'Segunda',
		'2': 'Terça',
		'3': 'Quarta',
		'4': 'Quinta',
		'5': 'Sexta',
		'6': 'Sábado',
		'7': 'Domingo',
	}
	daysOrder = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo']
	value: { day: string; times: string }[]

	constructor(
		prop: OperationHour[],
		separator: '|' | '-',
		groupDays: boolean = false // Novo parâmetro de configuração
	) {
		const result: Record<string, string[]> = {}

		// Preenche o dicionário com horários para cada dia traduzido
		for (const item of prop.filter(o => o.day)) {
			const day = this.dayTranslation[item.day]
			const timeRange = `${item.start} às ${item.end}`
			if (result[day]) {
				result[day].push(timeRange)
			} else {
				result[day] = [timeRange]
			}
		}

		// Ordena os dias
		const sortedDays = this.daysOrder.filter(day => result[day])

		// Lógica para agrupar dias consecutivos se groupDays for true
		if (groupDays) {
			const unifiedResult: { day: string; times: string }[] = []
			let startDay = sortedDays[0]
			let currentTimes = result[startDay].join(` ${separator} `)

			for (let i = 1; i < sortedDays.length; i++) {
				const day = sortedDays[i]
				const dayTimes = result[day].join(` ${separator} `)

				if (dayTimes === currentTimes) {
					continue
				} else {
					// Define o intervalo de dias
					const dayRange =
						startDay === sortedDays[i - 1] ? startDay : `${startDay} a ${sortedDays[i - 1]}`
					unifiedResult.push({ day: dayRange, times: currentTimes })
					startDay = day
					currentTimes = dayTimes
				}
			}

			// Adiciona o último agrupamento
			const lastDayRange =
				startDay === sortedDays[sortedDays.length - 1]
					? startDay
					: `${startDay} a ${sortedDays[sortedDays.length - 1]}`
			unifiedResult.push({ day: lastDayRange, times: currentTimes })

			this.value = unifiedResult
		} else {
			// Se não estiver agrupando, apenas converte para a lista de objetos ordenada
			this.value = sortedDays.map(day => ({
				day,
				times: result[day].join(` ${separator} `),
			}))
		}
	}
}
