import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { CheckinDate } from '../../models/vo/checkin-date'
import { AvailabilityCheckin } from './checkin'

export const CheckinActions = createActionGroup({
	source: 'Checkin Page',
	events: {
		'Load Availabilities': props<{ venueId: string; voucherId?: string }>(),
		'Load Availabilities Success': props<{ availabilities: AvailabilityCheckin }>(),
		'Load Availabilities Fail': props<{ error: string }>(),
		'CheckIn Availabilities Unavailable': props<{ error: string; availabilities: AvailabilityCheckin }>(),
		'Update Availabilities': props<{ availabilities: AvailabilityCheckin }>(),
		'CheckIn Confirmation': props<{ checkInDate: CheckinDate; partySize: number }>(),
		'CheckIn Confirm Success': emptyProps(),
		'CheckIn Confirm Failure': props<{ error: string }>(),
		'Set Voucher Value After CheckIn Confirm': props<{ voucherValue: string }>(),
		'Set OrderId after Loading Availabilities': props<{ orderId: string }>(),
		'Checkin Reset': emptyProps(),
	},
})
