import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { environment } from '@monorepo-channels/shared/util-environments'

export interface CpfResponse {
	id: string
	email: string
	phone: string
	enabled: boolean
	validated: boolean
}

export interface CpfDto {
	cpf: string
	validated?: boolean
	enabled?: boolean
}

export interface CreateClientReceiveCodeDto {
	email: string
	cpf: string
}

export interface ConfirmCodeDto {
	clientId: string
	registrationCode: string
}

export interface CompleteRegistrationDto {
	clientId: string
	name: string
	phone: string
	bin: number
	eula: boolean
	password: string
}

@Injectable()
export class ClientService {
	private http = inject(HttpClient)
	private baseURL = `${environment.channelsAPIURL}/api`

	findByCpfBradesco(dto: CpfDto) {
		const params = new URLSearchParams()

		if (dto.validated !== undefined) {
			params.set('validated', dto.validated.toString())
		}

		if (dto.enabled !== undefined) {
			params.set('enabled', dto.enabled.toString())
		}

		return this.http.get<CpfResponse[]>(
			`${this.baseURL}/v1/clients/programaMenu/cpf/${dto.cpf}?${params.toString()}`
		)
	}

	createClientReceiveCode(createClientReceiveCodeDto: CreateClientReceiveCodeDto) {
		return this.http.post<{ clientId: string; email: string }>(
			`${this.baseURL}/v1/clients/programaMenu/createReceiveCode`,
			createClientReceiveCodeDto
		)
	}

	confirmCode(confirmCodeDto: ConfirmCodeDto) {
		return this.http.post<{
			access_token: string
			name?: string
			phone?: string
			bin?: number
		}>(`${this.baseURL}/v1/clients/programaMenu/confirmCode`, confirmCodeDto)
	}

	updatePassword(dto: { clientId: string; password: string }, token: string) {
		return this.http.patch<{ success: true }>(`${this.baseURL}/v1/clients/updatePassword`, dto, {
			headers: { Authorization: `Bearer ${token}` },
		})
	}

	completeRegistration(completeRegistrationDto: CompleteRegistrationDto, token: string) {
		return this.http.post<{ success: true }>(
			`${this.baseURL}/v1/clients/programaMenu/completeRegistration`,
			completeRegistrationDto,
			{ headers: { Authorization: `Bearer ${token}` } }
		)
	}

	resend(clientId: string) {
		return this.http.post(`${this.baseURL}/v1/clients/programaMenu/sendValidationCode`, { clientId })
	}
}
