import { Component } from '@angular/core'
import { OrderHistoryComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	selector: 'pm-order-history-page',
	imports: [OrderHistoryComponent],
	template: '<feature-pm-order-history/>',
})
export class OrdersHistoryPageComponent {}
