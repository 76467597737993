import { CommonModule } from '@angular/common'
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { BottomSheetHeaderComponent, FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'
import { MatRippleModule } from '@angular/material/core'
import { MatButtonModule } from '@angular/material/button'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		BottomSheetHeaderComponent,
		MatRippleModule,
		MatButtonModule,
		FooterButtonsComponent,
	],
	template: `
		<ui-pm-bottom-sheet-header title="Selecionar tema"></ui-pm-bottom-sheet-header>

		<div class="cards">
			<button
				mat-ripple
				*ngFor="let theme of ['Normal', 'Centurion', 'Prime', 'Principal']"
				class="card"
				[class.active]="theme.toLocaleLowerCase() === selectedTheme()?.toLocaleLowerCase()"
				(click)="selectedTheme.set(theme)"
			>
				<mat-icon svgIcon="pm-icons:wallet" style="width: 24px; height: 24px"></mat-icon>
				{{ theme }}
				<mat-icon
					*ngIf="theme.toLocaleLowerCase() === selectedTheme()?.toLocaleLowerCase()"
					svgIcon="pm-icons:check"
					style="width: 16px; height: 16px; margin-left: auto; margin-right: 15px"
				></mat-icon>
			</button>
		</div>
		<ui-pm-footer-buttons>
			<button mat-raised-button color="primary" (click)="setTheme()">Confirmar</button>
		</ui-pm-footer-buttons>
	`,
	styles: [
		`
			body.all {
				:host {
					--icon-color-red: var(--content-primary);
				}
			}
			.cards {
				margin: 24px 20px 17px 20px;
				display: flex;
				flex-direction: column;
				gap: 12px;
			}

			.card {
				padding: 12px 15px 15px 20px;
				display: flex;
				gap: 16px;
				border-radius: 8px;
				align-items: center;
				width: 100%;
				color: var(--content-txt-black);

				&.active {
					box-shadow: var(--card-box-shadow);
				}

				&:hover {
					box-shadow: var(--card-box-shadow);
				}
			}
		`,
	],
})
export class SelectThemeOptionsComponent implements OnInit, OnDestroy {
	public selectedTheme = signal<string | undefined>(undefined)
	private observer!: MutationObserver
	private bottomSheetRef = inject<MatBottomSheetRef>(MatBottomSheetRef)

	ngOnInit() {
		this.selectedTheme.set(this.getSelectedTheme())

		this.observer = new MutationObserver(() => {
			this.selectedTheme.set(this.getSelectedTheme())
		})

		this.observer.observe(document.body, { attributes: true, attributeFilter: ['class'] })
	}

	ngOnDestroy() {
		this.observer.disconnect()
	}

	getSelectedTheme(): string {
		const themes = ['centurion', 'prime', 'principal', 'normal']
		return themes.find(theme => document.body.classList.contains(theme)) || 'normal'
	}

	setTheme() {
		const theme = this.selectedTheme()?.toLocaleLowerCase()
		if (!theme) return
		document.body.classList.remove('centurion', 'prime', 'principal', 'normal')
		document.body.classList.add(theme)
		document.body.classList.add('all')
		if (theme === 'normal') {
			document.body.classList.remove('all')
		}
		this.selectedTheme.set(theme)
		this.bottomSheetRef.dismiss()
	}
}
