<main class="container">
	<section class="title-container">
		<h1 class="title">Bem-vindo(a) ao <br />Concierge Programa Menu</h1>
		<p class="text">Realize o login para ter acesso <br />aos benefícios do seu cartão!</p>
	</section>

	<form [formGroup]="loginForm" (ngSubmit)="login()">
		<div class="form-field">
			<input
				type="text"
				id="login"
				class="form-field__input"
				formControlName="login"
				[ngClass]="{ focused: isLoginFocused(), filled: loginForm.controls.login.value }"
			/>
			<label for="login" class="form-field__label">Login</label>
			<span class="form-field__underline"></span>
		</div>
		<div class="form-field" style="margin-top: 24px; position: relative">
			<input
				[type]="showPassword() ? 'text' : 'password'"
				id="password"
				class="form-field__input"
				formControlName="password"
				(input)="onInputChange()"
				(focus)="isPasswordFocused.set(true)"
				(blur)="isPasswordFocused.set(false)"
				[ngClass]="{ focused: isPasswordFocused(), filled: loginForm.controls.password.value }"
				style="transform: translate3d(0, 0, 0)"
			/>
			<label for="password" class="form-field__label">Senha</label>
			<span class="form-field__underline"></span>
			<mat-icon
				[svgIcon]="showPassword() ? 'pm-icons:eye-on' : 'pm-icons:eye-off'"
				style="
					position: absolute;
					right: 8px;
					top: 50%;
					transform: translateY(-70%);
					cursor: pointer;
					width: 20px;
					height: 20px;
				"
				(click)="showPassword.set(!showPassword())"
			></mat-icon>
		</div>
		<p class="forget-password">Esqueceu a senha?</p>

		@if (isLoadingLogin === true) {
			<ui-pm-loading-page style="--height: 100px" />
		} @else {
			<button
				mat-raised-button
				style="margin-top: 32px"
				[disabled]="!loginForm.valid"
				[color]="!loginForm.valid ? '' : 'primary'"
			>
				Entrar
			</button>
		}
	</form>
</main>
