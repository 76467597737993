import { CommonModule } from '@angular/common'
import { Component, inject, signal } from '@angular/core'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
// import { ClientActions } from '@monorepo-channels/channels/domain'
import { ClientService, CpfResponse } from '../common/client.service'
import { Store } from '@ngrx/store'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { filter, switchMap, catchError, of, startWith, Subject, finalize, take } from 'rxjs'
import { GenericErrorComponent } from '../../simple-bottom-sheets/generic-error.component'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatIconModule } from '@angular/material/icon'
import { LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import { MatButtonModule } from '@angular/material/button'
import { RouterLink } from '@angular/router'
import { countdownFrom } from '../register/register.component'
import { passwordMatchValidator, passwordValidator } from '@monorepo-channels/shared/util-helpers'
import { NgxMaskDirective } from 'ngx-mask'

@Component({
	standalone: true,
	selector: 'feature-pm-update-password',
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatIconModule,
		LoadingPageComponent,
		MatButtonModule,
		RouterLink,
		NgxMaskDirective,
	],
	templateUrl: './update-password.component.html',
	providers: [ClientService],
	styleUrls: ['./update-password.component.scss', '../common/page-common.scss'],
})
export class UpdatePasswordComponent {
	private store = inject(Store)
	private fb = inject(FormBuilder)
	private clientService = inject(ClientService)
	private bottomSheet = inject(MatBottomSheet)
	public clients = signal<CpfResponse[]>([])
	public isLoading = signal(false)
	public isLoadingResend = signal(false)
	private readonly resendSubject = new Subject<void>()
	readonly resend$ = this.resendSubject.asObservable()
	readonly countdown$ = this.resend$.pipe(
		startWith(0),
		switchMap(() => countdownFrom(120))
	)
	public step = signal<'forgot-password' | 'confirm-code' | 'update-password' | 'password-finished'>(
		'forgot-password'
	)

	public isCpfFocused = signal(false)
	public isPasswordFocused = signal(false)
	public isConfirmPasswordFocused = signal(false)

	public selectedClient = signal<CpfResponse | null>(null)
	public accessToken: string | null = null
	public forgotPasswordForm = this.fb.nonNullable.group({
		cpf: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
		otp0: ['', [Validators.required]],
		otp1: ['', [Validators.required]],
		otp2: ['', [Validators.required]],
		otp3: ['', [Validators.required]],
		otp4: ['', [Validators.required]],
		otp5: ['', [Validators.required]],
	})
	public passwordGroupForm = this.fb.nonNullable.group(
		{
			password: ['', [Validators.required, passwordValidator()]],
			confirmPassword: ['', [Validators.required]],
		},
		{ validators: passwordMatchValidator('password', 'confirmPassword') }
	)
	public showPassword = {
		password: false,
		confirmPassword: false,
	}

	constructor() {
		this.forgotPasswordForm.controls.cpf.valueChanges
			.pipe(
				filter(value => value.length === 11),
				switchMap(value =>
					this.clientService.findByCpfBradesco({ cpf: value, validated: true, enabled: true }).pipe(
						catchError(resp => {
							this.bottomSheet.open(GenericErrorComponent, {
								data: {
									error: resp.error.message,
									title: 'Erro ao buscar cliente',
									btnMessage: 'Fechar',
								},
							})
							return of([])
						})
					)
				),
				takeUntilDestroyed()
			)
			.subscribe({
				next: clients => {
					this.clients.set(clients)
					if (clients.length === 1) {
						this.selectClientAndSendCode(clients[0])
					}
				},
			})
		this.forgotPasswordForm.controls.cpf.valueChanges
			.pipe(
				filter(value => value.length < 11),
				takeUntilDestroyed()
			)
			.subscribe(() => {
				this.clients.set([])
				this.selectedClient.set(null)
			})
	}

	selectClientAndSendCode(client: CpfResponse) {
		this.selectedClient.set(client)
		this.isLoading.set(true)
		this.clientService
			.resend((this.selectedClient() as CpfResponse).id)
			.pipe(
				take(1),
				finalize(() => this.isLoading.set(false)),
				catchError(this.getError)
			)
			.subscribe({
				next: res => {
					if (!res) return
					this.step.set('confirm-code')
					this.resendSubject.next()
				},
			})
	}

	updatePassword() {
		if (this.forgotPasswordForm.invalid || !this.selectedClient() || !this.accessToken) return
		const dto = {
			clientId: (this.selectedClient() as CpfResponse).id,
			password: this.passwordGroupForm.controls.password.value,
		}
		this.isLoading.set(true)
		this.clientService
			.updatePassword(dto, this.accessToken)
			.pipe(
				take(1),
				catchError(this.getError),
				finalize(() => this.isLoading.set(false))
			)
			.subscribe({
				next: res => {
					if (!res) return
					this.step.set('password-finished')
				},
			})
	}

	confirmCode() {
		if (this.forgotPasswordForm.invalid) return
		if (!this.selectedClient()) return
		const dto = {
			registrationCode: [
				this.forgotPasswordForm.controls.otp0.value,
				this.forgotPasswordForm.controls.otp1.value,
				this.forgotPasswordForm.controls.otp2.value,
				this.forgotPasswordForm.controls.otp3.value,
				this.forgotPasswordForm.controls.otp4.value,
				this.forgotPasswordForm.controls.otp5.value,
			]
				.join('')
				.toUpperCase(),
			clientId: (this.selectedClient() as CpfResponse).id,
		}
		this.isLoading.set(true)
		this.clientService
			.confirmCode(dto)
			.pipe(
				take(1),
				finalize(() => this.isLoading.set(false)),
				catchError(this.getError)
			)
			.subscribe({
				next: res => {
					if (!res) return
					this.accessToken = res.access_token
					this.step.set('update-password')
				},
			})
	}

	resendCode() {
		if (!this.selectedClient()) return
		this.isLoadingResend.set(true)
		this.forgotPasswordForm.controls.otp0.setValue('')
		this.forgotPasswordForm.controls.otp1.setValue('')
		this.forgotPasswordForm.controls.otp2.setValue('')
		this.forgotPasswordForm.controls.otp3.setValue('')
		this.forgotPasswordForm.controls.otp4.setValue('')
		this.forgotPasswordForm.controls.otp5.setValue('')
		this.resendSubject.next()
		this.clientService
			.resend((this.selectedClient() as CpfResponse).id)
			.pipe(
				finalize(() => this.isLoadingResend.set(false)),
				catchError(this.getError),
				take(1)
			)
			.subscribe()
	}

	onKeyUp(event: KeyboardEvent) {
		const currentInput = event.target as HTMLInputElement
		if (currentInput.value.length === 1) {
			const nextInput = currentInput.nextElementSibling as HTMLInputElement
			if (nextInput) {
				nextInput.focus()
			}
		}
	}

	onKeyDown(event: KeyboardEvent, index: number) {
		const currentInput = event.target as HTMLInputElement
		if (event.key === 'Backspace' && currentInput.value.length === 0 && index > 0) {
			const previousInput = currentInput.previousElementSibling as HTMLInputElement
			if (previousInput) {
				previousInput.focus()
			}
		}
	}

	onPaste(event: ClipboardEvent) {
		let pastedData = event.clipboardData?.getData('text/plain')
		pastedData = pastedData?.trim()
		if (pastedData) {
			// Update the form controls with the pasted OTP
			this.forgotPasswordForm.patchValue({
				otp0: pastedData[0],
				otp1: pastedData[1],
				otp2: pastedData[2],
				otp3: pastedData[3],
				otp4: pastedData[4],
				otp5: pastedData[5],
			})

			// Move focus to the last input field
			const lastInput = event.target as HTMLInputElement
			if (lastInput && lastInput.nextElementSibling) {
				;(lastInput.nextElementSibling as HTMLInputElement).focus()
			}
		}
	}

	onInput(event: Event) {
		const currentInput = event.target as HTMLInputElement
		currentInput.value = currentInput.value.toUpperCase()
	}

	private getError = (error: { error: { message: string } }) => {
		this.bottomSheet.open(GenericErrorComponent, {
			data: {
				error: error?.error?.message,
				title: 'Erro',
				btnMessage: 'Fechar',
			},
		})
		return of(null)
	}
}
