<div class="calendar">
	<div class="calendar-header">
		<div class="calendar-header__title">
			<button (click)="changeMonth(-1)">
				<svg
					width="21"
					height="21"
					viewBox="0 0 21 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<mask
						id="mask0_268_9518"
						style="mask-type: alpha"
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="21"
						height="21"
					>
						<rect x="0.533447" y="0.78125" width="19.5335" height="19.824" fill="#D9D9D9" />
					</mask>
					<g mask="url(#mask0_268_9518)">
						<path
							d="M13.5559 18.6668L5.69873 10.6928L13.5559 2.71875L14.711 3.89105L8.00877 10.6928L14.711 17.4945L13.5559 18.6668Z"
							fill="#3C3C3C"
						/>
					</g>
				</svg>
			</button>
			<p style="opacity: 0.7; font-weight: 360">{{ today | date: 'MMMM yyyy' | titlecase }}</p>
			<button (click)="changeMonth(+1)">
				<svg
					width="21"
					height="21"
					viewBox="0 0 21 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<mask
						id="mask0_268_9515"
						style="mask-type: alpha"
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="21"
						height="21"
					>
						<rect
							x="20.4666"
							y="20.6055"
							width="19.5335"
							height="19.824"
							transform="rotate(180 20.4666 20.6055)"
							fill="#D9D9D9"
						/>
					</mask>
					<g mask="url(#mask0_268_9515)">
						<path
							d="M7.44411 2.71994L15.3013 10.694L7.44411 18.668L6.28899 17.4957L12.9912 10.694L6.28899 3.89224L7.44411 2.71994Z"
							fill="#3C3C3C"
						/>
					</g>
				</svg>
			</button>
		</div>
	</div>
	<div class="calendar-body">
		<ng-container *ngFor="let day of days; let i = index">
			<span class="calendar-body__weekday" *ngIf="i < 7">
				{{ day | date: 'EEE' | uppercase | slice: 0 : 3 }}
			</span>
		</ng-container>
		<div
			*ngFor="let day of days"
			class="calendar-body__day"
			[class.calendar-body__not-month]="notMonth(day)"
			[class.calendar-body__selected-day]="isSelected(day)"
			[class.calendar-body__availability-date]="availabilityDate(day)"
			(click)="onDayClick(day)"
		>
			{{ day | date: 'd' }}
		</div>
	</div>
</div>
