import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { BottomSheetHeaderComponent, FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	imports: [CommonModule, BottomSheetHeaderComponent, FooterButtonsComponent, MatButtonModule],
	selector: 'feature-pm-register-card',
	template: `
		<ui-pm-bottom-sheet-header title="Celebrations"></ui-pm-bottom-sheet-header>

		<p style="padding: 0 20px; font-size: 14px; font-weight: 360">
			Você ganhou <span style="font-weight: 480">{{ voucherValue | currency: 'BRL' }}</span> de crédito
			no mês do seu aniversáro para utilizar no Programa Menu. Explore os restaurantes parceiros e
			realize uma reserva
		</p>

		<p style="font-size: 10px; font-weight: 360; padding: 0 20px; margin-top: 20px">
			Voucher de uso único e não acumulativo com outros benefícios
		</p>

		<ui-pm-footer-buttons>
			<button mat-raised-button color="primary" (click)="bottomSheetRef.dismiss()">Fechar</button>
		</ui-pm-footer-buttons>
	`,
})
export class CelebrationsComponent {
	public voucherValue: string | number = inject(MAT_BOTTOM_SHEET_DATA)
	public bottomSheetRef = inject(MatBottomSheetRef)
}
