<div
	class="container"
	(click)="openSelectCardOptions(data.client)"
	*ngIf="{ client: client$ | async } as data"
>
	<div class="box">
		<mat-icon svgIcon="pm-icons:wallet" style="width: 24px; height: 24px"></mat-icon>
		@if (data.client && data.client.selectedCategory) {
			<p>{{ data.client.selectedCategory.name }}</p>
		} @else {
			<p>Entre ou cadastre-se para saber os benefícios do seu cartão</p>
		}
		<mat-icon
			svgIcon="pm-icons:bottom-arrow"
			style="width: 14px; height: 8px; margin-left: auto"
		></mat-icon>
	</div>
</div>
