import { CommonModule } from '@angular/common'
import { Component, inject, signal } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import {
	BottomSheetHeaderComponent,
	FooterButtonsComponent,
	TermsOfUseComponent,
} from '@monorepo-channels/components/ui-pm'
import { MatRippleModule } from '@angular/material/core'
import { MatButtonModule } from '@angular/material/button'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		BottomSheetHeaderComponent,
		MatRippleModule,
		MatButtonModule,
		FooterButtonsComponent,
		TermsOfUseComponent,
	],
	template: `
		<ui-pm-bottom-sheet-header title="Termos de Uso"></ui-pm-bottom-sheet-header>
		<div style="height: 50vh; overflow-y: auto">
			<ui-pm-terms-of-use [seeRestaurants]="false" style="--content-txt: var(--content-txt-black)" />
		</div>
		<ui-pm-footer-buttons>
			<button mat-raised-button color="primary" (click)="close()">Fechar</button>
		</ui-pm-footer-buttons>
	`,
})
export class TermsOfUseBottomSheetComponent {
	public selectedTheme = signal<string | undefined>(undefined)
	private bottomSheetRef = inject<MatBottomSheetRef>(MatBottomSheetRef)

	close() {
		this.bottomSheetRef.dismiss()
	}
}
