import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Client } from '../../models/client/client'

import {
	BillingAddress,
	EncryptCardDataDto,
	LoginCpfPasswordDto,
	UpdateClientDto,
} from '../../gateways/client.gateway'
export const ClientActions = createActionGroup({
	source: 'Client',
	events: {
		'Reset Client': emptyProps(),
		'Load Client': props<{ id: string }>(),
		'Load Client Unicred SSO': props<{ token: string }>(),
		'Load Client Success': props<{ client: Client }>(),
		'Load Client Fail': props<{ error: string }>(),
		'Login Client Livelo Get Authorization Code': emptyProps(),
		'Login Client Livelo Exchange Code For Token': props<{ code: string }>(),
		'Login Client Livelo SSO': props<{ code: string }>(),
		'Login Client CPF password ProgramaMenu': props<LoginCpfPasswordDto>(),
		'Logout Client': emptyProps(),
		'Remove Token': emptyProps(),
		'Set Redirect Url': props<{ url: string | null }>(),
		'Add New Card': props<{
			encryptData: EncryptCardDataDto
			billingAddress: BillingAddress
			cpf?: string
			mainCard?: boolean
		}>(),
		'Register Card Success': props<{ gatewayId: string; lastFourDigits: string }>(),
		'Selected Card By GatewayId': props<{
			gatewayId: string
			lastFourDigits: string
		}>(),
		'Register Card Failure': props<{ error: string }>(),
		'Reset Card Status': emptyProps(),
		'Update Client': emptyProps(),
		'Remove Card': props<{ lastFourDigits: string }>(),
		'Remove Category': props<{ categoryId: string }>(),
		'Remove Card Success': emptyProps(),
		'Remove Card Failure': props<{ error: string }>(),
		'Update Main Card': props<{ lastFourDigits: string }>(),
		'Update Main Card Success': emptyProps(),
		'Update Main Card Failure': props<{ error: string }>(),
		'Update Client Backend': props<{ client: UpdateClientDto }>(),
		'Update Client Backend Success': emptyProps(),
		'Update Client Backend Failure': props<{ error: string }>(),
		'Login Homologation': props<{ clientId: string }>(),
		'Change Category': props<{ gatewayId: string; lastFourDigits: string }>(),
		'Change Category by CategoryId': props<{ categoryId: string }>(),
		'Change Category by Category With Most Benefits': emptyProps(),
		'Toggle Favorite': props<{ venueId: string }>(),
	},
})
