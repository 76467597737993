import { Component } from '@angular/core'
import { UpdatePasswordComponent } from '@monorepo-channels/feature-pm'

@Component({
	standalone: true,
	selector: 'pm-update-password',
	imports: [UpdatePasswordComponent],
	template: `<feature-pm-update-password />`,
})
export class UpdatePasswordPageComponent {}
