import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	ElementRef,
	AfterViewInit,
	inject,
	HostListener,
	OnDestroy,
} from '@angular/core'

import { getClient, LoginHomologationDirective, VenueActions } from '@monorepo-channels/channels/domain'
import {
	CelebrationsCardComponent,
	FilterComponent,
	FilterState,
	RegisterOrLoginComponent,
	SelectCardComponent,
	VenueCardListComponent,
} from '@monorepo-channels/feature-pm'
import { SwiperUiComponent } from '@monorepo-channels/components/ui-pm'
import { CommonModule } from '@angular/common'
import { isMobile } from '@monorepo-channels/shared/util-helpers'
import { Router } from '@angular/router'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { Store } from '@ngrx/store'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { MatIcon } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'

type Slide = {
	title: string
	text: string
	buttonText?: string
	imageDesktop: string
	imageMobile: string
	click: VoidFunction
	alt: string
	removeWhenLoggedIn: boolean
}

@Component({
	standalone: true,
	imports: [
		CommonModule,
		SwiperUiComponent,
		VenueCardListComponent,
		FilterComponent,
		SelectCardComponent,
		CelebrationsCardComponent,
		MatButtonModule,
		MatIcon,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA], // why? because of the custom element <swiper-element>
	templateUrl: 'venues-list.page.html',
	styleUrls: ['venues-list.page.scss'],
	hostDirectives: [LoginHomologationDirective],
})
export class VenueListPageComponent implements AfterViewInit, OnDestroy {
	private router = inject(Router)
	private bottomSheet = inject(MatBottomSheet)
	private filterState = inject(FilterState)
	private store = inject(Store)
	public banners: Slide[] = []

	public showBottomButton = false

	constructor() {
		this.store
			.select(getClient)
			.pipe(takeUntilDestroyed())
			.subscribe(client => {
				this.banners = [
					{
						title: 'Guia MICHELIN',
						text: 'Descubra os restaurantes selecionados pelo guia MICHELIN na nova categoria do Programa Menu.',
						buttonText: 'Ver Categoria',
						imageDesktop:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/banner-guia-michelin-desktop1.png',
						imageMobile:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/banner-%20guia-michelin-v.mobile1.png',
						click: () => {
							this.filterState.setTrueMichelinGuide()
							this.filterState.assignCount()
							this.store.dispatch(VenueActions.setMichelinGuide({ michelinGuide: true }))
							this.store.dispatch(VenueActions.applyFilter())
						},
						alt: 'imagem ilustrativa que te convida a fazer parte do Programa Menu',
						removeWhenLoggedIn: false,
					},
					{
						title: 'Faça parte do Programa Menu',
						text: 'E aproveite benefícios exclusivos nos melhores restaurantes, além de fazer suas reservas com facilidade e rapidez.',
						buttonText: 'Cadastrar',
						imageDesktop:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/slide-faca-parte.png',
						imageMobile:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/slide-faca-parte-mobile.png',
						click: () => {
							this.bottomSheet.open(RegisterOrLoginComponent)
						},
						alt: 'imagem ilustrativa que te convida a fazer parte do Programa Menu',
						removeWhenLoggedIn: Boolean(client),
					},
					{
						title: 'Vantagens exclusivas',
						text: 'Convidamos você a experimentar as exclusividades do Programa Menu, o programa gastronômico do Bradesco.',
						buttonText: 'Como funciona',
						imageDesktop:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/slide-vantagens.png',
						imageMobile:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/slide-vantagens-mobile.png',
						click: () => {
							this.router.navigate(['/menu/como-funciona'])
						},
						alt: 'imagem que ilustra as vantagens exclusivas do Programa Menu',
						removeWhenLoggedIn: false,
					},
					{
						title: 'Sem limites para usar',
						text: 'Faça sua reserva e aproveite descontos de até 15% em todos os restaurantes participantes, quantas vezes quiser.',
						buttonText: 'Como funciona',
						imageDesktop:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/slide-sem-limites.png',
						imageMobile:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/slide-sem-limites-mobile.png',
						click: () => {
							this.router.navigate(['/menu/como-funciona'])
						},
						alt: 'imagem ilustrando que o Programa Menu é sem limites de uso',
						removeWhenLoggedIn: false,
					},
					{
						title: 'Mais restaurantes para você',
						text: 'Seu restaurante favorito com benefícios exclusivos pode estar no Programa Menu',
						buttonText: 'Indique um restaurante',
						imageDesktop:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/banner-indique-um-restautante-desktop.png',
						imageMobile:
							'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/banner-indique-um-restautante-mobile.png',
						click: () => {
							const email = 'canais@tagme.com.br'
							const subject = 'Sugestão novo restaurante (Programa Menu)'
							window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`
						},
						alt: 'Imagem que mostra uma mulher sorrindo e segurando um caderno.',
						removeWhenLoggedIn: false,
					},
				]
			})
	}

	isMobile = isMobile()
	@HostListener('window:resize', ['$event'])
	onResize() {
		this.isMobile = isMobile()
	}

	private elementRef = inject(ElementRef)
	private sidenavContent: HTMLElement | null = null
	private scrollHandler = () => {
		if (this.sidenavContent) {
			this.showBottomButton = this.sidenavContent.scrollTop > 500
		}
	}

	ngAfterViewInit() {
		this.sidenavContent = this.elementRef.nativeElement.closest('mat-sidenav-content')
		if (this.sidenavContent) {
			this.sidenavContent.addEventListener('scroll', this.scrollHandler)
		}
	}

	ngOnDestroy() {
		if (this.sidenavContent) {
			this.sidenavContent.removeEventListener('scroll', this.scrollHandler)
		}
	}

	scrollToTop() {
		const sidenavContent = document.querySelector('mat-sidenav-content')
		if (sidenavContent) {
			sidenavContent.scrollTo({ top: 0, behavior: 'smooth' })
		}
	}
}
