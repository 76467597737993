<div>
	<mat-card class="reservation-card" *ngFor="let reservation of reservations">
		<div class="reservation-content">
			<div class="restaurant-image">
				<img
					src="{{ env.coverUrl }}{{ reservation.venue.partner.images.thumb[0].coverUrl }}"
					alt="Restaurant image"
				/>
			</div>

			<div class="reservation-details">
				<div class="header">
					<h2 class="restaurant-name">{{ reservation.venue.name }}</h2>
					<div class="status-badge">{{ getStatusName(reservation.status) }}</div>
				</div>

				<mat-divider></mat-divider>

				<div class="details-grid">
					<div class="details-column">
						<div class="detail-item">
							<p class="label">
								Data: <span>{{ reservation.reservationDay | date: 'dd/MM/yyy' }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								Horário: <span>{{ reservation.reservationTime }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								Mesa: <span>{{ reservation.partySize | formatPartySize }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								Benefícios: <span>{{ getFormattedBenefits(reservation) }}</span>
							</p>
						</div>
					</div>

					<div class="details-column">
						<div class="detail-item">
							<p class="label">
								Nome:
								<span>{{ reservation.client.name }} {{ reservation.client.lastName }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								CPF: <span>{{ reservation.client.cpf | mask: '000.000.000-00' }}</span>
							</p>
						</div>
						<div class="detail-item">
							<p class="label">
								Telefone:
								<span>{{ reservation.client.phone | mask: '+00 (00) 00000-0000' }}</span>
							</p>
						</div>
					</div>
				</div>

				<div class="actions">
					<a mat-button class="cancel-button">Cancelar reserva</a>
				</div>
			</div>
		</div>
	</mat-card>
	<!-- Not found card-->
	@if (reservations.length === 0) {
		<div class="not-found-card">
			<mat-icon svgIcon="pm-icons:close-circle"></mat-icon>
			<h2>Nenhuma reserva encontrada.</h2>
			<p>Revise as informações fornecidas na busca.</p>
		</div>
	}
</div>
