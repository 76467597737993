import { Injectable, signal } from '@angular/core'
import { Cuisine } from '@monorepo-channels/channels/domain'

@Injectable({ providedIn: 'root' })
export class FilterState {
	public searchChange = signal('')
	public selectedCategory = signal<Array<string>>([])
	public selectedCuisine = signal<Array<Cuisine>>([])
	public michelinGuide = signal({ name: 'Guia MICHELIN', selected: false })
	public newRestaurant = signal({ name: 'Novo restaurante', selected: false })
	public count = signal(0)

	setCategory(category: string) {
		// Toggle category in selectedCategory array
		this.selectedCategory.set(
			this.selectedCategory().includes(category)
				? this.selectedCategory().filter(c => c !== category)
				: [...this.selectedCategory(), category]
		)
	}

	setCuisine(cuisine: Cuisine) {
		// Toggle cuisine in selectedCuisine array
		this.selectedCuisine.set(
			this.includesCuisine(cuisine)
				? this.selectedCuisine().filter(c => c._id !== cuisine._id)
				: [...this.selectedCuisine(), cuisine]
		)
	}

	toggleMichilenGuide() {
		this.michelinGuide.update(current => ({ ...current, selected: !current.selected }))
	}

	toggleNewRestaurante() {
		this.newRestaurant.update(current => ({ ...current, selected: !current.selected }))
	}

	includesCategory(category: string) {
		return this.selectedCategory().includes(category)
	}

	includesCuisine(cuisine: Cuisine) {
		return this.selectedCuisine().some(c => c._id === cuisine._id)
	}

	resetState() {
		this.selectedCategory.set([])
		this.selectedCuisine.set([])
		this.michelinGuide.update(current => ({ ...current, selected: false }))
		this.newRestaurant.update(current => ({ ...current, selected: false }))
	}

	assignCount() {
		let count = 0
		if (this.selectedCategory().length !== 0) count += this.selectedCategory().length
		if (this.selectedCuisine().length !== 0) count += this.selectedCuisine().length
		if (this.michelinGuide().selected) count++
		if (this.newRestaurant().selected) count++
		this.count.set(count)
	}
}
