import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output, signal } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { Benefits } from '@monorepo-channels/channels/domain'
import { OrDivisorComponent } from '@monorepo-channels/components/ui-common'
import { BenefitsUiComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	selector: 'feature-pm-choose-celebrations',
	imports: [MatIconModule, OrDivisorComponent, CommonModule, BenefitsUiComponent],
	templateUrl: './choose-celebrations.component.html',
	styleUrl: './choose-celebrations.component.scss',
})
export class ChooseCelebrationsComponent {
	@Input() order: 'reserva' | 'checkin' = 'reserva'
	@Input() voucherValue: string = ''
	@Input() benefits: Benefits[] = []

	@Output()
	outputCelebration = new EventEmitter<string | null>()
	selectCelebration = signal<string | null>(null)

	setCelebration(value: string) {
		this.selectCelebration.set(value)
		this.outputCelebration.next(this.selectCelebration())
	}
}
