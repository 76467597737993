import { HttpClient } from '@angular/common/http'
import { Observable, delay, map, of, switchMap, throwError } from 'rxjs'
import { Injectable, inject } from '@angular/core'
import { OrderHistory } from '../models/order-history/order-history'
import { OrderFromHistoryProps } from '../models/order-history/order-history.interface'
import { environment } from '@monorepo-channels/shared/util-environments'
import { PartnerToken } from '@monorepo-channels/shared/util-helpers'
import { AvailabilityCheckin } from '../state/checkin/checkin'
import { AvailabilityReservation } from '../state/reservation/reservation'

export const DEFAULT_LIMIT = 10

@Injectable({ providedIn: 'root' })
export class OrderGateway {
	private baseURL = `${environment.channelsAPIURL}/api/v1/orders`
	private partnerId = inject(PartnerToken)
	private http = inject(HttpClient)

	checkAvailability(checkAvailabilityDto: CheckAvailabilityDto) {
		const dto = { ...checkAvailabilityDto, partnerId: this.partnerId }
		// return this.genericError()
		// return this.checkinInavailable()
		return this.http.post<ResponseAvailabilityCheckin | ResponseAvailabilityReservation>(
			`${this.baseURL}/checkAvailability`,
			dto
		)
	}

	// TODO: Better return types than any
	confirmOrder(orderId: string, confirmOrderDto: ConfirmReservationDto | ConfirmCheckinDto) {
		// return this.genericError()
		// return this.success() as Observable<any>
		return this.http.post<{ voucher?: { value: string } }>(
			`${this.baseURL}/confirm/${orderId}`,
			confirmOrderDto
		)
	}

	getHistory(params?: FilterHistoryQueryDto): Observable<OrderHistory[]> {
		const query = params ? '?' + new URLSearchParams(params as Record<string, string>).toString() : ''
		return this.http
			.get<OrderFromHistoryProps[]>(`${this.baseURL}/history` + query)
			.pipe(map(orders => orders.map(order => OrderHistory.create(order))))
	}

	cancelOrder(orderId: string): Observable<unknown> {
		// return this.genericError()
		// return this.success()
		return this.http.delete(`${this.baseURL}/${orderId}`)
	}

	getOrdersPaymentReady(clientId: string) {
		return this.http.get<{ hasPaymentReady: boolean }>(`${this.baseURL}/payment/ready/${clientId}`)
	}

	private checkinInavailable(): Observable<ResponseAvailabilityCheckin> {
		return of(['1']).pipe(
			delay(1000),
			map(() => ({
				success: false,
				availabilities: { startTime: '19:20', endTime: '20:20', available: false },
				error: { message: 'Checkin indisponivel' },
			}))
		)
	}

	private genericError() {
		return of(['1']).pipe(
			delay(1000),
			switchMap(() =>
				throwError(() => ({
					error: {
						message: 'error',
					},
				}))
			)
		)
	}

	private success() {
		return of(1).pipe(delay(1000))
	}
}

export type ResponseAvailabilityReservation = {
	availabilities: AvailabilityReservation[]
	voucher?: {
		value: string
	}
	order: {
		_id: string
	}
}

export type ResponseAvailabilityCheckin =
	| {
			success: true
			availabilities: AvailabilityCheckin
			order: { _id: string }
	  }
	| {
			success: false
			availabilities: AvailabilityCheckin
			error: { message: string }
	  }

export interface ConfirmCheckinDto {
	reservationDay: string // YYY-MM-DD
	reservationTime: string // HH:MM
	clientId: string
	partySize: number
}

export interface CheckAvailabilityDto {
	partnerId?: string
	voucherId?: string
	clientId: string
	venueId: string
	categoryId: string
	intent?: 'checkin' | 'reservation'
}

export interface ConfirmReservationDto {
	partySize: number
	section: string // mongoId
	sectionLabel: string
	reservationDay: string // YYY-MM-DD
	reservationTime: string // HH:MM
	clientId: string
}

export interface FilterHistoryQueryDto {
	clientId?: string
	partnerId?: string
	cpf?: string
	intent?: string
	startDate?: string
	endDate?: string
	skip?: number
	limit?: number
}
