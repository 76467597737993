import { Injectable } from '@angular/core'
const CONCIERGE_TOKEN = 'token_concierge'
const TOKEN_EXPIRATION = 'token_concierge_expiration'
const EXPIRATION_TIME = 60 * 60 * 1000

@Injectable({ providedIn: 'root' })
export class ConciergeAuthService {
	public redirectURL = '/'
	getToken() {
		const token = localStorage.getItem(CONCIERGE_TOKEN)
		const expiration = localStorage.getItem(TOKEN_EXPIRATION)

		if (!token || !expiration) {
			return null
		}

		const now = new Date().getTime()
		if (now > parseInt(expiration, 10)) {
			this.clearToken()
			return null
		}

		return token
	}

	setToken(token: string) {
		const expiration = new Date().getTime() + EXPIRATION_TIME
		localStorage.setItem(CONCIERGE_TOKEN, token)
		localStorage.setItem(TOKEN_EXPIRATION, expiration.toString())
	}

	clearToken() {
		localStorage.removeItem(CONCIERGE_TOKEN)
		localStorage.removeItem(TOKEN_EXPIRATION)
	}
}
