import { Component } from '@angular/core'
import { TermsOfUseComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	selector: 'pm-terms-of-use',
	imports: [TermsOfUseComponent],
	template: ` <ui-pm-terms-of-use /> `,
})
export class TermsOfUsePageComponent {}
