import { Component, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { map, merge } from 'rxjs'
import { AuthService, ClientActions } from '@monorepo-channels/channels/domain'

@Component({
	standalone: true,
	imports: [],
	template: '',
})
export class LoginAutomaticPageComponent {
	private authService = inject(AuthService)
	private store = inject(Store)
	private router = inject(Router)
	private actions$ = inject(Actions)
	constructor() {
		const { clientId, token } = this.authService.getClientIdAndToken()

		if (!clientId || !token) {
			this.router.navigate(['/'])
			return
		}

		this.store.dispatch(ClientActions.loadClient({ id: clientId }))

		merge(
			this.actions$.pipe(
				ofType(ClientActions.loadClientSuccess),
				map(() => this.authService.redirectURL)
			),
			this.actions$.pipe(
				ofType(ClientActions.loadClientFail),
				map(() => '/')
			)
		)
			.pipe(takeUntilDestroyed())
			.subscribe(route => {
				this.router.navigate([route])
			})
	}
}
