<feature-pm-select-card />
<feature-pm-celebrations-card />

<ui-pm-swiper-container>
	<ng-container *ngFor="let banner of banners">
		<swiper-slide *ngIf="!banner.removeWhenLoggedIn">
			<figure class="image-container" (click)="banner.click()">
				<img
					*ngIf="!isMobile"
					[src]="banner.imageDesktop"
					[alt]="banner.alt"
					style="cursor: pointer"
				/>
				<img *ngIf="isMobile" [src]="banner.imageMobile" [alt]="banner.alt" style="cursor: pointer" />
				<figcaption class="image-text">
					<h2 class="image-title">{{banner.title}}</h2>
					<p class="image-description" [innerHTML]="banner.text"></p>
					<button mat-raised-button color="primary" class="image-btn" *ngIf="banner.buttonText">
						{{banner.buttonText}}
					</button>
				</figcaption>
			</figure>
		</swiper-slide>
	</ng-container>
</ui-pm-swiper-container>

<article class="venue-filter">
	<feature-pm-filter></feature-pm-filter>
</article>
<button mat-icon-button class="goUp" (click)="scrollToTop()" *ngIf="showBottomButton">
	<mat-icon svgIcon="pm-icons:up-arrow" style="transform: translateY(-2px)"></mat-icon>
</button>
<main class="venue-list">
	<feature-pm-venue-card-list />
</main>
