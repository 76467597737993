import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, inject, signal } from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { Router, RouterLink } from '@angular/router'
import { ClientActions, getClient, getStatusClient, VenueActions } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { MatDividerModule } from '@angular/material/divider'

import { NgxMaskDirective } from 'ngx-mask'
import { MatInputModule } from '@angular/material/input'
import { Validators, FormBuilder, ReactiveFormsModule } from '@angular/forms'
import { catchError, filter, map, of, switchMap, tap } from 'rxjs'
import { CpfResponse, ClientService } from '../common/client.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { GenericErrorComponent } from '../../simple-bottom-sheets/generic-error.component'
import { Actions, ofType } from '@ngrx/effects'
import { LoadingPageComponent } from '@monorepo-channels/components/ui-pm'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		NgxMaskDirective,
		MatFormFieldModule,
		MatInputModule,
		MatDividerModule,
		ReactiveFormsModule,
		LoadingPageComponent,
		RouterLink,
	],
	providers: [ClientService],
	selector: 'feature-pm-login-client',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss', '../common/page-common.scss'],
})
export class LoginClientComponent implements AfterViewInit {
	private router = inject(Router)
	private store = inject(Store)
	private actions$ = inject(Actions)
	public client$ = this.store.select(getClient)
	private bottomSheet = inject(MatBottomSheet)
	private fb = inject(FormBuilder)
	private clientService = inject(ClientService)

	public loginForm = this.fb.nonNullable.group({
		cpf: ['', [Validators.required]],
		password: ['', [Validators.required]],
	})
	public isCpfFocused = signal(false)
	public isPasswordFocused = signal(false)
	public showPassword = signal(false)
	public clients = signal<CpfResponse[]>([])
	public selectedClient = signal<CpfResponse | null>(null)
	public isLoadingLogin$ = this.store.select(getStatusClient).pipe(map(status => status === 'pending'))

	constructor() {
		this.loginForm.controls.cpf.valueChanges
			.pipe(
				filter(value => value.length === 11),
				switchMap(value =>
					this.clientService.findByCpfBradesco({ cpf: value, validated: true, enabled: true }).pipe(
						catchError(resp => {
							this.bottomSheet.open(GenericErrorComponent, {
								data: {
									error: resp.error.message,
									title: 'Erro ao buscar cliente',
									btnMessage: 'Fechar',
								},
							})
							return of([])
						})
					)
				),
				takeUntilDestroyed()
			)
			.subscribe({
				next: clients => {
					this.clients.set(clients)
					if (clients.length === 1) {
						this.selectedClient.set(clients[0])
					}
				},
			})
		this.loginForm.controls.cpf.valueChanges
			.pipe(
				filter(value => value.length < 11),
				takeUntilDestroyed()
			)
			.subscribe(() => {
				this.clients.set([])
				this.selectedClient.set(null)
			})

		this.actions$.pipe(ofType(ClientActions.loadClientFail), takeUntilDestroyed()).subscribe(action => {
			let message = action.error
			if (message.includes('Muitas requisições')) {
				message =
					'Você fez muitas tentativas. Por favor, aguarde um momento e tente novamente mais tarde.'
			}
			this.bottomSheet.open(GenericErrorComponent, {
				data: {
					error: message,
					title: 'Erro ao entrar',
					btnMessage: 'Fechar',
				},
			})
		})
		this.actions$
			.pipe(
				ofType(ClientActions.loadClientSuccess),
				takeUntilDestroyed(),
				tap(() => this.store.dispatch(VenueActions.applyFilter()))
			)
			.subscribe(() => {
				this.router.navigate(['/'])
			})
	}

	onInputChange(): void {
		const passwordControl = this.loginForm.get('password')
		if (passwordControl) {
			passwordControl.setValue((document.getElementById('password') as HTMLInputElement).value)
			passwordControl.updateValueAndValidity()
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.loginForm.updateValueAndValidity()
		}, 0)
	}

	login() {
		if (this.loginForm.invalid) return
		if (!this.selectedClient()) return
		const dto = {
			email: (this.selectedClient() as { email: string }).email,
			phone: (this.selectedClient() as { phone: string }).phone,
			cpf: this.loginForm.controls.cpf.value,
			password: this.loginForm.controls.password.value,
		}
		this.store.dispatch(ClientActions.loginClientCPFPasswordProgramaMenu(dto))
	}
}
